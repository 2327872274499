<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16.9375C13.8315 16.9375 16.9375 13.8315 16.9375 10C16.9375 6.16852 13.8315 3.0625 10 3.0625M10 16.9375C6.16852 16.9375 3.0625 13.8315 3.0625 10C3.0625 6.16852 6.16852 3.0625 10 3.0625M10 16.9375C8.23959 16.9375 6.8125 13.8315 6.8125 10C6.8125 6.16852 8.23959 3.0625 10 3.0625M10 16.9375C11.7604 16.9375 13.1875 13.8315 13.1875 10C13.1875 6.16852 11.7604 3.0625 10 3.0625M16.75 10H3.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
    />

  </svg>
</template>
<script>
export default {
  name: 'GlobeIcon',
  props: {
    width: {
      type: Number,
      default: () => 20
    },
    height: {
      type: Number,
      default: () => 20
    }
  }
}
</script>
    <style scoped lang="css"></style>
