// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get All By User
const getAllCreatedByUser = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('invitations')
    .where('createdBy', '==', user.uid)
    .where('teamId', '==', teamId)
    .where('status', '==', 'pending')
    .get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

const getUserInvitations = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('invitations')
    .where('toEmail', '==', user.email.toLocaleLowerCase())
    .where('status', '==', 'pending')
    .get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

const remove = async (invitationId) => {
  return db.collection('invitations').doc(invitationId).delete()
}

const reject = async (invitationId) => {
  return db.collection('invitations').doc(invitationId).update({ status: 'rejected' })
}

const accept = async (invitationId) => {
  return db.collection('invitations').doc(invitationId).update({ status: 'accepted' })
}

export default {
  accept,
  getAllCreatedByUser,
  getUserInvitations,
  reject,
  remove
}
