/* This util file acts as a master IntersectionObserver for all the BaseDynamicLoader instances.
It is responsible for executing the DOM loading/unloading callback methods of these instances.

Using a shared observer allows us to avoid creating multiple IntersectionObserver instances (saving memory), 
while maintaining the reusability of the BaseDynamicLoader component */

let observers = new Map();
let subscribers = new Map();

function createObserver(options) {
  if (observers.has(options.root)) return observers.get(options.root);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      const { callback, context } = subscribers.get(entry.target);
      if (callback) {
        callback.call(context, entry);
      }
    });
  }, options);

  observers.set(options.root, observer);
}

export function observe(element, callback, context, options) {
  const observer = createObserver(options);
  subscribers.set(element, { callback, context, observer });
  if (observer){

    observer.observe(element);
  }
}

export function unobserve(element) {
  const subscriber = subscribers.get(element);
  if (!subscriber) return;
  const observer = subscriber.observer;
  if (observer) {
    observer.unobserve(element);
  }
  subscribers.delete(element);
}