<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
      <path d="M11.9999 4.83333H13.3333C14.0697 4.83333 14.6666 5.43029 14.6666 6.16667V14.6667C14.6666 15.4031 14.0697 16 13.3333 16H6.66659C5.93021 16 5.33325 15.4031 5.33325 14.6667V6.16667C5.33325 5.43029 5.93021 4.83333 6.66659 4.83333H7.99992M11.9999 4.83333V6.16667H7.99992V4.83333M11.9999 4.83333C11.9999 4.09695 11.403 3.5 10.6666 3.5H9.33325C8.59687 3.5 7.99992 4.09695 7.99992 4.83333" 
      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BriefIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>