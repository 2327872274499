<template>
  <transition name="fade">
    <div v-if="activeAlert" class="notification flex items-center p-1 rounded-full"
    style="background-color: rgba(6, 7, 16, 0.88)" :class="{'sidebar': !$route.meta.hideSidebar}">
      <!-- Alert Icon -->
      <div tag="div" class="relative w-7 h-7 flex-shrink-0 rounded-full flex items-center justify-center transition-colors duration-300" 
      :style="{ backgroundColor: getAlertBackgroundColor(activeAlert.type) }">
        <transition name="icon">
          <SuccessResponseIcon v-if="activeAlert.type === 'success'" class="alert-icon text-secondary-green-25" />
        </transition>
        <transition name="icon">
          <ErrorResponseIcon v-if="['error', 'warning'].includes(activeAlert.type)" class="alert-icon transition-colors duration-300"
          :class="activeAlert.type === 'error' ? 'text-primary-red-25' : 'text-secondary-yellow-25'" />
        </transition>
        <transition name="icon">
          <InfoResponseIcon v-if="!['success', 'error', 'warning'].includes(activeAlert.type)" class="alert-icon text-primary-blue-25" />
        </transition>
      </div>
      <!-- Alert Message -->
      <transition name="initmessage">
        <div v-if="activeAlert.message" class="relative">
          <div ref="active-message" class="flex items-center gap-1 px-3 w-max whitespace-nowrap">
            <BaseText type="label" size="sm" class="text-white">
              {{ activeAlert.message }}
            </BaseText>
            <transition name="fade">
              <BaseText v-if="activeAlert.count > 1" type="body" size="sm" class="text-white">
                ({{ activeAlert.count }})
              </BaseText>
            </transition>
          </div>
          <div ref="previous-message" class="previous-message flex items-center gap-1 px-3 w-max whitespace-nowrap">
            <BaseText type="label" size="sm" class="text-white">
              {{ previousAlert?.message || '' }}
            </BaseText>
            <transition name="fade">
              <BaseText v-if="previousAlert?.count > 1" type="body" size="sm" class="text-white">
                ({{ previousAlert?.count || 0 }})
              </BaseText>
            </transition>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="activeAlert.message" class="fade-overlay absolute right-0 top-0 bottom-0 w-3 z-10" :class="activeAlert.type" />
      </transition>
    </div>
  </transition>
</template>

<script>
import EventBus from './EventBus'
import smoothReflow from 'vue-smooth-reflow'

// Icons
import SuccessResponseIcon from '../../components/globals/Icons/ResponseAlertIcons/SuccessResponseIcon.vue'
import ErrorResponseIcon from '../../components/globals/Icons/ResponseAlertIcons/ErrorResponseIcon.vue'
import InfoResponseIcon from '../../components/globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'

const MESSAGE_EXPANSION_DELAY = 300

export default {
  name: 'BaseNotifyAlert',
  mixins: [smoothReflow],
  components: {
    SuccessResponseIcon,
    ErrorResponseIcon,
    InfoResponseIcon
  },
  data () {
    return {
      activeAlert: null,
      alertTimeout: null,
      previousAlert: null,
    }
  },
  mounted () {
    EventBus.$on('showAlert', (alert) => this.enqueueAlert(alert))
    this.$smoothReflow({
      el: '.notification',
      property: ['width'],
      transition: 'width 0.5s ease-in-out'
    })
  },
  methods: {
    enqueueAlert (alert) {
      const newAlert = {
        ...alert,
        count: 1,
        nextAlert: null
      }
      newAlert.timeout = newAlert.timeout ?? getDefaultAlertDuration(newAlert.type)
      if (this.activeAlert) {
        let mostRecentAlert = this.activeAlert
        while (mostRecentAlert.nextAlert) {
          // Traverse to the back of the alert queue
          mostRecentAlert = mostRecentAlert.nextAlert
        }
        if (mostRecentAlert.message === newAlert.message) {
          mostRecentAlert.count++
          // Reset the current timeout
          clearTimeout(this.alertTimeout)
          this.alertTimeout = setTimeout(this.dequeueAlert, newAlert.timeout)
        } else {
          mostRecentAlert.nextAlert = newAlert
        }
      } else {
        this.activeAlert = { ...newAlert, message: null }
        setTimeout(() => {
          this.activeAlert.message = newAlert.message
        }, MESSAGE_EXPANSION_DELAY + 300)
        // Start the timeout
        this.alertTimeout = setTimeout(this.dequeueAlert, newAlert.timeout)
      }
    },
    dequeueAlert () {
      if (this.activeAlert.nextAlert) {
        this.displayNextAlert(this.activeAlert)
      } else {
        const leavingAlert = { ...this.activeAlert }
        this.activeAlert.message = null
        setTimeout(() => {
          if (!this.activeAlert.nextAlert) {
            this.activeAlert = null
          } else {
            // In case a new alert was added during the collapse animation
            this.displayNextAlert(leavingAlert)
          } 
        }, MESSAGE_EXPANSION_DELAY + 200)
      }
    },
    displayNextAlert (previousAlert) {
      this.previousAlert = previousAlert
      const nextAlert = this.activeAlert.nextAlert
      this.activeAlert = nextAlert
      this.executeMessageSwapAnim()
      this.alertTimeout = setTimeout(this.dequeueAlert, nextAlert.timeout)
    },
    executeMessageSwapAnim () {
      const activeMessage = this.$refs['active-message']
      const previousMessage = this.$refs['previous-message']
      if (!activeMessage || !previousMessage) return

      activeMessage.classList.remove('message-enter-anim')
      activeMessage.offsetWidth // Trigger reflow
      activeMessage.classList.add('message-enter-anim')

      previousMessage.classList.remove('message-leave-anim')
      previousMessage.offsetWidth // Trigger reflow
      previousMessage.classList.add('message-leave-anim')
    },
    getAlertBackgroundColor (type) {
      switch (type) {
        case 'success': return 'rgba(0, 199, 169, 0.44)'
        case 'warning': return 'rgba(255, 187, 38, 0.44)'
        case 'error': return 'rgba(244, 0, 58, 0.44)'
        default: return 'rgba(0, 107, 255, 0.44)'
      }
    }
  }
}

const getDefaultAlertDuration = (type) => {
  switch (type) {
    case 'error':
    case 'warning': return 3500
    case 'info': return 3000
    default: return 2500
  }
}
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 500000;
}
.notification.sidebar {
  left: calc(50% + 118px);
}
/* .alert-icon {
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
} */
.previous-message {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}

.fade-overlay {
  background: linear-gradient(to left, rgba(6, 7, 16, 0.88), transparent);
}

/* ========= Animation classes ========= */
.message-enter-anim {
  animation-name: messageEnter;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.message-leave-anim {
  animation-name: messageLeave;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes messageEnter {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes messageLeave {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

/* ========= Vue <transition> classes ========= */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.icon-enter-active, .icon-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.icon-leave-active {
  position: absolute;
  left: 4px;
}
.fade-enter-from, .fade-enter, .fade-leave-to, .icon-enter-from, .icon-enter, .icon-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from, .icon-enter-to, .icon-leave-from {
  opacity: 1;
}

.initmessage-enter-active {
  transition: opacity 0.3s ease-in-out;
}
.initmessage-leave-active {
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  left: 32px;
}
.initmessage-enter-from, .initmessage-enter, .initmessage-leave-to {
  opacity: 0;
}
.initmessage-enter-to, .initmessage-leave-from {
  opacity: 1;
}
</style>