<template>
  <div class="w-full h-full overflow-y-auto">
    <div class="w-full h-full flex flex-col gap-5 rounded-t-3xl px-32 pt-12">
      <div class="flex justify-between items-end border-b border-neutral-50 pb-5">
        <div class="flex flex-col gap-1">
          <BaseText
            type="label"
            class="text-neutral-600"
          >
            Expert Profile
          </BaseText>
          <BaseText
            size="sm"
            class="text-neutral-500"
          >
            Show off your creative research and gain exposure to the Foreplay community.
          </BaseText>
        </div>
        <BaseButton
          v-if="currentUser?.expert"
          primary
          :loading="loading.saveExpert"
          :disabled="!hasDataChanged"
          forced-theme="blue"
          @click="saveExpertProfile"
        >
          Save Changes
        </BaseButton>
      </div>
      <div
        v-if="currentUser?.expert"
        class="flex flex-col gap-y-10"
      >
        <!-- Profile Photo -->
        <div class="grid grid-cols-12">
          <div class="col-span-6 space-y-1">
            <BaseText type="label">
              Profile Photo
            </BaseText>
            <BaseText size="sm">
              Update the photo displayed in the Experts section.
            </BaseText>
          </div>
          <div class="col-span-6 flex gap-5">
            <img
              v-if="currentUser?.avatar || expertPhoto"
              :src="expertPhoto ?? currentUser?.avatar"
              class="rounded-full w-11 h-11"
            >
            <img
              v-else
              src="../../assets/images/defaultProfileImage.webp"
              class="rounded-full w-11 h-11"
            >
            <div>
              <div class="flex py-1.5 gap-2">
                <!-- Upload Logo Button -->
                <button
                  class="base-btn flex items-center gap-1.5 pl-1.5 py-1.5 pr-2.5 rounded-md"
                  @click="handleFileInput"
                >
                  <input
                    ref="fileInput"
                    class="hidden"
                    type="file"
                    @change="handleFileUpload"
                  >
                  <UploadIcon class="text-icon-normal" />
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    Upload
                  </BaseText>
                </button>
                <button
                  class="px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
                  :class="expertPhoto === '' ? 'cursor-default bg-neutral-10 text-text-disabled' : 'text-text-muted'"
                  :disabled="expertPhoto === ''"
                  @click="removePhoto"
                >
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    Remove
                  </BaseText>
                </button>
              </div>
              <BaseText
                size="sm"
                class="text-text-normal"
              >
                We support PNGs and JPEGs.
              </BaseText>
            </div>
          </div>
        </div>
        <!-- Name inputs -->
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-6">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted self-center"
            >
              First Name
            </BaseText>
            <input
              v-model="expertProfile.firstName"
              placeholder="John"
              class="w-full form-input px-2 py-1.5 max-w-none min-w-0 caret-neutral-400"
            >
          </div>
          <div class="col-span-6">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted self-center"
            >
              Last Name
            </BaseText>
            <input
              v-model="expertProfile.lastName"
              placeholder="Smith"
              class="w-full form-input px-2 py-1.5 max-w-none min-w-0 caret-neutral-400"
            >
          </div>
        </div>
        <!-- Socials -->
        <div class="space-y-3">
          <BaseText
            type="label"
            size="sm"
          >
            Social Links
          </BaseText>
          <BaseText size="sm">
            Social links to be included on your public profile.
          </BaseText>
          <div class="grid grid-cols-12 gap-3">
            <!--  -->
            <div
              v-for="socialLink in socialPlatforms"
              :key="socialLink.model"
              class="col-span-3"
            >
              <div class="relative">
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <component
                    :is="socialLink.icon"
                    class="text-icon-muted"
                  />
                </span>
                <input
                  v-model="social[socialLink.model]"
                  :placeholder="socialLink.placeholder"
                  class="w-full form-input pl-10 px-2 py-1.5 max-w-none min-w-0 caret-neutral-400"
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Expert Folder -->
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-6">
            <BaseText
              size="sm"
              type="label"
              class="text-text-muted"
            >
              Expert Folder
            </BaseText>
            <BaseText
              size="sm"
              class="text-text-normal"
            >
              All boards within this folder will be displayed publicly
            </BaseText>
            <FolderDropdown
              class="mt-1"
              :show-label="false"
              :initial-selected-id="selectedFolder"
              @change="(val) => selectedFolder = val.id"
            />
          </div>
          <div class="col-span-6">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted self-center"
            >
              Affiliate String
            </BaseText>
            <BaseText
              size="sm"
              class="text-text-normal"
            >
              Earn from users who signup through your expert board.
            </BaseText>
            <input
              v-model="utm"
              placeholder="via=XXXXXX"
              class="w-full form-input px-2 py-1.5 mt-1 max-w-none min-w-0 caret-neutral-400"
            >
          </div>
        </div>
        <!--  -->
      </div>
      <div
        v-else
        class="no-expert-container p-6 mt-5 flex items-center justify-center text-center flex-col gap-1"
      >
        <ExpertSettingsIcon />
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          You're not an Expert ... YET!
        </BaseText>
        <BaseText
          size="sm"
          class="text-text-normal"
          style="max-width: 432px"
        >
          Foreplay Experts get exposure to over 100,000 monthly users on Foreplay and earn by sharing a public Swipe File.
        </BaseText>
        <button
          class="become-expert-btn mt-4 px-2.5 py-1.5"
          @click="handleExpertCreate"
        >
          Become an Expert
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import { mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
// Components
import FolderDropdown from '../boards/FolderDropdown.vue'
// Icons
import ExpertSettingsIcon from '../globals/Icons/SettingsIcons/ExpertSettingsIcon.vue'
import UploadIcon from '../globals/Icons/UploadIcon.vue'
import MonoTwitterIcon from '../globals/Icons/socials/mono/MonoTwitterIcon.vue'
import MonoInstagramIcon from '../globals/Icons/socials/mono/MonoInstagramIcon.vue'
import MonoFacebookIcon from '../globals/Icons/socials/mono/MonoFacebookIcon.vue'
import MonoLinkedInIcon from '../globals/Icons/socials/mono/MonoLinkedinIcon.vue'
import MonoYoutubeIcon from '../globals/Icons/socials/mono/MonoYoutubeIcon.vue'
import MonoTiktokIcon from '../globals/Icons/socials/mono/MonoTiktokIcon.vue'
import GlobeIcon from '../globals/Icons/GlobeIcon.vue'
export default {
  name: 'ExpertProfileSettings',
  components: {
    FolderDropdown,
    ExpertSettingsIcon,
    UploadIcon
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const socialPlatforms = [
      { placeholder: 'X/Twitter', icon: MonoTwitterIcon, model: 'twitter' },
      { placeholder: 'Instagram', icon: MonoInstagramIcon, model: 'instagram' },
      { placeholder: 'YouTube', icon: MonoYoutubeIcon, model: 'youtube' },
      { placeholder: 'Facebook', icon: MonoFacebookIcon, model: 'facebook' },
      { placeholder: 'LinkedIn', icon: MonoLinkedInIcon, model: 'linkedin' },
      { placeholder: 'TikTok', icon: MonoTiktokIcon, model: 'tiktok' },
      { placeholder: 'Website URL', icon: GlobeIcon, model: 'websiteUrl' }
    ]
    return {
      socialPlatforms,

      selectedFolder: null,
      expertPhoto: '',
      utm: '',
      social: {
        twitter: '',
        instagram: '',
        youtube: '',
        facebook: '',
        linkedin: '',
        tiktok: '',
        websiteUrl: ''
      },

      expertProfile: {
        firstName: '',
        lastName: ''
      },
      loading: {
        saveExpert: false
      }
    }
  },
  computed: {
    hasDataChanged () {
      return Object.keys(this.expertProfile).some(key => this.expertProfile[key] !== this.currentUser?.expertProfile?.[key]) ||
        this.selectedFolder !== this.currentUser?.expertProfile?.selectedFolder ||
        Object.keys(this.social).some(key => this.social[key] && this.social[key] !== this.currentUser?.expertProfile?.[key]) ||
        this.utm !== this.currentUser?.expertProfile?.utm || this.expertPhoto !== this.currentUser?.avatar
    }
  },
  async mounted () {
    const userExpertProfile = this.currentUser?.expertProfile
    this.expertProfile = {
      firstName: this.currentUser?.first_name,
      lastName: this.currentUser?.last_name
    }
    this.expertPhoto = this.currentUser?.avatar || ''
    Object.keys(this.social).forEach(key => {
      this.social[key] = userExpertProfile?.[key] || ''
    })
    this.utm = userExpertProfile?.utm || ''
    this.selectedFolder = userExpertProfile.selectedFolder ?? null
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async handleExpertCreate () {
      window.open('https://www.foreplay.co/experts-application', '_blank')
    },
    async saveExpertProfile () {
      this.loading.saveExpert = true
      const avatarLocation = await this.uploadNewAvatar()
      const newSelectedFolder = this.selectedFolder ?? ''
      const toUpdate = {
        ...this.expertProfile,
        ...this.social,
        selectedFolder: newSelectedFolder,
        utm: this.utm
      }
      const avatarToUpdate = avatarLocation ?? this.currentUser.avatar
      try {
        await FirebaseAPI.Users.updateExpert(this.currentUser, { expert: toUpdate, avatar: avatarLocation })
        this.SET_USER({
          ...this.currentUser,
          expertProfile: { ...toUpdate },
          avatar: avatarToUpdate
        })
        // Update local form inputs
        this.expertPhoto = avatarToUpdate
        this.selectedFolder = newSelectedFolder
        this.loading.saveExpert = false
        this.$showAlert({ type: 'success', message: 'Expert profile updated' })
      } catch (e) {
        console.error(e)
        this.$showAlert({ type: 'error', message: 'An error occurred while saving expert profile' })
        this.loading.expertSave = false
      }
    },
    async uploadNewAvatar () {
      if (this.expertPhoto && this.expertPhoto !== this.currentUser.avatar) {
        const user = this.currentUser
        const storage = firebase
          .app()
          .storage('gs://foreplay-user-avatars')

        // Delete old avatar if exists
        if (user && user?.avatar) {
          const oldAvatarPath = user.avatar
          const oldAvatarRef = storage.ref().child(
            oldAvatarPath.split(
              'https://storage.googleapis.com/foreplay-user-avatars/'
            )[1]
          )
          try {
            await oldAvatarRef.delete()
          } catch (e) {
            console.log('Error deleting avatar: ', e.message)
          }
        }

        // Handle if logo was removed
        if (this.expertPhoto === '') {
          return ''
        }

        // Upload logo to gstorage
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`
        const base64Logo = this.expertPhoto
        await storage.ref(filePath).putString(base64Logo, 'data_url')

        return `https://storage.googleapis.com/foreplay-user-avatars/${filePath}`
      } else if (this.expertPhoto === '') {
        return ''
      }
      return undefined
    },
    handleFileUpload (event) {
      this.showLogoCallout = false
      const mediaFile = event.target.files[0]
      if (!mediaFile) return
      const fileType = mediaFile.type
      if (!(fileType === 'image/png' || fileType === 'image/jpeg')) {
        this.$showAlert({
          message: 'Only JPEG and PNG accepted',
          type: 'error'
        })
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.expertPhoto = e.target.result
      }
      reader.readAsDataURL(mediaFile)
    },
    removePhoto () {
      this.expertPhoto = ''
      this.$refs.fileInput.value = ''
    },
    handleFileInput () {
      this.$refs.fileInput.click()
    }
  }

}
</script>

<style scoped>
.no-expert-container{
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}

.base-btn {
  background: linear-gradient(180deg, rgba(223, 225, 231, 0.08) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out;
}
.base-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}

.become-expert-btn {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.become-expert-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.14), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}

.info-grid {
  display: grid;
  grid-template-columns: 160px 1fr;
  column-gap: 4px;
  row-gap: 8px;
}

.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.preview-container {
  width: 1440px;
  border-top-left-radius: 12px;
  overflow: hidden;
}
</style>
