// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

const create = async (teamName, avatar = '') => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const { id } = await db.collection('teams').add({
    teamName,
    avatar,
    created_by: user.uid,
    members: [user.uid],
    created_at: new Date().getTime()
  })
  const snapshot = await db
    .collection('teams')
    .doc(id)
    .get()

  return { ...snapshot.data(), id }
}

const getCreatedTeam = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db
    .collection('teams')
    .where('createdBy', '==', user.uid)
    .get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...(doc.data() || [])
    }
  })
}

// Get By ID
const getByID = async teamId => {
  const team = await db
    .collection('teams')
    .doc(teamId)
    .get()

  return {
    id: team.id,
    ...team.data()
  }
}

const getTeamMembers = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db
    .collection('teams')
    .where('members', 'array-contains', user.uid)
    .get()

  const team = {
    id: snapshot.docs[0].id,
    ...(snapshot.docs[0].data() || [])
  }

  const teamMemberDocs = await Promise.all(
    team.members.map(async memberId =>
      db
        .collection('auth-users')
        .doc(memberId)
        .get()
    )
  )

  const teamMembers = teamMemberDocs.map(doc => {
    return {
      id: doc.id,
      ...(doc.data() || [])
    }
  })

  return teamMembers
}

const getUserTeam = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db
    .collection('teams')
    .where('members', 'array-contains', user.uid)
    .get()

  if (snapshot.docs?.length === 0) {
    return null
  } else {
    return {
      id: snapshot.docs[0].id,
      ...(snapshot.docs[0].data() || [])
    }
  }
}

const join = async teamId => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const teamDoc = await db
    .collection('teams')
    .doc(teamId)
    .get()
  const team = teamDoc.data()

  if (team.members.includes(user.uid)) {
    throw new Error('User is already a member')
  }
  await db
    .collection('teams')
    .doc(teamId)
    .update({ members: [...team.members, user.uid] })
}

const removeMember = async (userId, teamId) => {
  const teamDoc = await db
    .collection('teams')
    .doc(teamId)
    .get()
  const team = teamDoc.data()

  // Check if the user is in the team
  if (!team.members.includes(userId)) {
    throw new Error('User is not a member of the team')
  }

  // Check if the user is the creator of the team
  if (team.created_by === userId) {
    throw new Error('Team admin cannot be removed from the team')
  }

  await db.collection('teams').doc(teamId).update({ members: firebase.firestore.FieldValue.arrayRemove(userId) })

  // Counter-jank: We need to check if the user is a member of any other teams (legacy data bugs)
  // If they are, we need to remove them from said teams
  const otherTeams = await db.collection('teams').where('members', 'array-contains', userId).get()
  otherTeams.docs.forEach(doc => {
    if (doc.id !== teamId) {
      db.collection('teams').doc(doc.id).update({ members: firebase.firestore.FieldValue.arrayRemove(userId) })
    }
  })
}

const update = async (teamId, payload) => {
  return await db
    .collection('teams')
    .doc(teamId)
    .update(payload)
}

const getMigrationJobSnapshot = async (collection, userId, teamId) => {
  const snapshot = await db
    .collection(collection)
    .where('userId', '==', userId)
    .where('teamId', '==', teamId)
    .get()

  return snapshot
}

const getDeleteTeamJobSnapshot = async (teamId) => {
  const snapshot = await db
    .collection('team-delete-jobs')
    .where('teamId', '==', teamId)
    .get()

  return snapshot
}

export default {
  create,
  getByID,
  getCreatedTeam,
  getTeamMembers,
  join,
  removeMember,
  getUserTeam,
  update,
  getMigrationJobSnapshot,
  getDeleteTeamJobSnapshot,
}
