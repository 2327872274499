<template>
  <!-- Manage Board Modal -->
  <BaseModal cardWidth="440px" noPadding noToolbar noExit>
    <template #default>
      <!-- Loading State -->
      <div v-if="loadingTeamData" class="relative w-full flex items-center justify-center border-b border-border-normal" 
      style="height: 180px;">
        <BaseLoadingLogo class="relative top-2"/>
        <button class="absolute top-3 right-3 p-1 transition-opacity" @click="$emit('close')">
          <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
        </button>
      </div>
      <!-- Modal Content -->
      <div v-else class="relative w-full flex flex-col gap-1 p-5 border-b border-border-normal fade-in">
        <!-- Team Logo and Owner Avatar -->
        <div class="relative mx-auto" style="width: 68px; height: 68px;">
          <!-- Team Logo -->
          <img v-if="team.avatar" :src="team.avatar" class="team-logo w-9 h-9 rounded-md" alt="Team Logo" />
          <div v-else class="team-logo w-9 h-9 flex items-center justify-center p-2 rounded-md bg-neutral-900">
            <BaseText type="label" size="sm" class="text-white">
              {{ team?.teamName?.charAt(0) || 'T' }}
            </BaseText>
          </div>
          <!-- Team Owner Avatar -->
          <img v-if="teamOwner.avatar" :src="teamOwner.avatar" class="owner-avatar w-9 h-9 rounded-full" alt="Team Owner Avatar"
          referrerpolicy="no-referrer" />
          <div v-else class="owner-avatar w-9 h-9 flex items-center justify-center p-2 rounded-full bg-neutral-50">
            <BaseText type="label" size="sm" class="text-text-muted">
              {{ teamOwner?.name?.charAt(0) || teamOwner?.first_name?.charAt(0) || teamOwner?.last_name?.charAt(0) }}
            </BaseText>
          </div>
        </div>
        <!-- Invite Header -->
        <BaseText type="label" size="md" class="text-text-muted text-center">
          You're invited to join {{ team.teamName ? `'${team.teamName}'` : 'a team' }}
        </BaseText>
        <!-- Invite Message -->
        <BaseText type="body" size="sm" class="text-text-normal text-center">
          {{ teamOwner.name || `${teamOwner.first_name} ${teamOwner.last_name}` }} has invited you to join their team and collaborate on exciting projects together.
        </BaseText>
        <!-- Already in a team message -->
        <BaseText v-if="getTeam" type="label" size="sm" class="text-text-muted text-center mt-5 mb-1">
          You're already in a team. You'll need to leave your current team before joining this one.
        </BaseText>
        <!-- Close Button -->
        <button class="absolute top-3 right-3 p-1 transition-opacity" @click="$emit('close')">
          <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
        </button>
      </div>
      <!-- Modal Actions Footer -->
      <div class="w-full flex justify-between p-5">
        <button class="flex justify-center px-2 py-1.5 rounded-md transition duration-100 "
        :class="loadingDecline ? 'bg-neutral-25 cursor-default' : 'hover:bg-neutral-25'" 
        style="width: 66px;" :disabled="loadingDecline" 
        @click="rejectInvitation">
          <BaseLoadingSpinnerCircle v-if="loadingDecline" class="text-text-disabled" />
          <BaseText v-else type="label" size="sm" class="text-text-muted">
            Decline
          </BaseText>
        </button>
        <button class="submit-btn py-1.5 px-3 rounded-md"
        @click="goToTeamSettings">
          <BaseText type="label" size="sm">
            {{ getTeam ? 'Go to Settings' : 'Go to Invites' }}
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'TeamInvitationModal',
  components: {
    TagRemoveIcon
  },
  data () {
    return {
      invitation: {},
      team: {},
      teamOwner: {},
      loadingTeamData: true,
      loadingDecline: false,
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getPendingInvitations', 'getTeam'])
  },
  async mounted () {
    await this.getInvitationTeam()
    this.loadingTeamData = false
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserInvitations']),
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    async getInvitationTeam () {
      const team = await FirebaseAPI.Teams.getByID(this.getPendingInvitations[0].teamId)
      const teamOwner = await FirebaseAPI.Users.getById(team.created_by)
      this.team = team
      this.teamOwner = teamOwner
    },
    async rejectInvitation () {
      this.loadingDecline = true
      try {
        await FirebaseAPI.Invitations.reject(this.getPendingInvitations[0].id)
        await this.fetchUserInvitations()
        this.$emit('close')
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loadingDecline = false
      }
    },
    goToTeamSettings () {
      this.SET_SETTINGS_DRAWER_VIEW_MODE('team')
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.team-logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.owner-avatar {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-color {
  syntax: '<color>';
  initial-value: #0063F4;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out, 
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out, 
    --submit-btn-opacity-2 100ms ease-in-out,
    --submit-color 100ms ease-in-out;
}
.submit-btn:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
