import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'

const getAfterDoc = async (nextPage, archived) => {
  const { Aa: token } = firebase.auth().currentUser

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      ...(nextPage && { next: nextPage }),
      archived: archived ?? false
    }
  }

  const { data } = await AxiosConfig.get('/briefs', config)

  return data
}

const getByText = async (search) => {
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(`/briefs?textSearch=${search}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}

const getByQuery = async (query) => {
  const { Aa: token } = firebase.auth().currentUser

  const params = {}
  if (query.search) {
    params.textSearch = query.search
  }
  if (query.brandProfile) {
    params.brandProfileId = query.brandProfile
  }
  if (query.status) {
    params.status = query.status
  }
  if (query.nextPage) {
    params.next = query.nextPage
  }
  if (query.sort) {
    params.sort = query.sort
  }
  if (query.lastDocId) {
    params.next = query.lastDocId
  }

  params.archived = query?.archived ?? false
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  }

  const { data } = await AxiosConfig.get('/briefs', config)

  return data
}

const getAllSidebar = async () => {
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get('/briefs/sidebar',
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}
export default {
  getAfterDoc,
  getByText,
  getByQuery,
  getAllSidebar
}
