import store from '../../../store'
import router from '../../../router'

// We need misc state module here
async function liveUpdateAdsSetup (socket) {
  socket.on('adCreated', (data) => {
    console.log('Received adCreated event with data:', data)
    const currentRouteName = store.getters['RouterModule/getCurrentRoute']

    // Get the route object from routes array that matches the current route name
    const currentRoute = router.options.routes.find(route => route.name === currentRouteName)
    const routeMeta = currentRoute?.meta || {}

    console.log('current route metadata:', routeMeta)

    if (routeMeta.liveUpdate) {
      // Get current new ads from store
      const currentNewAds = store.getters['AdvertisementsModule/getNewAds']

      // If we're on BoardsDetailsView then we need to check if the ads board_ids contain the current board_id
      if (currentRouteName === 'BoardsDetailsView') {
        const routeParams = store.getters['RouterModule/getCurrentParams']
        const boardIds = data.board_ids

        if (boardIds.includes(routeParams.id)) {
          // Add the new ad to the array
          store.commit('AdvertisementsModule/SET_NEW_ADS', [...currentNewAds, data])
        }
      } else {
        // Add the new ad to the array
        store.commit('AdvertisementsModule/SET_NEW_ADS', [...currentNewAds, data])
      }
    }
  })
}

export default liveUpdateAdsSetup
