<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 64 64" fill="none">
    <path d="M36 27H55" stroke="white" stroke-opacity="0.36" stroke-width="2" stroke-linecap="round"/>
    <path d="M36 33H43" stroke="white" stroke-opacity="0.36" stroke-width="2" stroke-linecap="round"/>
    <path opacity="0.6" d="M10 30C10 24.4772 14.4772 20 20 20C25.5228 20 30 24.4772 30 30C30 35.5228 25.5228 40 20 40C18.5093 40 17.0999 39.6751 15.8343 39.0942C12.3874 37.5122 10 34.0329 10 30ZM20 16C12.268 16 6 22.268 6 30C6 34.9291 8.54761 39.2602 12.3909 41.7533L8.61852 49.7417C8.14685 50.7405 8.57419 51.9326 9.57299 52.4042C10.5718 52.8759 11.7638 52.4486 12.2355 51.4498L16.0242 43.4269C17.2862 43.8001 18.6211 44 20 44C27.732 44 34 37.732 34 30C34 22.268 27.732 16 20 16Z" 
    fill="white" stroke="#0F1018" stroke-width="2" stroke-linecap="round"/>
    <path opacity="0.6" d="M16 26L20 30M24 34L20 30M20 30L24 26M20 30L16 34" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'NoSearchResultsIcon',
  props: {
    width: {
      type: Number,
      default: 64
    },
    height: {
      type: Number,
      default: 64
    }
  }
}
</script>

<style scoped></style>