<template>
  <div 
    class="sidebar-root p-5 flex flex-col h-screen" 
    style="width: 288px; min-width: 288px"
    :class="currentRouteGroup ? `${currentRouteGroup}-gradient` : ''"
  >
    <!-- LOGO & COLLAPSE BUTTON -->
    <div class="flex items-center justify-between flex-shrink-0">
      <img src="../../assets/images/brief-foreplay-icon.svg" alt="" class="filter invert h-8">
      <!-- <button class="p-1 text-neutral-alpha-650 transition-colors duration-100 hover:text-white">
        <CollapseIcon />
      </button> -->
    </div>
    <!-- PRODUCT SELECTOR -->
    <div class="w-full flex flex-col flex-shrink-0 mt-3">
      <div class="flex items-center gap-0.5" @mouseenter="handleProductSwitcherHover" @mouseleave="handleProductSwitcherUnhover">
        <!-- Swipe File -->
        <router-link 
          :to="{ name: 'LibraryView' }" 
          class="product-button hover:bg-neutral-alpha-200 w-12 h-12"
          :class="!productSwitcherHovered && currentRouteGroup === 'library' ? 'bg-neutral-alpha-200' : 'hover:bg-neutral-alpha-50'"
          @mouseenter.native="hoveredProduct = 'library', labeledProduct = 'library'" 
          @mouseleave.native="hoveredProduct = null"
        >
          <AnimatedProductNavIcon product="library" key="library-anim-icon" :isHovered="hoveredProduct === 'library'" />
        </router-link>
        <!-- Discovery -->
        <router-link 
          :to="{ name: 'DiscoveryView' }" 
          class="product-button hover:bg-neutral-alpha-200 w-12 h-12"
          :class="!productSwitcherHovered && currentRouteGroup === 'discovery' ? 'bg-neutral-alpha-200' : 'hover:bg-neutral-alpha-50'"
          @mouseenter.native="hoveredProduct = 'discovery', labeledProduct = 'discovery'" 
          @mouseleave.native="hoveredProduct = null"
        >
          <AnimatedProductNavIcon product="discovery" key="discovery-anim-icon" :isHovered="hoveredProduct === 'discovery'" />
        </router-link>
        <!-- Spyder -->
        <router-link 
          :to="{ name: 'SpyderBrandsView' }" 
          class="product-button hover:bg-neutral-alpha-200 w-12 h-12"
          :class="!productSwitcherHovered && currentRouteGroup === 'spyder' ? 'bg-neutral-alpha-200' : 'hover:bg-neutral-alpha-50'"
          @mouseenter.native="hoveredProduct = 'spyder', labeledProduct = 'spyder'" 
          @mouseleave.native="hoveredProduct = null"
        >
          <AnimatedProductNavIcon product="spyder" key="spyder-anim-icon" :isHovered="hoveredProduct === 'spyder'" />
        </router-link>
        <!-- Briefs -->
        <router-link 
          :to="{ name: 'BriefsView' }" 
          class="product-button hover:bg-neutral-alpha-200 w-12 h-12"
          :class="!productSwitcherHovered && currentRouteGroup === 'briefs' ? 'bg-neutral-alpha-200' : 'hover:bg-neutral-alpha-50'"
          @mouseenter.native="hoveredProduct = 'briefs', labeledProduct = 'briefs'"
          @mouseleave.native="hoveredProduct = null"
        >
          <AnimatedProductNavIcon product="briefs" key="briefs-anim-icon" :isHovered="hoveredProduct === 'briefs'" />
        </router-link>
        <!-- Lens -->
        <router-link
          :to="{ name: 'LensView' }"
          class="product-button hover:bg-neutral-alpha-200 w-12 h-12"
          :class="!productSwitcherHovered && currentRouteGroup === 'lens' ? 'bg-neutral-alpha-200' : 'hover:bg-neutral-alpha-50'"
          @mouseenter.native="hoveredProduct = 'lens', labeledProduct = 'lens'"
          @mouseleave.native="hoveredProduct = null"
        >
          <AnimatedProductNavIcon product="lens" key="lens-anim-icon" :isHovered="hoveredProduct === 'lens'" />
        </router-link>
      </div>
      <!-- Animated Product Name/Hotkey Label -->
      <transition name="product-label">
        <div v-if="labeledProduct || productSwitcherHovered" class="relative w-full h-8 overflow-hidden">
          <div class="product-label-container cursor-default" :class="{ 'disable-transitions': !doAnimLabel }"
          :style="{ transform: `translateX(${labelOffsetDistance}px)` }">
            <div class="product-label pl-2 py-0.5 pr-0.5 rounded-lg bg-neutral-alpha-200"
            :style="{ width: productNameWidth }">
              <transition-group name="product-label-swap" :css="doAnimLabel">
                <BaseText v-for="key in productKeys" v-if="labeledProduct === key || (!labeledProduct && key === 'library')" 
                :key="`product-name-${key}`" type="label" size="sm" class="product-text text-white whitespace-nowrap">
                  {{ productNameMap.get(key) || 'Swipe File' }}
                </BaseText>
              </transition-group>
              <div class="product-hotkey px-1.5 py-0.5 rounded-md" :style="{ width: productHotkeyWidth }">
                <transition-group name="product-hotkey-swap" :css="doAnimLabel">
                  <BaseText v-for="key in productKeys" v-if="labeledProduct === key || (!labeledProduct && key === 'library')" 
                  :key="`product-hotkey-${key}`" type="label" size="sm" class="text-white">
                    {{ productHotkeyMap.get(key) || '⌘L' }}
                  </BaseText>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- HOME BUTTON -->
    <router-link :to="{ name: 'DashboardView' }" class="group w-full flex items-center gap-2 p-1.5 mt-3 rounded-md transition-colors duration-100 flex-shrink-0"
    :class="currentRouteGroup === 'home' ? 'bg-neutral-alpha-200' : 'hover:bg-neutral-alpha-50'">
      <HomeIcon :class="currentRouteGroup === 'home' ? 'text-neutral-alpha-700' : 'text-neutral-alpha-650 group-hover:text-neutral-alpha-700'" />
      <BaseText type="body" size="sm" :class="currentRouteGroup === 'home' ? 'text-neutral-alpha-800' : 'text-neutral-alpha-650 group-hover:text-neutral-alpha-800'">
        Home
      </BaseText>
    </router-link>
    <!-- SEARCH BAR / CREATE NEW BUTTON -->
    <div class="relative w-full flex items-center gap-1 mt-2 flex-shrink-0">
      <!-- Search Input -->
  <div class="flex-grow flex items-center gap-2 p-1.5 transition-colors duration-100 rounded-lg min-w-0"
      :class="{'bg-neutral-alpha-50': searchInputHovered && !searchInputFocused}"
      @mouseenter="searchInputHovered = true" @mouseleave="searchInputHovered = false">
        <DynamicSearchIcon sidebar :isHovered="searchInputHovered" :isActive="searchInputFocused" class="flex-shrink-0" />
        <input v-model="search" class="search-input flex-grow min-w-0 max-w-none" placeholder="Search..." @focus="searchInputFocused = true" @blur="searchInputFocused = false" />
        <!-- Clear Button -->
        <transition>
          <button v-if="searchInputFocused" class="text-neutral-alpha-600 transition-colors duration-100 hover:text-white"
          @mousedown.stop.prevent="search = ''">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M5.16675 5.16663L10.8334 10.8333M10.8334 5.16663L5.16675 10.8333" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          </button>
        </transition>
      </div>
      <!-- Create New Button -->
      <button class="create-new-button rounded-md" :class="{ 'active': createEntityDropdownExpanded }"
      @click="createEntityDropdownExpanded = !createEntityDropdownExpanded">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path d="M14 10.25V14M14 14V17.75M14 14H10.25M14 14H17.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
      <!-- Create Entity Dropdown -->
      <transition>
        <div v-if="createEntityDropdownExpanded" class="create-entity-dropdown" v-on-clickaway="() => createEntityDropdownExpanded = false">
          <SidebarItemDropdown :items="getCreateEntityDropdownItems()" @close="createEntityDropdownExpanded = false" />
        </div>
      </transition>
    </div>
    <!-- FOLDERS -->
    <div class="folders-container flex-grow flex flex-col pt-2 min-h-0 overflow-y-auto scrollbar-hide">
      <Folders 
        :is-beta-user="isBetaUser"
        :folder-modal-open.sync="folderModalOpen"
        :search="search"
        @showManageBoardModal="showManageBoardModal = true"
      />
    </div>
    <!-- SIDEBAR FOOTER -->
    <div class="relative w-full flex items-center flex-shrink-0">
      <button @click="showUserPopover = !showUserPopover">
        <BaseAvatar :avatar="getUserAvatar" size="28" class="flex-shrink-0 rounded-full object-fit" no-border />
      </button>
      <div id="olvy-whats-new" class="group cursor-pointer select-none ml-auto p-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-200"
      style="z-index: 200;">
        <ShipIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-white" />
      </div>
      <!-- User Popover -->
      <transition>
        <div v-if="showUserPopover" class="user-popover-container" v-on-clickaway="() => showUserPopover = false">
          <UserPopover :referral-link="referralLink" @itemClick="handleUserPopoverClick" />
        </div>
      </transition>
    </div>

    <!-- MODALS -->
    <div class="absolute">
      <SearchModal
        @focusSearch="focusSearch()"
      />
    </div>
    <PostPurchaseModal v-if="getShowUpsell && getInitialLoad" />
    <TeamInvitationModal v-else-if="!hideTeamInvitationModal && getPendingInvitations.length && getInitialLoad"
    @close="hideTeamInvitationModal = true" />
    <!-- <PaymentPastDueModal v-else-if="getTeam && getTeamSubscriptionState === 'past_due'" /> -->
    <UserPaymentFailModal v-else-if="trialExpired && getInitialLoad && ((getTeam && getTeamSubscriptionState === 'past_due' && isTeamOwner) || getUserSubscription.status === 'past_due')" />
    <TeamMemberPastDueModal v-else-if="trialExpired && getInitialLoad && getTeam && !isTeamCreator" />
    <TrialEndedModal v-else-if="trialExpired && getInitialLoad" />
    <OnboardingModal
      v-else-if="needsOnboarding && getInitialLoad"
      @close="completedOnboarding = true"
    />
    <InstagramModal
      v-if="showInstagramModal"
      @close="() => { showInstagramModal = false }"
    />
    <!-- Manage Board Modal -->
    <ManageBoardModal
      v-if="showManageBoardModal"
      :board="{}"
      @close="showManageBoardModal = false"
    />

    <!-- Folder Specific Modals -->
    <portal-target name="folder-modals" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import moment from 'moment'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

import firebase from '../../api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import AxiosConfig from '../../api/foreplayServer/serverAxiosConfig'
import Folders from '../sidebar/Folders'
import SidebarItemDropdown from '../sidebar/SidebarItemDropdown.vue'
import UserPopover from '../sidebar/UserPopover.vue'

// Modals
import InstagramModal from '../dashboard/Modals/InstagramModal.vue'
import ManageBoardModal from '../boards/ManageBoardModal.vue'
import OnboardingModal from '../../components/account/OnboardingModal.vue'
import PaymentPastDueModal from '../../components/account/subscription/PaymentPastDueModal.vue'
import PostPurchaseModal from '../account/subcriptionv2/PostPurchaseModal.vue'
import ProductHuntModal from '../account/ProductHuntModal.vue'
import SearchModal from '../search/SearchModal'
import TeamInvitationModal from '../../components/account/teams/TeamInvitationModal.vue'
import TeamMemberPastDueModal from '../account/subscription/TeamMemberPastDueModal.vue'
import TrialEndedModal from '../../components/account/subscription/TrialEndedModal.vue'
import UserPaymentFailModal from '../account/subscription/UserPaymentFailModal.vue'

// Icons
import AnimatedProductNavIcon from './Icons/SidebarIcons/AnimatedProductNavIcon.vue'
import CollapseIcon from './Icons/SidebarIcons/CollapseIcon.vue'
import HomeIcon from './Icons/SidebarIcons/HomeIcon.vue'
import DynamicSearchIcon from './Icons/SearchIcons/DynamicSearchIcon.vue'
import ShipIcon from './Icons/SidebarIcons/ShipIcon.vue'
import CreateFolderItemIcon from './Icons/SidebarIcons/dropdowns/CreateFolderItemIcon.vue'
import CreateBoardItemIcon from './Icons/SidebarIcons/dropdowns/CreateBoardItemIcon.vue'

export default {
  name: 'Sidebar',
  mixins: [clickaway],
  components: {
    Folders,
    SidebarItemDropdown,
    UserPopover,
    InstagramModal,
    ManageBoardModal,
    OnboardingModal,
    PaymentPastDueModal,
    PostPurchaseModal,
    ProductHuntModal,
    SearchModal,
    TeamInvitationModal,
    TeamMemberPastDueModal,
    TrialEndedModal,
    UserPaymentFailModal,
    AnimatedProductNavIcon,
    CollapseIcon,
    HomeIcon,
    DynamicSearchIcon,
    ShipIcon
  },
  data () {
    const productHotkeyMap = new Map([['library', '⌘L'], ['discovery', '⌘D'], 
      ['spyder', '⌘I'], ['briefs', '⌘B'], ['lens', '⌘X']])
    const productNameMap = new Map([['library', 'Swipe File'], ['discovery', 'Discovery'],
      ['spyder', 'Spyder'], ['briefs', 'Briefs'], ['lens', 'Lens']])

    return {
      betaIds: [],
      search: '',
      trialEnd: moment().format('X'),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      remixiconUrl,
      completedOnboarding: true,
      showManageBoardModal: false,
      closeProductHuntModal: false,
      unseenUpdates: false,
      editBoard: {},
      referralLink: '',

      // UI States
      productKeys: ['library', 'discovery', 'spyder', 'briefs', 'lens'],
      hoveredProduct: null,
      labeledProduct: null,
      productSwitcherHovered: false,
      doAnimLabel: true,
      productHotkeyMap,
      productNameMap,
      searchInputHovered: false,
      searchInputFocused: false,
      createEntityDropdownExpanded: false,
      showUserPopover: false,
      folderModalOpen: false,
      showInstagramModal: false,
      hideTeamInvitationModal: false
    }
  },
  async created () {
    this.fetchOrCreateReferral()
    if (!this.currentRouteGroup || this.currentRouteGroup === 'home') this.doAnimLabel = false
  },
  watch: {
    currentRouteGroup: {
      handler (newVal) {
        if (newVal === 'home') this.labeledProduct = null
        else this.labeledProduct = newVal
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getIsInspoMode']),
    ...mapGetters('AuthModule', [
      'getInitialLoad',
      'getPendingInvitations',
      'getShowUpsell',
      'getSurveyViewed',
      'getTeam',
      'getTeamSubscriptionState',
      'getUser',
      'getUserAvatar',
      'getUserEmail',
      'getUserFreeTrialDate',
      'getUserName',
      'getUserSubscription',
      'isFreeTier',
      'isTeamOwner',
      'isBetaUser'
    ]),
    ...mapGetters('MiscModule', ['getTheme']),
    showProductHuntModal () {
      return !this.getUser.viewedProductHuntModal && !this.closeProductHuntModal
    },
    isTeamCreator () {
      return this.getTeam && this.getTeam.created_by === this.getUser.user_id
    },
    trialExpired () {
      // check if a week has passed since trial and if they're free tier
      if (this.getTeam && ['past_due', 'canceled', 'inactive'].includes(this.getTeamSubscriptionState)) {
        return true
      } else if (this.getUserSubscription && ['past_due', 'canceled', 'inactive'].includes(this.getUserSubscription.status)) {
        return true
      }
      return this.isFreeTier && Date.now() > this.getUserFreeTrialDate + 6.048e8 && !this.getTeam && !this.isDiscovery
    },
    shouldSeeSurvey () {
      return !this.isFreeTier && !this.getSurveyViewed
    },
    canSeeBriefs () {
      return this.getUserSubscription?.product?.name === 'Full Workflow' || !this.getUserSubscription?.product?.name
    },
    needsOnboarding () {
      return !this.completedOnboarding && this.getUser.trialStarted > 1663193252602 && !this.getUser.onboardingComplete
    },

    // ====== UI COMPUTED VALUES ======

    currentRouteGroup () {
      if (!this.$route) return null
      if (this.$route.name === 'DashboardView') return 'home'
      return this.$route?.meta?.product ?? null
    },
    productLabelRenderData () {
      const defaults = {
        nameWidth: '121px',
        hotkeyWidth: '34px',
        offsetDistance: 0
      }
      const productConfig = {
        library: { nameWidth: '121px', hotkeyWidth: '34px', offsetDistance: 0 },
        discovery: { nameWidth: '121px', hotkeyWidth: '36px', offsetDistance: 14 },
        spyder: { nameWidth: '96px', hotkeyWidth: '30px', offsetDistance: 62 },
        briefs: { nameWidth: '93px', hotkeyWidth: '36px', offsetDistance: 114 },
        lens: { nameWidth: '86px', hotkeyWidth: '36px', offsetDistance: 144 }
      }
      return productConfig?.[this.labeledProduct] || defaults
    },
    productNameWidth () {
      return this.productLabelRenderData.nameWidth
    },
    productHotkeyWidth () {
      return this.productLabelRenderData.hotkeyWidth
    },
    labelOffsetDistance () {
      return this.productLabelRenderData.offsetDistance
    }
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    ...mapActions('BoardsModule', ['fetchBoards']),
    ...mapActions('AuthModule', ['fetchFreeTrialDate', 'checkTeamExpired']),
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    handleLogout () {
      console.log('logout')
      this.logoutUser()
    },
    sendToManagePlan () {
      this.$router.push({ name: 'PaymentView' })
    },
    async getBetaUsers () {
      this.betaIds = await FirebaseAPI.Beta.getBetaUserIds()
    },
    async fetchOrCreateReferral () {
      try {
        const { Aa: token } = firebase.auth().currentUser
        const response = await AxiosConfig.post('/campaigns/referral', null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        this.referralLink = response.data.url
      } catch (error) {
        console.log('referral error', error)
      }
    },
    handleDropdownClick (itemName) {
      if (itemName === 'Logout') {
        this.handleLogout()
      } else if (itemName === 'Settings' || itemName === 'Profile') {
        this.SET_SETTINGS_DRAWER_VIEW_MODE('account')
      } else if (itemName === 'Help') {
        this.showIntercom(itemName)
      } else if (itemName === 'Feature Releases') {
        window.open('https://foreplay.co/ships', '_blank')
      } else if (itemName === 'Perks & Discounts') {
        this.$router.push({ name: 'PerksView' })
      } else if (itemName === 'Become an Affiliate') {
        window.open('https://www.foreplay.co/affiliates', '_blank')
      }
    },
    handleUserPopoverClick (itemName) {
      switch (itemName) {
        case 'settings': this.SET_SETTINGS_DRAWER_VIEW_MODE('account'); break;
        case 'perks': this.$router.push({ name: 'PerksView' }); break;
        case 'affiliate': window.open('https://www.foreplay.co/affiliates', '_blank'); break;
        case 'clients': break;
        case 'marketers': break;
        case 'support': window.Intercom('show'); break;
        case 'knowledge': break;
        case 'logout': this.handleLogout(); break;
      }
      this.showUserPopover = false
    },
    getCreateEntityDropdownItems () {
      return [
        { name: 'Create Folder', action: () => { this.folderModalOpen = true }, icon: CreateFolderItemIcon },
        { name: 'Create Board', action: () => { this.showManageBoardModal = true }, icon: CreateBoardItemIcon }
      ]
    },

    // ==============================================================================
    // ================================= UI METHODS =================================
    // ==============================================================================

    focusSearch () {
      // Find the main search bar input if it exists, otherwise focus sidebar search
      const searchInput = document.querySelector('.search .search-input-container .search-input')
      if (searchInput) {
        searchInput.focus()
      } else {
        this.$refs.searchInput.focus()
      }
    },
    showIntercom (itemName) {
      if (itemName === 'Help') {
        window.Intercom('show')
      }
    },
    folderSearch ($event) {
      this.search = $event.target.value
    },
    handleProductSwitcherHover () {
      this.productSwitcherHovered = true
      setTimeout(() => {
        this.doAnimLabel = true
      }, 10)
    },
    handleProductSwitcherUnhover () {
      this.productSwitcherHovered = false
      if (!this.currentRouteGroup) this.doAnimLabel = false
      if (this.currentRouteGroup !== 'home') this.labeledProduct = this.currentRouteGroup
      else this.labeledProduct = null
    }
  }
}
</script>

<style scoped>
/* PRODUCT SELECTOR ANIMATION CLASSES */
.product-button {
  padding: 4px;
  border-radius: 10px;
  transition: background-color 150ms ease-in-out;
}
.product-label-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 121px;
  height: 28px;
  display: flex;
  justify-content: center;
  transform-origin: center;
  transition: transform 150ms ease-in-out;
}
.product-label {
  position: relative;
  transition: width 150ms ease-in-out;
}
.product-text {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.product-hotkey {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  transition: width 150ms linear;
  background-color: #0F1019;
  opacity: 0.68;
  display: flex;
  justify-content: center;
}
.product-label-enter-active {
  animation: productLabelEnter 150ms ease-in-out forwards;
}
.product-label-leave-active {
  animation: productLabelLeave 150ms ease-in-out forwards;
}
@keyframes productLabelEnter {
  0% { opacity: 0; height: 0px; }
  50% { opacity: 0; height: 32px; }
  100% { opacity: 1; height: 32px; }
}
@keyframes productLabelLeave {
  0% { opacity: 1; height: 32px; }
  50% { opacity: 0; height: 32px; }
  100% { opacity: 0; height: 0px; }
}
.product-label-swap-enter-active, .product-label-swap-leave-active, .product-hotkey-swap-enter-active {
  transition: opacity 150ms ease-in-out;
}
.product-hotkey-swap-leave-active {
  transition: opacity 150ms ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.product-label-swap-enter-from, .product-label-swap-enter, .product-label-swap-leave-to,
.product-hotkey-swap-enter-from, .product-hotkey-swap-enter, .product-hotkey-swap-leave-to {
  opacity: 0;
}
.product-label-swap-enter-to, .product-label-swap-leave-from,
.product-hotkey-swap-enter-to, .product-hotkey-swap-leave-from {
  opacity: 1;
}
.disable-transitions, .disable-transitions * {
  transition: none !important;
}
.create-new-button {
  transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
  color: rgba(255, 255, 255, 0.68);
  background-color: transparent;
}
.create-new-button:hover, .create-new-button.active {
  color: white;
  background-color: rgba(255, 255, 255, 0.12);
}
.search-input {
  color: white;
  caret-color: white;

  /* Remove default browser styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.search-input:focus {
  /* Remove default browser styles */
  outline: none;
  border: none;
  box-shadow: none;
}
.search-input::placeholder {
  transition: color 100ms ease-in-out;
  color: rgba(255, 255, 255, 0.68);
}
.search-input:hover::placeholder {
  color: white;
}
.search-input:focus::placeholder {
  color: rgba(255, 255, 255, 0.28);
}
.folders-container {
  /* Fade out folders content 50px from the bottom and 8px from the top */
  mask-image: 
    linear-gradient(to top, transparent, transparent 6px, black 50px, black),
    linear-gradient(to bottom, transparent, black 8px, black);
  mask-composite: intersect;
}
.create-entity-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  z-index: 1000;
}
.user-popover-container {
  position: absolute;
  bottom: calc(100% + 12px);
  left: 0;
  width: 248px;
  z-index: 1001;
}

/* Sidebar Gradient Colors/Animations */
@property --gradient-color {
  syntax: '<color>';
  inherits: false;
  initial-value: rgba(0, 0, 0, 0);
}
.sidebar-root {
  background: radial-gradient(100% 50% at 100% 50%, var(--gradient-color) 0%, rgba(15, 16, 25, 0.20) 100%);
  transition: --gradient-color 750ms ease-in-out;
}
.library-gradient {
  --gradient-color: rgba(0, 107, 255, 0.16);
}
.discovery-gradient {
  --gradient-color: rgba(109, 49, 232, 0.16);
}
.spyder-gradient {
  --gradient-color: rgba(255, 84, 83, 0.16);
}
.briefs-gradient {
  --gradient-color: rgba(31, 213, 163, 0.16);
}
.lens-gradient {
  --gradient-color: rgba(255, 255, 255, 0.16);
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
