<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 8L9.55806 5.94194C9.80214 5.69786 10.1979 5.69786 10.4419 5.94194L12.5 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M7.5 12L9.55806 14.0581C9.80214 14.3021 10.1979 14.3021 10.4419 14.0581L12.5 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'ChevronGrabberIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>