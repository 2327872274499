// Lens Module
import LensAPI from '@/api/lens'
import Fuse from 'fuse.js'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    fuse: null,
    fuzzyResults: new Set(),
    metricSearch: '',

    metrics: {},
    metricLookup: {}
  },
  getters: {
    getFuzzyResults: state => state.fuzzyResults,
    getMetrics: state => state.metrics,
    getMetricSearch: state => state.metricSearch,
    getMetricLookup: state => state.metricLookup,
    getFuse: state => state.fuse
  },
  mutations: {
    SET_FUZZY_RESULTS (state, results) {
      state.fuzzyResults = results
    },
    SET_METRIC_SEARCH (state, text) {
      state.metricSearch = text
    },
    SET_METRIC_LOOKUP (state, lookup) {
      state.metricLookup = { ...lookup }
    },
    SET_LENS_METRICS (state, metrics) {
      state.metrics = metrics
    },
    SET_FUSE (state, fuse) {
      state.fuse = fuse
    }
  },
  actions: {
    setFuzzyResults ({ commit }, results) {
      commit('SET_FUZZY_RESULTS', results)
    },
    updateTextSearch ({ commit }, text) {
      commit('SET_METRIC_SEARCH', text)
    },
    async fetchLensMetrics ({ state, commit }) {
      console.log('Fetching Lens Metrics')
      const { data } = await LensAPI.LensMetrics.getLensMetrics()
      const start = performance.now()

      const metrics = {}
      const metricLookup = {}
      // TODO @Sam: Add metricPlatform column and group off platform (meta, ga, etc..)
      data.forEach((item) => {
        metricLookup[item.key] = item

        const {
          parent_group: parentGroup,
          child_group: childGroup,
          table_group: tableGroup,
          col_order: colOrder,
          table_col: tableCol,
          table_row_name: tableRowName
        } = item

        // Ensure parent group exists
        if (!metrics[parentGroup]) {
          metrics[parentGroup] = {
            metrics: [],
            subSections: [],
            tableSections: {}
          }
        }

        if (!tableGroup) {
          // Child group
          if (childGroup) {
            const currentSub = metrics[parentGroup].subSections.find((sec) => sec.sectionId === childGroup)
            if (!currentSub) {
              metrics[parentGroup].subSections = [{ sectionId: childGroup, domId: childGroup, metrics: [item] }]
            } else {
              currentSub.metrics.push(item)
            }
          } else {
            metrics[parentGroup].metrics.push(item)
          }
        }
        // Table group
        else {
          const currentTable = metrics[parentGroup].tableSections[tableGroup]
          if (!currentTable) {
            const row = [null, null, null]
            const colHeaders = [null, null, null]
            row[colOrder - 1] = item
            colHeaders[colOrder - 1] = tableCol
            metrics[parentGroup].tableSections[tableGroup] = {
              // domId: tableGroup,
              metrics: { [tableRowName]: row },
              colHeaders
            }
          } else {
            if (!currentTable.metrics?.[tableRowName]) {
              const row = [null, null, null]
              row[colOrder - 1] = item
              currentTable.metrics[tableRowName] = row
            } else {
              currentTable.colHeaders[colOrder - 1] = tableCol
              currentTable.metrics[tableRowName][colOrder - 1] = item
            }
          }
        }
      })
      Object.keys(metrics).forEach((key) => {
        const sectionData = metrics[key]
        if (!_.isEmpty(sectionData.tableSections)) {
          const tableSubs = Object.entries(sectionData.tableSections).map(([tblSec, sectionData]) => {
            const formattedMetrics = Object.entries(sectionData.metrics).map(([row, metrics]) => ({
              rowName: row,
              metrics
            }))
            return {
              domId: tblSec,
              sectionId: tblSec,
              colHeaders: sectionData.colHeaders,
              metrics: formattedMetrics
            }
          })
          metrics[key].tableSections = tableSubs
        }
      })
      const end = performance.now()

      console.log(`%c Metric (Query + Format) execution time: ${end - start} ms`, 'color: green; font-size: 14px;')
      // Initialize Fuse searcher & metric lookup
      if (!state.fuse) {
        const allMetrics = data
        const options = {
          includeScore: true,
          keys: ['key', 'name'],
          ignoreLocation: true,
          threshold: 0.3
        }
        const fuse = new Fuse(allMetrics, options)
        commit('SET_FUSE', fuse)
        commit('SET_METRIC_LOOKUP', metricLookup)
      }
      commit('SET_LENS_METRICS', metrics)
    }
  }
}
