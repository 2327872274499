<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.99995 4.20001V15.8M15.8 11.4H9.99995M9.99995 8.60001H4.19995M5.79995 15.8H14.2C15.0836 15.8 15.8 15.0837 15.8 14.2V5.80001C15.8 4.91636 15.0836 4.20001 14.2 4.20001H5.79995C4.9163 4.20001 4.19995 4.91636 4.19995 5.80001V14.2C4.19995 15.0837 4.9163 15.8 5.79995 15.8Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BoardIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>