<template>
  <!-- Manage Board Modal -->
  <BaseModal
    card-width="400px"
    no-padding
    no-toolbar
    no-exit
    @close="handleClose"
  >
    <template #default>
      <!-- Modal Content -->
      <div class="relative w-full flex flex-col gap-5 p-5 border-b border-border-normal">
        <!-- Delete Icon -->
        <div class="flex items-center justify-center w-9 h-9 p-2 rounded-md bg-secondary-red-100">
          <DeleteIcon class="text-white" />
        </div>
        <!-- Modal Body Text -->
        <div class="flex flex-col gap-1">
          <BaseText
            type="label"
            size="md"
            class="text-text-loud"
          >
            {{ getTitle }}
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted mt-3"
          >
            {{ getDescription }}
          </BaseText>
          <BaseText
            v-if="folder"
            type="body"
            size="sm"
            class="text-text-muted mt-1"
          >
            Anything contained in this folder will be moved to the default folder.
          </BaseText>
          <BaseText
            v-if="template"
            type="label"
            size="sm"
            class="text-secondary-red-300 mt-1"
          >
            Once changed, script and reference images can not be recovered.
          </BaseText>
        </div>
        <!-- Confirmation Checkbox -->
        <div
          v-if="showCheckbox"
          class="flex items-center gap-2"
        >
          <input
            v-model="userConfirmed"
            type="checkbox"
            class="confirm-checkbox w-4 h-4 focus:ring-transparent cursor-pointer"
          >
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted"
          >
            I understand the above information.
          </BaseText>
        </div>
        <!-- Close button -->
        <button
          class="absolute top-3 right-3 p-1 transition-opacity"
          @click="handleClose"
        >
          <TagRemoveIcon
            stroke="#5E6678"
            :width="16"
            :height="16"
          />
        </button>
      </div>
      <!-- Modal Actions Footer -->
      <div class="w-full flex justify-between p-5">
        <button
          class="px-2 py-1.5 rounded-md transition duration-100 hover:bg-neutral-25"
          @click="handleClose"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Cancel
          </BaseText>
        </button>
        <button
          class="submit-btn py-1.5 px-3 rounded-md"
          style="width: 78px;"
          :disabled="!isFormValid"
          :class="isFormValid ? 'enabled' : 'disabled cursor-default'"
          @click="handleConfirm"
        >
          <BaseText
            type="label"
            size="sm"
          >
            {{ template ? 'Change' : 'Delete' }}
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import DeleteIcon from './Icons/DeleteIcon.vue'
import TagRemoveIcon from './Icons/TagRemoveIcon.vue'

export default {
  name: 'ConfirmDeleteModal',
  components: {
    DeleteIcon,
    TagRemoveIcon
  },
  props: {
    folder: Boolean,
    board: Boolean,
    template: Boolean,
    lens: Boolean,
    showCheckbox: Boolean
  },
  data () {
    return {
      userConfirmed: false
    }
  },
  computed: {
    isFormValid () {
      return this.userConfirmed || !this.showCheckbox
    },
    getTitle () {
      if (this.template) {
        return 'Are you sure you want to change the selected template?'
      } else if (this.board) {
        return 'Are you sure you want to delete the selected board?'
      } else if (this.lens) {
        return 'Are you sure you want to delete the selected lens?'
      } else {
        return `Are you sure you want to delete the selected ${this.folder ? 'folder' : 'brief'}?`
      }
    },
    getDescription () {
      if (this.template) {
        return 'Once changed, script and reference images can not be recovered.'
      } else if (this.board) {
        return 'Once deleted, boards can not be recovered.'
      } else if (this.lens) {
        return 'Once deleted, lenses can not be recovered. All associated data and reports will be lost.'
      } else {
        return `Once deleted, ${this.folder ? 'folders' : 'briefs'} can not be recovered.`
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    handleConfirm () {
      if (this.isFormValid) {
        this.$emit('confirm')
      }
    }
  }
}
</script>

<style scoped>
.confirm-checkbox {
  border-radius: 4px;
}
.confirm-checkbox:checked {
  background-color: #303546;
  color: #303546;
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --del-submit-color {
  syntax: '<color>';
  initial-value: #F4003A;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--del-submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #C80031;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out,
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out,
    --submit-btn-opacity-2 100ms ease-in-out,
    --del-submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --del-submit-color: #F6F8FA;
}
</style>
