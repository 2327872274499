<template>
  <div ref="tooltip" class="tracking-tooltip">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseTrackingTooltip',
  props: {
    leftRightPosOverride: {
      type: String,
      default: null
    }
  },
  mounted () {
    window.addEventListener('mousemove', this.handleMouseMove)
  },
  beforeDestroy () {
    window.removeEventListener('mousemove', this.handleMouseMove)
  },
  methods: {
    handleMouseMove (event) {
      if (!this.$refs.tooltip) return
      const tooltip = this.$refs.tooltip
      const tooltipRect = tooltip.getBoundingClientRect()
      const tooltipWidth = tooltipRect.width
      const tooltipHeight = tooltipRect.height
      const mouseX = event.clientX
      const mouseY = event.clientY

      let tooltipLeft
      const tooltipTop = mouseY - tooltipHeight / 2

      if (this.leftRightPosOverride === 'left') {
        tooltipLeft = mouseX - 24 - tooltipWidth
      } else if (this.leftRightPosOverride === 'right') {
        tooltipLeft = mouseX + 24
      } else {
        tooltipLeft = mouseX + 24
        if (tooltipLeft + tooltipWidth > window.innerWidth) {
          tooltipLeft = mouseX - 24 - tooltipWidth
        }
      }

      tooltip.style.left = `${tooltipLeft}px`
      tooltip.style.top = `${tooltipTop}px`
    }
  }
}
</script>

<style scoped>
.tracking-tooltip {
  position: fixed;
  z-index: 1000;
}
</style>