<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.16839 2.77063C9.68317 2.47424 10.3168 2.47424 10.8316 2.77063L16.0399 5.76934C16.5566 6.0668 16.875 6.61755 16.875 7.21371V12.7865C16.875 13.3827 16.5566 13.9335 16.0399 14.2309L10.8315 17.2296C10.3168 17.526 9.68317 17.526 9.1684 17.2296L3.96009 14.2312C3.44342 13.9337 3.12498 13.3829 3.12498 12.7868L3.12498 7.21369C3.12498 6.61753 3.44341 6.06677 3.96005 5.76932L9.16839 2.77063Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
    <path d="M12.7083 10.0002C12.7083 11.4959 11.4958 12.7085 10 12.7085C8.50423 12.7085 7.29167 11.4959 7.29167 10.0002C7.29167 8.5044 8.50423 7.29184 10 7.29184C11.4958 7.29184 12.7083 8.5044 12.7083 10.0002Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
  </svg>
</template>

<script>
export default {
  name: 'SettingsUserIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>