<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4.75 16V5.5C4.75 4.67157 5.42157 4 6.25 4H13.75C14.5784 4 15.25 4.67157 15.25 5.5V16M4.75 16H15.25M4.75 16H3.25M15.25 16H16.75M7.75 7H8.5M11.5 7H12.25M7.75 10H8.5M11.5 10H12.25M7.75 13H8.5M11.5 13H12.25" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'FindMarketersUserIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>