<template>
  <!-- Base Button -->
  <button
    :type="type"
    class="base-button select-none"
    :style="`${iconOnly ? 'padding: 6px !important; min-width: 20px;' : ''}`"
    :class="getBaseButtonClasses"
    :disabled="disabled"
    @click="handleClick"
  >
    <SuccessLottie v-if="success" />

    <span
      v-else-if="!loading && !iconOnly"
      class="text"
    >
      <slot />
    </span>

    <div v-else-if="!loading && iconOnly">
      <slot />
    </div>

    <BaseLoadingSpinner
      v-else
      :small="!large"
      :dark="outlined"
    />
  </button>
</template>

<script>
import { mapGetters } from 'vuex'
import SuccessLottie from '@/components/globals/Icons/SuccessLottie'

export default {
  name: 'BaseButton',
  components: {
    SuccessLottie
  },
  props: {
    // Primary Style
    primary: {
      type: Boolean
    },
    animate: {
      type: Boolean
    },
    theme: {
      type: Boolean
    },
    forcedTheme: {
      type: String,
      default: ''
    },
    // CTA Style (Green)
    cta: {
      type: Boolean
    },
    large: {
      type: Boolean
    },
    // Primary Style
    outlined: {
      type: Boolean
    },
    danger: {
      type: Boolean
    },
    destroy: {
      type: Boolean
    },
    text: {
      type: Boolean
    },
    iconOnly: {
      type: Boolean
    },
    // Is Button Disabled
    disabled: {
      type: Boolean
    },
    // Loading State
    loading: {
      type: Boolean
    },
    // Bubbly Animation
    bubbly: {
      type: Boolean
    },
    paddingX: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'button'
    },
    success: {
      type: Boolean
    }
  },
  data: function () {
    return {
      runBubblyAnimation: false
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getLastTheme', 'getThemeChangedAt']),

    // Get Button Classes
    getBaseButtonClasses () {
      // Start with the animation classes
      const classes = []

      if ((this.primary || this.animate) && +new Date() - this.getThemeChangedAt < 1000) {
        // So buttons mounted after the theme changes dont animate
        classes.push(`${this.getLastTheme}-to-${this.getTheme}`)
      }

      if (this.theme || this.primary || this.cta) {
        classes.push(
          `bg-primary-${this.forcedTheme.length ? this.forcedTheme : this.getTheme}-100`,
          `${this.forcedTheme.length ? this.forcedTheme : this.getTheme}-button`
        )
      }

      if (this.bubbly) {
        classes.push('bubbly-button')
      }

      if (this.runBubblyAnimation) {
        classes.push('animate')
      }

      if (this.primary) {
        classes.push('base-button--primary')
      }
      if (this.cta) classes.push('base-button--cta')
      if (this.outlined) classes.push('base-button--outlined')
      if (this.text) classes.push('base-button--text')
      if (this.disabled) classes.push('base-button--disabled')

      if (this.danger) classes.push('danger-button')
      if (this.destroy) classes.push('destroy-button')
      if (this.large) classes.push('large')
      if (this.paddingX) {
        classes.push(`px-${this.paddingX}`)
      } else {
        if (!this.iconOnly) {
          classes.push('px-3')
        }
      }

      return classes
    }
  },
  methods: {
    handleClick () {
      if (this.bubbly) {
        this.runBubblyAnimation = true
      }

      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="sass">
// Blue Primary Button
.blue-button
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #1F69FF
  border: 1px solid rgba(255, 255, 255, 0.12)
  box-shadow: 0px 0px 0px 1px #1E62EB, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:hover
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0) 100%), #1F69FF
    box-shadow: 0px 0px 0px 1px #1E62EB, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:active
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 100%), #1F69FF
    box-shadow: 0px 0px 0px 1px #1E62EB, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:focus
    @apply ring-2 ring-offset-2 ring-primary-blue-25

  // Green Primary Button
.green-button
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #009F6D
  border: 1px solid rgba(255, 255, 255, 0.12)
  box-shadow: 0px 0px 0px 1px #1F8E6B, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:hover
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0) 100%), #009F6D
    box-shadow: 0px 0px 0px 1px #1F8E6B, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:active
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 100%), #009F6D
    box-shadow: 0px 0px 0px 1px #1F8E6B, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:focus
    @apply ring-2 ring-offset-2 ring-primary-green-25

  // Purple Primary Button
.purple-button
  background: linear-gradient(180deg, color(display-p3 1 1 1 / 0.12) 0%, color(display-p3 1 1 1 / 0.00) 100%), color(display-p3 0.0385 0.0411 0.0615)
  border: 1px solid rgba(255, 255, 255, 0.12)
  box-shadow: 0px 0px 0px 1px #6336D3, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:hover
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0) 100%), #6534DF
    box-shadow: 0px 0px 0px 1px #6336D3, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:active
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 100%), #6534DF
    box-shadow: 0px 0px 0px 1px #6336D3, inset 0px -1px 12px rgba(255, 255, 255, 0.12)

  &:focus
    @apply ring-2 ring-offset-2 ring-primary-purple-25

.red-button
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #FF5453
  border: 1px solid rgba(255, 255, 255, 0.12)
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #FF5453

  &:hover
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.12) 100%), #FF5453
    box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #FF5453

  &:active
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 100%), #FF5453
    box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #FF5453

  &:focus
    @apply ring-2 ring-offset-2 ring-primary-red-25

// Danger Button
.danger-button
  background: linear-gradient(180deg, rgba(223, 225, 231, 0) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF
  box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08)
  @apply transition

  .text
    @apply text-secondary-red-100

  &:hover
    .text
      @apply text-secondary-red-200

  &:active
    background: linear-gradient(180deg, rgba(223, 225, 231, 0.12) 0%, rgba(223, 225, 231, 0) 100%), #FFFFFF
    box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08)

    .text
      @apply text-secondary-red-300

  &:focus
    .text
      @apply text-secondary-red-100

    @apply ring-2 ring-offset-2 ring-secondary-red-25 border border-gray-300

// Destroy Button
.destroy-button
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #DF1C41
  border: 1px solid rgba(255, 255, 255, 0.12)
  box-shadow: 0px 1px 2px rgba(113, 14, 33, 0.5), 0px 0px 0px 1px #B71836

  .text
    @apply text-white

  &:hover
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0) 100%), #DF1C41
    border: 1px solid rgba(255, 255, 255, 0.12)
    box-shadow: 0px 1px 2px rgba(113, 14, 33, 0.5), 0px 0px 0px 1px #B71836

  &:active
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 100%), #DF1C41
    border: 1px solid rgba(255, 255, 255, 0.12)
    box-shadow: 0px 1px 2px rgba(113, 14, 33, 0.5), 0px 0px 0px 1px #B71836

  &:focus
    @apply ring-2 ring-offset-2 ring-secondary-red-25

.base-button
  box-sizing: border-box
  height: min-content

  /* Auto layout */
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding-top: 6px
  padding-bottom: 6px
  gap: 4px

  min-width: 96px
  min-height: 28px

  border-radius: 6px

  @apply transition whitespace-nowrap
  .text
    font-weight: 500
    font-size: 14px
    line-height: 20px
    padding-left: 4px
    padding-right: 4px

    flex: none
    order: 1
    flex-grow: 0

  &--primary
    @apply border-none text-white rounded-md

  &--cta
    @apply border-none text-white rounded-md

  &--outlined
    @apply bg-white border border-gray-300 text-gray-600 rounded-md

    &:hover
      background: linear-gradient(180deg, rgba(223, 225, 231, 0) 0%, rgba(223, 225, 231, 0.12) 100%), #FFFFFF
      box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08)

    &:active
      background: linear-gradient(180deg, rgba(223, 225, 231, 0.12) 0%, rgba(223, 225, 231, 0) 100%), #FFFFFF
      box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08)

    &:focus
      @apply ring-2 ring-offset-2 ring-neutral-50

  &--text
    height: auto

    &:hover
      @apply bg-neutral-25

    &:focus
      @apply ring-2 ring-offset-2 ring-neutral-100 bg-neutral-25

    &:active
      @apply bg-neutral-50

    &.text
      display: flex
      @apply text-neutral-600

  &:disabled
    gap: 6px
    font-weight: 500
    border-radius: 6px
    border: none
    box-shadow: none

    @apply bg-neutral-25

    .text
      @apply text-neutral-300

.large
  padding-top: 8px
  padding-bottom: 8px
  padding-left: 10px
  padding-right: 10px

  .text
    padding-left: 6px
    padding-right: 6px

.black-button
  border-radius: 6px
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #0A0A10
  border: 1px solid rgba(255, 255, 255, 0.12)
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0A0A11

  &:hover
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.12) 100%), #0A0A10
    box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0A0A11

  &:active
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.18) 100%), #0A0A10
    box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0A0A11

  &:focus
    @apply ring-2 ring-offset-2 ring-gray-900
</style>
