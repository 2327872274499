<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 7.18403V12.625C13 15.3174 10.8174 17.5 8.125 17.5C5.43261 17.5 3.25 15.3174 3.25 12.625C3.25 9.93257 5.43261 7.75 8.125 7.75C8.51223 7.75 8.88888 7.79514 9.25 7.88045V10.2526C8.90905 10.0906 8.5276 10 8.125 10C6.67525 10 5.5 11.1753 5.5 12.625C5.5 14.0747 6.67525 15.25 8.125 15.25C9.57475 15.25 10.75 14.0747 10.75 12.625V2.5H13C13 4.57107 14.679 6.25 16.75 6.25V8.5C15.3311 8.5 14.0272 8.00746 13 7.18403Z"
      fill="currentColor"
    />

  </svg>
</template>
<script>
export default {
  name: 'MonoTiktokIcon',
  props: {
    width: {
      type: Number,
      default: () => 20
    },
    height: {
      type: Number,
      default: () => 20
    }
  }
}
</script>
  <style scoped lang="css"></style>
