<template>
  <div class="relative w-10 h-10 overflow-hidden" style="user-select: none;">
    <img v-if="!spriteSheetLoaded" :src="`https://publicassets.foreplay.co/nav-preload-${product}.png`" class="w-10 h-10">
    <img v-show="spriteSheetLoaded" :src="`https://publicassets.foreplay.co/nav-spritesheet-${product}.png`" 
    ref="spritesheet" class="spritesheet" draggable="false" @load="spriteSheetLoaded = true" />
  </div>
</template>

<script>
// ==== Product sprite sheet frame counts ====
// Briefs frame 55
// Lens frame 21
// Swipefile frame 54
// Discovery frame 62
// Spyder frame 31

import { animate, easeInOut } from 'popmotion'

const FRAME_SIZE = 40

export default {
  name: 'AnimatedProductNavIcon',
  props: {
    product: {
      type: String,
      required: true
    },
    isHovered: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 300 // ms
    }
  },
  data () {
    return {
      spriteSheetLoaded: false,
      currentAnimation: null,
      currentFrame: 0
    }
  },
  watch: {
    isHovered (newVal) {
      if (newVal) {
        this.playAnimationForward()
      } else {
        this.playAnimationReverse()
      }
    }
  },
  computed: {
    frameCount () {
      // We subtract 1 from the frame count since we are 0 indexing the frames
      switch (this.product) {
        case 'library': return 53
        case 'discovery': return 61
        case 'spyder': return 30
        case 'briefs': return 54
        case 'lens': return 20
        default: return 0
      }
    }
  },
  methods: {
    playAnimationForward () {
      const spritesheet = this.$refs.spritesheet
      if (!spritesheet) return

      // Abort any current animation
      this.abortCurrentAnimation()

      const frameDiff = this.frameCount - this.currentFrame
      const animDuration = (frameDiff / this.frameCount) * this.duration // ms
      const startFrame = this.currentFrame
      this.currentAnimation = animate({
        from: startFrame,
        to: this.frameCount,
        duration: animDuration,
        ease: easeInOut,
        onUpdate: (value) => {
          this.currentFrame = Math.round(value)
          spritesheet.style.transform = `translateX(-${this.currentFrame * FRAME_SIZE}px)`
        },
        onComplete: () => {
          this.currentAnimation = null
        }
      })
    },
    playAnimationReverse () {
      const spritesheet = this.$refs.spritesheet
      if (!spritesheet) return

      // Abort any current animation
      this.abortCurrentAnimation()
      
      const animDuration = (this.currentFrame / this.frameCount) * this.duration // ms
      const startFrame = this.currentFrame
      this.currentAnimation = animate({
        from: startFrame,
        to: 0,
        duration: animDuration,
        ease: easeInOut,
        onUpdate: (value) => {
          this.currentFrame = Math.round(value)
          spritesheet.style.transform = `translateX(-${this.currentFrame * FRAME_SIZE}px)`
        }
      })
    },
    abortCurrentAnimation () {
      if (this.currentAnimation) {
        this.currentAnimation.stop()
        this.currentAnimation = null
      }
    }
  }
};
</script>

<style scoped>
.spritesheet {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  max-width: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}
</style>