import { render, staticRenderFns } from "./PerksUserIcon.vue?vue&type=template&id=f5a05500&scoped=true"
import script from "./PerksUserIcon.vue?vue&type=script&lang=js"
export * from "./PerksUserIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5a05500",
  null
  
)

export default component.exports