import { render, staticRenderFns } from "./RemoveTeamMemberModal.vue?vue&type=template&id=008666c3&scoped=true"
import script from "./RemoveTeamMemberModal.vue?vue&type=script&lang=js"
export * from "./RemoveTeamMemberModal.vue?vue&type=script&lang=js"
import style0 from "./RemoveTeamMemberModal.vue?vue&type=style&index=0&id=008666c3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008666c3",
  null
  
)

export default component.exports