var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"relative inline-flex flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none",class:[_vm.value ? `bg-${_vm.color}` : 'bg-neutral-50', _vm.small ? 'w-7 h-4' : 'w-11 h-6'],staticStyle:{"padding-top":"1.5px","padding-left":"2px"},attrs:{"type":"button","role":"switch","aria-checked":"false"},on:{"click":_vm.updateValue}},[_c('span',{staticClass:"pointer-events-none relative inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",class:{
      'translate-x-5': _vm.value,
      'translate-x-0': !_vm.value
    },style:({
      width: _vm.small ? '13px' : '20px', 
      height: _vm.small ? '13px' : '20px',
      transform: _vm.value ? _vm.small ? 'translateX(11px)' : 'translateX(20px)' : 'translateX(0px)'
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }