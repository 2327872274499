<template>
  <!-- App -->
  <div
    id="app"
    class="transition-colors duration-1000"
    :class="`${getTheme}-background `"
  >
    <!-- Left Navigation -->
    <Sidebar v-if="isUserLoggedIn && !isRoutePublic && !hideSidebar" />

    <!-- Settings Drawer -->
    <SettingsDrawer
      v-if="isUserLoggedIn && getSettingsDrawerViewMode"
      @close="closeSettingsDrawer"
    />

    <!-- Briefs Submissions -->
    <!-- <UploadSidebar v-if="this.$route.name === 'BriefPublicView'" /> -->

    <!-- Main Routes -->
    <router-view :key="$route.fullPath" />

    <!-- Base Notifications -->
    <BaseNotifyAlert />
  </div>
</template>

<script>
import Sidebar from './components/globals/Sidebar'
import SettingsDrawer from './components/settings/SettingsDrawer.vue'
import UploadSidebar from './components/globals/UploadSidebar'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: { Sidebar, SettingsDrawer, UploadSidebar },
  computed: {
    ...mapGetters('AuthModule', ['isUserLoggedIn']),
    ...mapGetters('MiscModule', ['getSettingsDrawerViewMode', 'getTheme']),
    ...mapGetters('RouterModule', ['getCurrentRouteId']),
    hideSidebar () {
      return this.$route.meta.hideSidebar
    },
    isRoutePublic () {
      return !this.$route.meta.requiresAuth
    }
  },
  watch: {
    // We use a watcher here since we need to wait for the router to finish initializing
    // If there's a settings query, we'll set the settings drawer view mode to trigger the drawer to open
    '$route.query.settings': {
      handler (settingsView) {
        const validSettingsViews = ['account', 'team', 'whitelabel', 'referral', 'integrations', 'plan', 'billing', 'notifications', 'lens', 'expert']
        if (validSettingsViews.includes(settingsView)) {
          this.SET_SETTINGS_DRAWER_VIEW_MODE(settingsView)

          if (settingsView === 'lens') {
            const params = new URLSearchParams(window.location.href)
            console.log('Setting current route id', params.get('lensId'))

            this.SET_CURRENT_ROUTE_ID(params.get('lensId'))
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    ...mapMutations('RouterModule', ['SET_CURRENT_ROUTE_ID']),
    closeSettingsDrawer () {
      this.SET_SETTINGS_DRAWER_VIEW_MODE(null)
      this.SET_CURRENT_ROUTE_ID(null)
    }
  }
}
</script>

<style lang="sass" scoped>
#app
  font-family: "Inter var", "system-ui"
  font-size: 15px
  background: linear-gradient(0deg, rgba(219, 222, 241, 0.04) 0%, rgba(219, 222, 241, 0.04) 100%), #060710
  @apply flex h-full min-h-screen w-screen

// Disabled until we want to make the app bg dynamic:
  
// .green-background
//   background-color: #061B16

// .blue-background
//   background-color: #06081B

// .purple-background
//   background-color: #0F061B

// .red-background
//   background-color: #0e080c

</style>
