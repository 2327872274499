<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M15.1202 7.96982H11.0144C10.8276 7.96982 10.676 7.81829 10.676 7.63138V3.50338C10.676 3.16853 10.2417 3.03704 10.056 3.31565L4.59647 11.5049C4.44653 11.7298 4.60776 12.0311 4.87807 12.0311H8.98382C9.1707 12.0311 9.32225 12.1826 9.32225 12.3695V16.4975C9.32225 16.8323 9.75654 16.9639 9.94227 16.6853L15.4018 8.49599C15.5517 8.27108 15.3905 7.96982 15.1202 7.96982Z"
    stroke="currentColor" stroke-width="1.35375" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'PerformanceMetricsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
