// Which colors are used for the selected metrics/columns/kpis in the Lens report.
// The first color is used for the first selected metric/column/kpi, the second for the second, etc.

export default [
  '#003A7D',
  '#008DFF',
  '#AF2DF0',
  '#FF73B6',
  '#F48852',
  '#F9E858',
  '#823C00',
  '#DB4246'
]