var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"base-drawer",class:[{'exit-overlay-hovered': _vm.exitOverlayHovered}, _vm.forceNoScroll ? 'overflow-y-hidden' : 'overflow-y-scroll']},[_c('div',{staticClass:"relative w-full h-20",on:{"mouseenter":function($event){_vm.exitOverlayHovered = true},"mouseleave":function($event){_vm.exitOverlayHovered = false},"click":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"close-drawer-indicator flex items-center gap-3 py-2 pl-2.5 pr-4 cursor-pointer shadow-lg bg-neutral-900 text-sm text-white rounded-lg transition-opacity duration-300 ease-in-out",class:{'opacity-100': _vm.exitOverlayHovered, 'opacity-0': !_vm.exitOverlayHovered}},[_c('DownArrowIcon'),_c('div',[_vm._v("ESC to close "+_vm._s(_vm.closeLabel))])],1)]),_c('div',{staticClass:"base-drawer--card",class:[{
        'rounded-3xl': !_vm.noBottomRounding,
        'rounded-t-3xl': _vm.noBottomRounding,
        'h-auto min-h-screen': _vm.heightAuto,
        'h-full': !_vm.heightAuto && !_vm.heightAutoOnly,
        'h-auto': _vm.heightAutoOnly,
        'exit-hover-entering': _vm.exitOverlayHovered, 
        'exit-hover-leaving': !_vm.exitOverlayHovered
      },
      _vm.mobileView ? 'left-1.5 right-1.5' : 'left-3 right-3']},[(!_vm.noToolbar)?_c('div',{staticClass:"base-drawer--card--toolbar",class:{'p-4' : !_vm.noPadding }},[_vm._t("toolbar")],2):_vm._e(),_c('div',{staticClass:"base-drawer--card--content"},[_vm._t("content")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }