import { render, staticRenderFns } from "./OpContainsIcon.vue?vue&type=template&id=37f7b1a3&scoped=true"
import script from "./OpContainsIcon.vue?vue&type=script&lang=js"
export * from "./OpContainsIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f7b1a3",
  null
  
)

export default component.exports