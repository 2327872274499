<template>
  <div class="user-popover flex flex-col p-1">
    <!-- Avatar/Name/Email -->
    <div class="flex items-center gap-2 pl-3 py-1.5 pr-6">
      <BaseAvatar :avatar="getUser.avatar" size="36" class="flex-shrink-0 rounded-full object-fit" no-border />
      <div class="flex flex-col min-w-0">
        <BaseText type="label" size="sm" class="text-white truncate">
          {{ getUser.name || `${getUser.first_name} ${getUser.last_name}` }}
        </BaseText>
        <BaseText type="body" size="sm" class="text-neutral-alpha-650 truncate">
          {{ getUser.email }}
        </BaseText>
      </div>
    </div>
    <!-- Affiliate Link -->
    <div class="w-full flex flex-col gap-1 mt-1 px-3 py-2.5 rounded-md bg-neutral-alpha-200">
      <BaseText type="label" size="sm" class="text-white">
        Affiliate Link
      </BaseText>
      <div class="w-full flex items-center gap-2 min-w-0">
        <BaseText type="body" size="sm" class="flex-grow text-neutral-alpha-800 truncate">
          {{ referralLink }}
        </BaseText>
        <button class="text-neutral-alpha-650 transition-colors duration-100 hover:text-neutral-alpha-800"
        v-clipboard:copy="referralLink" v-clipboard:success="handleCopyReferralLink">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M12.7083 12.7082V16.6665C12.7083 17.2418 12.242 17.7082 11.6667 17.7082H3.33332C2.75803 17.7082 2.29166 17.2418 2.29166 16.6665V8.33317C2.29166 7.75787 2.75803 7.2915 3.33332 7.2915H7.29166M8.33332 12.7082H16.6667C17.242 12.7082 17.7083 12.2418 17.7083 11.6665V3.33317C17.7083 2.75787 17.242 2.2915 16.6667 2.2915H8.33332C7.75803 2.2915 7.29166 2.75787 7.29166 3.33317V11.6665C7.29166 12.2418 7.75803 12.7082 8.33332 12.7082Z" 
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
    <!-- Action Buttons -->
    <div class="relative w-full flex flex-col mt-1">
      <!-- Settings -->
      <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'settings')">
        <SettingsUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Settings
        </BaseText>
      </button>
      <!-- Perks & Benefits -->
      <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'perks')">
        <PerksUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Perks & Benefits
        </BaseText>
      </button>
      <!-- Private Mode -->
      <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="togglePrivateMode">
        <PrivateModeUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Private Mode
        </BaseText>
        <BaseToggle :value="getIsPrivateMode" small class="ml-auto" />
      </button>
      <!-- Divider -->
      <div class="relative h-0 my-1.5 border-t border-neutral-alpha-50" style="width: calc(100% + 8px); left: -4px;" />
      <!-- Earn Money as Affiliate -->
      <!-- <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'affiliate')">
        <AffiliateUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Earn $10k as an Affiliate
        </BaseText>
      </button> -->
      <!-- Find New Clients -->
      <!-- <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'clients')">
        <FindClientsUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Find New Clients
        </BaseText>
      </button> -->
      <!-- Find Marketers -->
      <!-- <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'marketers')">
        <FindMarketersUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Find Marketers
        </BaseText>
      </button> -->
      <!-- Divider -->
      <!-- <div class="relative h-0 my-1.5 border-t border-neutral-alpha-50" style="width: calc(100% + 8px); left: -4px;" /> -->
      <!-- Customer Support -->
      <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'support')">
        <CustomerSupportUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Customer Support
        </BaseText>
      </button>
      <!-- Knowledge Base -->
      <!-- <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'knowledge')">
        <KnowledgeBaseUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Knowledge Base
        </BaseText>
      </button> -->
      <!-- Divider -->
      <div class="relative h-0 my-1.5 border-t border-neutral-alpha-50" style="width: calc(100% + 8px); left: -4px;" />
      <!-- Logout -->
      <button class="group w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="$emit('itemClick', 'logout')">
        <LogoutUserIcon class="text-neutral-alpha-650 transition-colors duration-100 group-hover:text-neutral-alpha-800" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white">
          Log out
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import * as Icons from '../globals/Icons/SidebarIcons/user'

export default {
  name: 'UserPopover',
  components: {
    ...Icons
  },
  props: {
    referralLink: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isPrivateMode: true
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('MiscModule', ['getIsPrivateMode'])
  },
  methods: {
    ...mapMutations('MiscModule', ['SET_IS_PRIVATE_MODE']),
    handleCopyReferralLink () {
      this.$showAlert({
        message: 'Link copied to clipboard',
        type: 'success'
      })
    },
    togglePrivateMode () {
      const newPrivateModeVal = !this.getIsPrivateMode
      this.SET_IS_PRIVATE_MODE(newPrivateModeVal)
      localStorage.setItem('privateMode', String(newPrivateModeVal))
    }
  }
}
</script>

<style scoped>
.user-popover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), rgba(16, 17, 26, 0.76);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}
</style>