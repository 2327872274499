<template>
  <BaseModal @close="handleClose" cardWidth="400px" noPadding noToolbar noExit>
    <template #default>
      <!-- Modal Content -->
      <div class="relative w-full flex flex-col gap-5 p-5 border-b border-border-normal">
        <!-- Leave Icon -->
        <div class="flex items-center justify-center w-9 h-9 p-2 rounded-md border border-secondary-red-100">
          <LeaveIcon class="text-secondary-red-100" />
        </div>
        <!-- Modal Body Text -->
        <div class="flex flex-col gap-1">
          <BaseText type="label" size="md" class="text-text-loud">
            Leave Team?
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-loud mt-3">
            Are you sure you want to leave this team? Once you leave, you'll lose access to team shared content.
          </BaseText>
          <BaseText type="label" size="sm" class="text-secondary-green-300 mt-1">
            You will keep the ads you've saved, as well as your created boards, folders, briefs, comments, and tags.
          </BaseText>
          <BaseText type="label" size="sm" class="text-secondary-red-300 mt-1">
            You will not retain access to manually uploaded content or spyders, as these are owned by the team.
          </BaseText>
        </div>
        <!-- Confirmation Checkbox -->
        <div class="flex items-center gap-2">
          <input type="checkbox" v-model="userConfirmed" class="confirm-checkbox w-4 h-4 focus:ring-transparent cursor-pointer" />
          <BaseText type="body" size="sm" class="text-text-muted">
            I understand the above information.
          </BaseText>
        </div>
        <!-- Close button -->
        <button class="absolute top-3 right-3 p-1 transition-opacity" @click="handleClose">
          <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
        </button>
      </div>
      <!-- Modal Actions Footer -->
      <div class="w-full flex justify-between p-5">
        <button class="px-2 py-1.5 rounded-md transition duration-100 hover:bg-neutral-25"
        @click="handleClose">
          <BaseText type="label" size="sm" class="text-text-muted">
            Cancel
          </BaseText>
        </button>
        <button class="submit-btn py-1.5 px-3 rounded-md" style="width: 64px;"
        :disabled="!isFormValid || loadingSubmit" :class="isFormValid ? 'enabled' : 'disabled cursor-default'"
        @click="handleLeaveTeam">
          <BaseLoadingSpinner v-if="loadingSubmit" small />
          <BaseText v-else type="label" size="sm">
            Leave
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from '../../../api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import CloudFunctionsAPI from '@/api'

import LeaveIcon from '../../globals/Icons/LeaveIcon.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'LeaveTeamModal',
  components: {
    LeaveIcon,
    TagRemoveIcon
  },
  data () {
    return {
      userConfirmed: false,
      loadingSubmit: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam']),
    isFormValid () {
      return this.userConfirmed
    }
  },
  methods: {
    async handleLeaveTeam () {
      this.loadingSubmit = true
      try {
        const user = firebase.auth().currentUser
        const teamId = this.getTeam.id
        await FirebaseAPI.Teams.removeMember(user.uid, teamId)
        await CloudFunctionsAPI.Teams.migrateUserFromTeam(user.uid, teamId)

        this.$showAlert({
          type: 'success',
          message: 'You have successfully left the team'
        })

        this.$emit('leftTeam', teamId)
        this.$emit('close')
      } catch (error) {
        console.error(error)
        const errorMessage = error?.response?.data?.error?.message 
          || 'Error occurred while leaving team. See console for details.'
        this.$showAlert({
          type: 'error',
          message: errorMessage
        })
      } finally {
        this.loadingSubmit = false
      }
    },
    handleClose () {
      if (this.loadingSubmit) return
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.confirm-checkbox {
  border-radius: 4px;
}
.confirm-checkbox:checked {
  background-color: #303546;
  color: #303546;
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --del-submit-color {
  syntax: '<color>';
  initial-value: #F4003A;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--del-submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #C80031;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out, 
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out, 
    --submit-btn-opacity-2 100ms ease-in-out,
    --del-submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --del-submit-color: #F6F8FA;
}

.status-enter-anim {
  animation: statusEnter 200ms ease-in-out forwards;
}
@keyframes statusEnter {
  0% { height: 0px; opacity: 0; }
  50% { height: 20px; opacity: 0; }
  100% { height: 20px; opacity: 1; }
}
</style>