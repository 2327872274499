<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.86623 11.9883L12.4375 16.75H17.6875L11.7938 8.89172L16.6981 3.25H14.7106L10.8732 7.66434L7.5625 3.25H2.3125L7.94565 10.7609L2.73936 16.75H4.72688L8.86623 11.9883ZM13.1875 15.25L5.3125 4.75H6.8125L14.6875 15.25H13.1875Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'MonoTwitterIcon',
  props: {
    width: {
      type: Number,
      default: () => 20
    },
    height: {
      type: Number,
      default: () => 20
    }
  }
}
</script>
  <style scoped lang="css"></style>
