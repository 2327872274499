<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M8.00016 8.66683C8.36836 8.66683 8.66683 8.36836 8.66683 8.00016C8.66683 7.63196 8.36836 7.3335 8.00016 7.3335C7.63196 7.3335 7.3335 7.63196 7.3335 8.00016C7.3335 8.36836 7.63196 8.66683 8.00016 8.66683Z" 
    stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8366 8.66683C13.2048 8.66683 13.5033 8.36836 13.5033 8.00016C13.5033 7.63196 13.2048 7.3335 12.8366 7.3335C12.4684 7.3335 12.1699 7.63196 12.1699 8.00016C12.1699 8.36836 12.4684 8.66683 12.8366 8.66683Z" 
    stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.16667 8.66683C3.53485 8.66683 3.83333 8.36836 3.83333 8.00016C3.83333 7.63196 3.53485 7.3335 3.16667 7.3335C2.79848 7.3335 2.5 7.63196 2.5 8.00016C2.5 8.36836 2.79848 8.66683 3.16667 8.66683Z" 
    stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'MoreIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 16
    }
  }
}
</script>

<style scoped></style>