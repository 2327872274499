<template>
  <BaseModal @close="$emit('close')" cardWidth="520px" noPadding noToolbar noExit>
    <template #default>
      <!-- Modal Header -->
      <div class="relative w-full flex flex-col gap-1 p-5 border-b border-border-normal">
        <BaseText type="label" size="md" class="text-text-muted">
          Create Team
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Once set, this can't be changed. If you need any assistance or have questions, please don't hesitate to contact support.
        </BaseText>
        <button class="absolute top-3 right-3 p-1" @click="$emit('close')">
          <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
        </button>
      </div>
      <!-- Modal Content -->
      <div class="w-full flex flex-col gap-5 p-5 border-b border-border-normal">
        <!-- Team Name Input -->
        <div class="w-full flex flex-col gap-1">
          <BaseText type="label" size="sm" class="text-text-muted">
            Team Name
          </BaseText>
          <input v-model="teamName" class="form-input w-full px-2 py-1.5 rounded-md" placeholder="Creative Team" />
        </div>
        <!-- Logo Upload -->
        <div class="w-full flex flex-col gap-4">
          <div class="w-full flex flex-col gap-1">
            <BaseText type="label" size="sm" class="text-text-muted">
              Logo (optional)
            </BaseText>
            <BaseText type="body" size="sm" class="text-text-normal">
              We support PNGs and JPEGs.
            </BaseText>
          </div>
          <div class="w-full flex items-center gap-4">
            <!-- Preview Image -->
            <img v-if="avatarPreview" :src="avatarPreview" alt="Preview" class="w-10 h-10 rounded-lg flex-shrink-0">
            <div v-else class="w-10 h-10 rounded-lg bg-neutral-50 flex-shrink-0" />
            <!-- Upload Button -->
            <button class="standard-btn flex items-center gap-1.5 rounded-md pl-1.5 py-1.5 pr-2.5"
            @click="handleFileInput">
              <input ref="fileInput" class="hidden" type="file" @change="handleFileUpload">
              <UploadIcon class="text-icon-normal" />
              <BaseText type="label" size="sm" class="text-text-muted">
                Upload
              </BaseText>
            </button>
          </div>
          <div class="w-full flex items-center gap-2">
            <InfoResponseIcon :width="20" :height="20" class="text-icon-normal" />
            <BaseText type="body" size="sm" class="text-text-muted">
              Choose a square image with a background for best appearance.
            </BaseText>
          </div>
        </div>
      </div>
      <!-- Modal Actions Footer -->
      <div class="w-full flex justify-between p-5">
        <button class="px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
        @click="$emit('close')">
          <BaseText type="label" size="sm" class="text-text-muted">
            Cancel
          </BaseText>
        </button>
        <button class="submit-btn py-1.5 rounded-md" style="width: 130px;"
        :disabled="!isFormValid || loadingSubmit" :class="isFormValid ? 'enabled' : 'disabled cursor-default'"
        @click="createTeam">
          <BaseLoadingSpinner v-if="loadingSubmit" small />
          <div v-else class="relative right-0.5 w-full flex items-center justify-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 6.25V10M10 10V13.75M10 10H6.25M10 10H13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <BaseText type="label" size="sm">
              Create Team
            </BaseText>
          </div>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import CloudFunctionsAPI from '@/api'
import { nanoid } from 'nanoid'
import { mapActions, mapGetters } from 'vuex'

import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import UploadIcon from '../../globals/Icons/UploadIcon.vue'
import InfoResponseIcon from '../../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'

export default {
  name: 'CreateTeamModal',
  components: {
    TagRemoveIcon,
    UploadIcon,
    InfoResponseIcon
  },
  data () {
    return {
      teamName: '',
      avatarUpload: null,
      avatarPreview: '',
      loadingSubmit: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam']),
    isFormValid () {
      return this.teamName.length > 0
    }
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserTeam']),
    async createTeam () {
      if (!this.teamName.length) return // Should not be possible
      this.loadingSubmit = true

      let avatarUploadFailed = false
      try {
        // Upload the avatar image if provided
        let avatarUrl = null
        if (this.avatarPreview) {
          try {
            const folderName = nanoid()
            const fileName = nanoid()
            const filePath = `${folderName}/${fileName}`
            const storageRef = firebase.app().storage('gs://foreplay-team-avatars').ref(filePath)
            
            await storageRef.put(this.avatarUpload)
            const fileLocation = `https://storage.googleapis.com/foreplay-team-avatars/${filePath}`
            avatarUrl = fileLocation
          } catch (error) {
            console.error(error)
            avatarUploadFailed = true
            throw error
          }
        }

        // Create the team
        await FirebaseAPI.Teams.create(this.teamName, avatarUrl)

        // Set the team in global state
        await this.fetchUserTeam()

        // Migrate user's assets to their team
        CloudFunctionsAPI.Teams.migrateUserToTeam(this.getTeam.id)

        this.$emit('createdTeam')

        this.loadingSubmit = false
        this.$showAlert({
          type: 'success',
          message: 'Team created successfully'
        })
        this.$showAlert({
          type: 'info',
          message: 'Migrating data into your new team...',
          timeout: 5000
        })
        this.$emit('close')
      } catch (error) {
        console.error(error)
        if (avatarUploadFailed) {
          this.$showAlert({
            type: 'error',
            message: "Couldn't create team. Avatar upload failed. See console for details."
          })
        } else {
          this.$showAlert({
            type: 'error',
            message: "Couldn't create team. See console for details."
          })
        }
      } finally {
        this.loadingSubmit = false
      }
    },
    handleFileInput () {
      this.$refs.fileInput.click()
    },
    handleFileUpload (event) {
      const mediaFile = event.target.files[0]
      if (!mediaFile) return
      this.avatarUpload = mediaFile
      const fileType = mediaFile.type
      if (!(fileType === 'image/png' || fileType === 'image/jpeg')) {
        this.$showAlert({
          message: 'Only JPEG and PNG accepted',
          type: 'error'
        })
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.avatarPreview = e.target.result
      }
      reader.readAsDataURL(mediaFile)
    },
  }
}
</script>

<style scoped>
.standard-btn {
  background: linear-gradient(180deg, rgba(223, 225, 231, 0.08) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out;
}
.standard-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input.invalid {
  box-shadow: none;
  border-color: #FF002F;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-color {
  syntax: '<color>';
  initial-value: #0063F4;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out, 
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out, 
    --submit-btn-opacity-2 100ms ease-in-out,
    --submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --submit-color: #F6F8FA;
}
</style>
