<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.99993 8L11.7499 9M9.99993 8L8.24993 9M9.99993 8L10 3.99969M11.7499 9V11M11.7499 9L14.8481 7.00031M11.7499 11L9.99993 12M11.7499 11L14.8481 13.0003M9.99993 12L8.24993 11M9.99993 12L10 15.9997M8.24993 11V9M8.24993 11L4.74927 13.0003M8.24993 9L4.75024 7.00031" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M8.87524 4.01585C9.5714 3.61393 10.4291 3.61393 11.1252 4.01585L14.6204 6.0338C15.3166 6.43572 15.7454 7.17851 15.7454 7.98236V12.0183C15.7454 12.8221 15.3166 13.5649 14.6204 13.9668L11.1252 15.9848C10.4291 16.3867 9.5714 16.3867 8.87524 15.9848L5.38005 13.9668C4.6839 13.5649 4.25505 12.8221 4.25505 12.0183V7.98236C4.25505 7.17851 4.6839 6.43572 5.38005 6.0338L8.87524 4.01585Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'LensIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
