<template>
  <div
    v-on-clickaway="() => showResults = false"
    class="relative"
  >
    <input
      :value="location"
      type="text"
      class="form-input fade-in min-w-0 w-full px-2 py-1.5"
      :disabled="isLoading"
      @input="handleInput"
      @click="openResults"
    >
    <span
      v-if="loading"
      class="absolute right-2 top-1/2 transform -translate-y-1/2"
    >
      <BaseLoadingSpinnerCircle
        class="text-text-disabled"
      />
    </span>
    <Transition name="fade">
      <div
        v-if="location.length > 0 && searchResults.length > 0 && showResults"
        class="absolute top-8 left-0 bg-white rounded-md w-full autocomplete-dropdown"
      >
        <button
          v-for="(result) in searchResults"
          :key="result.place_id"
          class="p-1 truncate w-full text-left hover:bg-background-normal"
          @click="onPlaceSelect(result)"
        >
          {{ result.description }}
        </button>
      </div>
    </Transition>
  </div>
</template>

<script>
import _ from 'lodash'
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  name: 'Autocomplete',
  mixins: [clickaway],
  props: {
    isLoading: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      searchResults: [],
      location: '',

      service: null,
      detailsService: null,

      loading: false,

      showResults: false
    }
  },
  watch: {
    location (newValue) {
      if (!newValue) {
        this.searchResults = []
      }
    }
  },
  mounted () {
    this.MapsInit()
    this.location = this.value
    this.debouncedGetPlacePredictions = _.debounce(() => {
      this.service.getPlacePredictions({
        input: this.location,
        fields: ['adr_address', 'formatted_address', 'address_components'],
        types: ['address']
      }, this.displaySuggestions)
    }, 300)
  },
  methods: {
    openResults () {
      if (this.isLoading) return
      this.showResults = true
    },
    MapsInit () {
      this.service = new window.google.maps.places.AutocompleteService()
      this.detailsService = new window.google.maps.places.PlacesService(
        document.createElement('div')
      )
    },
    handleInput (event) {
      this.$emit('updateText', event.target.value)
      this.loading = true
      this.location = event.target.value
      if (!event.target.value || event.target.value.length <= 2) {
        this.searchResults = []
        this.loading = false
        return
      }
      this.debouncedGetPlacePredictions()
      this.loading = false
    },
    displaySuggestions (predictions, status) {
      this.loading = false
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions
    },
    onPlaceSelect (result) {
      if (!result || !result.place_id) return

      this.detailsService.getDetails(
        {
          placeId: result.place_id,
          fields: ['address_components', 'formatted_address']
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const addressComponents = place.address_components
            const placeDetails = {
              city: this.getAddressComponent(addressComponents, 'locality').long_name,
              state: this.getAddressComponent(
                addressComponents,
                'administrative_area_level_1'
              ).long_name,
              country: this.getAddressComponent(addressComponents, 'country').short_name,
              postalCode: this.getAddressComponent(
                addressComponents,
                'postal_code'
              ).long_name,
              street: result.description
            }
            this.$emit('addressSelect', placeDetails, result)
            this.location = result.description
            this.showResults = false
          }
        }
      )
    },
    getAddressComponent (components, type) {
      const component = components.find((c) => c.types.includes(type))
      return component ?? {}
    }
  }
}
</script>

<style scoped>
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  height: 32px
}

.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}

.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}

.form-input:disabled {
  background-color: #FAFAFA;
}

.form-input:hover::placeholder {
  color: #303546;
}

.form-input:focus::placeholder {
  color: #C1C7D0;
}

.autocomplete-dropdown{
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);

}
.fade-enter-active,
.fade-leave-active {
  transition: all 150ms;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
