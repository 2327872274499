<template>
  <div class="w-full p-5">
    <div class="flex items-center">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="flex items-center"
      >
        <div class="flex items-center gap-2">
          <div
            :class="{
              'bg-white': true,
              'border': true,
              'border-text-muted': index === currentStep,
              'border-text-disabled': index !== currentStep,
              'text-text-muted': index === currentStep,
              'text-text-disabled': index !== currentStep,
            }"
            class="w-6 h-6 rounded-full flex items-center justify-center text-base font-medium font-inter leading-6 shrink-0"
          >
            {{ index + 1 }}
          </div>

          <!-- NATHAN TRANSITION PLEASE, PLEASE!!!! "... --- ..." -->
          <div class="overflow-hidden">
            <BaseText
              v-if="index === currentStep"
              type="label"
              size="sm"
              class="text-text-muted whitespace-nowrap transition-all duration-300"
              :style="{
                maxWidth: index === currentStep ? '200px' : '0',
                opacity: index === currentStep ? 1 : 0,
                marginLeft: index === currentStep ? '0.5rem' : '0'
              }"
            >
              {{ step }}
            </BaseText>
          </div>
        </div>

        <div
          v-if="index < steps.length - 1"
          class="h-px mx-4 flex-shrink border-border-normal-50"
          :style="{
            background: 'var(--border-normal-50, #EAEFF3)',
            minWidth: '16px'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from './BaseText.vue'

export default {
  name: 'BaseProgressStepBar',
  components: {
    BaseText
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    currentStep: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style scoped>
.fade-move {
  transition: all 0.3s;
}
</style>
