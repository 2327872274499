<template>
  <div class="relative">
    <transition>
      <svg v-if="isActive" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none" key="active">
        <path d="M4.68062 14.9259H14.2431C14.8587 14.9259 15.4029 14.5258 15.5865 13.9382L16.7516 10.2098C16.8932 9.75666 16.5547 9.29623 16.0799 9.29623H15.6297M4.68062 14.9259C5.12407 14.9259 5.51607 14.6377 5.64834 14.2144L6.87664 10.2838C7.06025 9.69628 7.6044 9.29623 8.21998 9.29623H15.6297M4.68062 14.9259C4.12067 14.9259 3.66675 14.4719 3.66675 13.912V5.77771C3.66675 5.00042 4.29687 4.3703 5.07416 4.3703H7.83945C8.31002 4.3703 8.74946 4.60548 9.01046 4.99702L9.79115 6.16805C9.92169 6.36382 10.1414 6.48141 10.3767 6.48141H14.2223C14.9996 6.48141 15.6297 7.11153 15.6297 7.88882V9.29623" 
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none" key="inactive">
        <path d="M4 6.00002V13.3334C4 14.0698 4.59695 14.6667 5.33333 14.6667H14.6667C15.4031 14.6667 16 14.0698 16 13.3334V8.00002C16 7.26364 15.4031 6.66669 14.6667 6.66669H10.3568C10.1339 6.66669 9.92573 6.55529 9.80207 6.36982L9.06247 5.26042C8.8152 4.88949 8.39889 4.66669 7.95309 4.66669H5.33333C4.59695 4.66669 4 5.26364 4 6.00002Z" 
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FolderIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped>
.v-enter-active {
  transition: opacity 100ms ease-in-out;
}
.v-leave-active {
  transition: opacity 100ms ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>