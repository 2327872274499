<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 5.87483L5.07539 4.78047C4.13862 4.57229 3.25 5.28512 3.25 6.24475V13.6715C3.25 14.3746 3.7383 14.9833 4.42461 15.1358L10 16.3748M10 5.87483L14.9246 4.78047C15.8614 4.57229 16.75 5.28512 16.75 6.24475V13.6715C16.75 14.3746 16.2617 14.9833 15.5754 15.1358L10 16.3748M10 5.87483V16.3748" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'KnowledgeBaseUserIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>