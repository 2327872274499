<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M6 13.25L3.97812 10.015C3.76998 9.68198 4.00941 9.25 4.40212 9.25H15.691C16.0627 9.25 16.3044 9.64116 16.1382 9.97361L15 12.25" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 15.75V15.75C5.48572 16.9386 7.57319 17.0183 9.14522 15.9464L9.37651 15.7887C10.9621 14.7076 13.0452 14.6968 14.642 15.7613L16.5 17" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 8.875L10.2158 4.29493C10.4965 3.89514 11.125 4.09376 11.125 4.58225V6.4375V8.875" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <circle v-if="hasNotifications" cx="16.5" cy="3.5" r="2.5" fill="#FFBE4C"/>
  </svg>
</template>

<script>
export default {
  name: 'ShipIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    hasNotifications: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
