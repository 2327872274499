<template>
  <BaseModal @close="handleClose" cardWidth="400px" noPadding noToolbar noExit>
    <template #default>
      <!-- Modal Content -->
      <div class="relative w-full flex flex-col px-5 pt-5 pb-2.5 border-b border-border-normal">
        <!-- Delete Icon -->
        <div class="p-2 rounded-md bg-secondary-red-100 w-min">
          <DeleteIcon class="text-white" />
        </div>
        <!-- Modal Body Text -->
        <div class="flex flex-col gap-1 mt-5">
          <BaseText type="label" size="md" class="text-text-loud">
            Delete Team?
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-loud mt-1">
            Are you sure you want to delete this team? You will lose access to data contributed by other users. This action is permanent and cannot be undone.
            <br><br>
            To confirm, please type the word <span class="font-medium">"DELETE"</span> below.
          </BaseText>
        </div>
        <!-- Delete Confirmation Input -->
        <input v-model="deleteConfirmation" class="form-input w-full px-2 py-1.5 mt-5 mb-2.5 rounded-md" :disabled="isActionsDisabled"
        @keyup.enter="handleDeleteTeam" />
        <!-- Migration Status Text -->
        <div v-if="migratingData" class="flex items-center justify-center gap-2 overflow-hidden status-enter-anim" style="height: 20px">
          <BaseLoadingSpinnerCircle class="text-text-subdued" :duration="1" />
          <BaseText type="body" size="sm" class="text-text-muted text-center">
            Migrating user data. Please wait...
          </BaseText>
        </div>
        <!-- Close Button -->
        <button class="absolute top-3 right-3 p-1 transition-opacity" 
        :class="isActionsDisabled ? 'opacity-50 cursor-default' : 'opacity-100'" 
        @click="handleClose" :disabled="isActionsDisabled">
          <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
        </button>
      </div>
      <!-- Modal Actions Footer -->
      <div class="w-full flex justify-between p-5">
        <button class="px-2 py-1.5 rounded-md transition duration-100 hover:bg-neutral-25"
        :class="isActionsDisabled ? 'opacity-50 cursor-default' : 'opacity-100'"
        :disabled="isActionsDisabled" @click="handleClose">
          <BaseText type="label" size="sm" class="text-text-muted">
            Cancel
          </BaseText>
        </button>
        <button class="submit-btn py-1.5 px-3 rounded-md" style="width: 68px;"
        :disabled="!isFormValid || isActionsDisabled" 
        :class="isFormValid && !migratingData ? 'enabled' : 'disabled cursor-default'"
        @click="handleDeleteTeam">
          <BaseLoadingSpinner v-if="loadingSubmit" small />
          <BaseText v-else type="label" size="sm">
            Delete
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import CloudFunctionsAPI from '@/api'

import DeleteIcon from '../../globals/Icons/DeleteIcon.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'DeleteTeamModal',
  components: {
    DeleteIcon,
    TagRemoveIcon
  },
  data () {
    return {
      deleteConfirmation: '',
      loadingSubmit: false,
      migratingData: false,
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam']),
    isFormValid () {
      return this.deleteConfirmation === 'DELETE'
    },
    isActionsDisabled () {
      return this.loadingSubmit || this.migratingData
    }
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserTeam']),
    async handleDeleteTeam () {
      if (!this.isFormValid) return

      // Create the delete team job
      this.loadingSubmit = true
      try {
        await CloudFunctionsAPI.Teams.deleteTeam(this.getTeam.id)
      } catch (error) {
        console.error(error)
        const errorMessage = error?.response?.data?.error?.message 
          || 'Error occurred while deleting team. See console for details.'
        this.$showAlert({
          type: 'error',
          message: errorMessage
        })
        return
      } finally {
        this.loadingSubmit = false
      }

      // Poll the delete team job
      this.migratingData = true
      try {
        let snapshot = await FirebaseAPI.Teams.getDeleteTeamJobSnapshot(this.getTeam.id)
        while (!snapshot.empty && !snapshot?.docs[0]?.data()?.retriesMaxed) {
          await new Promise(resolve => setTimeout(resolve, 1500))
          snapshot = await FirebaseAPI.Teams.getDeleteTeamJobSnapshot(this.getTeam.id)
        }

        if (snapshot?.docs[0]?.data()?.retriesMaxed) {
          throw new Error('Migration failed after multiple retries.')
        }

        this.$showAlert({
          type: 'success',
          message: 'Team deleted successfully.'
        })
        this.fetchUserTeam()
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Error occurred while migrating data. Please contact support.'
        })
        return
      } finally {
        this.migratingData = false
      }
    },
    handleClose () {
      if (this.isActionsDisabled) return
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out, opacity 75ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input:disabled {
  opacity: 0.5;
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --del-submit-color {
  syntax: '<color>';
  initial-value: #F4003A;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--del-submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #C80031;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out, 
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out, 
    --submit-btn-opacity-2 100ms ease-in-out,
    --del-submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --del-submit-color: #F6F8FA;
}

.status-enter-anim {
  animation: statusEnter 200ms ease-in-out forwards;
}
@keyframes statusEnter {
  0% { height: 0px; opacity: 0; }
  50% { height: 20px; opacity: 0; }
  100% { height: 20px; opacity: 1; }
}
</style>
