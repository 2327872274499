<template>
  <div>
    <ninja-keys
      ref="ninjaKeys"
      class="ninja-keys"
      :placeholder="placeholder"
      :hidebreadcrumbs="true"
      @selected="selected"
      @change="change"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import 'ninja-keys'

export default {
  name: 'SearchModal',
  data () {
    return {
      placeholder: 'Search for a Board or Brand',
      timer: null,
      hotkeys: [
        {
          id: 'Library',
          title: 'View Library',
          hotkey: 'cmd+l,ctrl+l',
          handler: () => {
            this.$router.push({ name: 'LibraryView' }).catch(() => {})

            document.querySelector('ninja-keys').close()
          }
        },
        {
          id: 'Briefs',
          title: 'View Briefs',
          hotkey: 'cmd+b,ctrl+b',
          handler: () => {
            this.$router.push({ name: 'BriefsView' }).catch(() => {})

            document.querySelector('ninja-keys').close()
          }
        },
        {
          id: 'Dashboard',
          title: 'View Dashboard',
          hotkey: 'cmd+h,ctrl+h',
          handler: () => {
            this.$router.push({ name: 'DashboardView' }).catch(() => {})

            document.querySelector('ninja-keys').close()
          }
        },
        {
          id: 'Discovery',
          title: 'View Discovery',
          hotkey: 'cmd+d,ctrl+d',
          handler: () => {
            this.$router.push({ name: 'DiscoveryView' }).catch(() => {})

            document.querySelector('ninja-keys').close()
          }
        },
        {
          id: 'Spyder',
          title: 'View Spyder',
          hotkey: 'cmd+i,ctrl+i',
          handler: () => {
            this.$router.push({ name: 'SpyderBrandsView' }).catch(() => {})

            document.querySelector('ninja-keys').close()
          }
        },
        {
          hotkey: 'cmd+k,ctrl+k',
          title: 'Focus Search',
          handler: () => {
            this.$emit('focusSearch')

            document.querySelector('ninja-keys').close()
          }
        },
        {
          hotkey: 'cmd+x,ctrl+x',
          title: 'View Lens',
          handler: () => {
            this.$router.push({ name: 'LensView' }).catch(() => {})

            document.querySelector('ninja-keys').close()
          }
        }
        // REMOVED: Routes to a view that is now obsolete
        // {
        //   id: 'Boards',
        //   title: 'View Boards',
        //   handler: () => {
        //     this.$router.push({ name: 'BoardsView' }).catch(() => {})

        //     document.querySelector('ninja-keys').close()
        //   }
        // }
      ],
      openHotkey: 'cmd+j,ctrl+j',
      searchClient: null,
      searchIndex: null,
      searchData: null,
      queryId: null,
      defaultResults: []
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser'])
  },
  mounted () {
    this.$refs.ninjaKeys.data = [...this.hotkeys]
  },
  methods: {
    selected (event) {
      // Checkout the action and do the following action
    },
    formatSearchResults (hit, index) {
      let section = 'Boards'

      if (hit.type === 'brand') {
        section = 'My Brands'
      } else if (hit.type === 'unique-brand') {
        section = 'Discovery Brands'
      }

      const payload = {
        id: hit.id,
        title: hit.name,
        section,
        handler: async (event) => {
          if (event.section === 'My Brands') {
            this.$router
              .push({ name: 'BrandView', params: { id: event.id } })
              .catch(() => {}) // incase the user is already there
          } else if (event.section === 'Discovery Brands') {
            this.$router
              .push({ name: 'DiscoveryBrandView', params: { id: event.id } })
              .catch(() => {}) // incase the user is already there
          } else {
            this.$router
              .push({ name: 'BoardsDetailsView', params: { id: event.id } })
              .catch(() => {}) // incase the user is already there
          }

          this.$refs.ninjaKeys.data = [...this.hotkeys, ...this.defaultResults]
        }
      }

      if (section === 'Discovery Brands' || section === 'My Brands') {
        payload.icon = `<img style="border-radius: 100%; height: 30px; margin-right: 10px" src="${hit.avatar}" />`
      }

      return payload
    },
    async change (event) {
      // make an Angolia search here

      // Wait for typing to stop before searching
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(async () => {
        // Empty string will actually search for an empty string
        const searchText =
          event.detail.search === '' ? undefined : event.detail.search

        const formattedData = []
        // Track event if user searched
        window.analytics.track('User Searched', {
          searchQuery: searchText
        })

        this.$refs.ninjaKeys.data = [...this.hotkeys, ...formattedData]
      }, 500)
    }
  }
}
</script>

<style></style>
