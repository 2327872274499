// Import
import firebase from '../config/FirebaseConfig'
import store from '../../store'

// Setup
const db = firebase.firestore()

const getById = async (userId) => {
  let uid = userId; let dbCollection = 'auth-users'
  if (userId.split('-')[0] === 'legacy') { // In case the id references a legacy team member
    uid = userId.split('-')[1]
    dbCollection = 'legacy-team-members'
  }

  const user = await db.collection(dbCollection).doc(uid).get()

  return {
    id: userId,
    ...user.data()
  }
}

const getByEmail = async (email) => {
  const snapshot = await db.collection('auth-users').where('email', '==', email).get()
  if (!snapshot.docs.length) return undefined
  return {
    ...snapshot.docs[0].data(),
    id: snapshot.docs[0].id
  }
}

const checkUserSubscription = async (uid) => {
  if (uid.split('-')[0] === 'legacy') return false // legacy team members don't have subscriptions

  const db = firebase.firestore()

  const user = await db.collection('auth-users').doc(uid).get()
  const trialStartDate = user.data().trialStarted

  const docs = await db
    .collection('customers')
    .doc(uid)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .get()

  const userHasSubscription =
    !!docs.docs.length || Date.now() < trialStartDate + 6.048e8

  // check if the user is a part of a team
  if (!userHasSubscription) {
    const snapshot = await db
      .collection('teams')
      .where('members', 'array-contains', uid)
      .get()

    if (snapshot.docs?.length !== 0) {
      return true
    }
  }

  return userHasSubscription
}

const checkTeamSubscription = async (teamId) => {
  const team = await db.collection('teams').doc(teamId).get()
  const user = await db
    .collection('auth-users')
    .doc(team.data().created_by)
    .get()
  const trialStartDate = user.data().trialStarted

  const docs = await db
    .collection('customers')
    .doc(user.id)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .get()

  return !!docs.docs.length || Date.now() < trialStartDate + 6.048e8
}

// Check if user exists and create if not (for new users)
const checkUserExists = async (user) => {
  const userFound = await db.collection('auth-users').doc(user.uid).get()

  if (userFound.data() && !userFound.data().trialStarted) {
    await db.collection('auth-users').doc(user.uid).update({
      user_id: user.uid,
      email: user.email,
      name: user.displayName,
      avatar: user.photoURL,
      trialStarted: Date.now()
    })
  }

  if (!userFound.data()) {
    console.log('User not found, create a new user!')

    await db.collection('auth-users').doc(user.uid).set({
      user_id: user.uid,
      email: user.email,
      name: user.displayName,
      avatar: user.photoURL,
      trialStarted: Date.now()
    })
  } else {
    store.commit('AuthModule/SET_USER', {
      ...userFound.data(),
      email: user.email,
      user_id: user.uid,
      token: user.uid
    })
  }
}

// Update User
const update = async (userId, payload) => {
  let uid = userId; let dbCollection = 'auth-users'
  if (userId.split('-')[0] === 'legacy') { // In case the id references a legacy team member
    uid = userId.split('-')[1]
    dbCollection = 'legacy-team-members'
  }
  return await db.collection(dbCollection).doc(uid).update(payload)
}

// Set last seen
const updateLastSeen = async () => {
  const user = firebase.auth().currentUser

  return await db
    .collection('auth-users')
    .doc(user.uid)
    .update({ lastSeen: +new Date() })
}

const updateExpert = async (user, updatePayload) => {
  if (!user) return
  const expertToUpdate = updatePayload.expert
  const avatarToUpdate = updatePayload.avatar

  const updateData = {
    expertProfile: expertToUpdate
  }
  if (avatarToUpdate) {
    updateData.avatar = avatarToUpdate
  }

  await db.collection('auth-users').doc(user?.user_id).update(updateData)
}
export default {
  checkUserExists,
  checkUserSubscription,
  getById,
  getByEmail,
  update,
  updateLastSeen,
  checkTeamSubscription,

  // Expert operations
  updateExpert
}
