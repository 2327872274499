<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M8.5 8.5L5.3125 5.3125M10 4.75V3.25C13.728 3.25 16.75 6.27208 16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 9.21107 3.38535 8.45376 3.63409 7.75M11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96448 11.875 8.125 11.0355 8.125 10C8.125 8.96448 8.96448 8.125 10 8.125C11.0355 8.125 11.875 8.96448 11.875 10Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'AnonBenchmarksIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
