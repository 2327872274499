<template>
  <div>
    <div class="w-full h-full rounded-t-3xl px-32 py-12">
      <div>
        <div class="flex justify-between items-end">
          <!-- Header -->
          <div class="flex flex-col gap-1">
            <BaseText
              type="label"
              class="text-neutral-600"
            >
              Referral
            </BaseText>
            <BaseText
              size="sm"
              class="max-w-xs text-neutral-500"
            >
              Earn 20% commissions on the first 12
              months for every
              customer you refer
              - it's that simple!
            </BaseText>
          </div>
          <BaseButton
            outlined
            @click="redirectTo('https://www.foreplay.co/affiliates')"
          >
            Learn More
          </BaseButton>
        </div>
        <!-- Affiliate Links -->
        <div class="flex items-center gap-5 py-5 border-t border-b mt-5 border-neutral-50">
          <div class="flex gap-3 items-center">
            <div
              v-if="loadingStats"
              class="skeleton h-8 w-64 rounded-md px-2 py-1.5"
            />
            <div
              v-else
              class="rounded-md border px-2 py-1.5 truncate relative h-8 w-64 transition-all fade-in"
            >
              {{ referralLink }}
            </div>
            <BaseButton
              v-clipboard:copy="referralLink"
              v-clipboard:success="() => { handleSuccess('Referral link copied') }"
              primary
              padding-x="1.5"
              forcedTheme="blue"
            >
              <div class="flex gap-1.5">
                <LinkRoundedIcon />
                <div>Copy Link</div>
              </div>
            </BaseButton>
          </div>
          <!-- Social redirects -->
          <div class="grid grid-cols-12">
            <BaseButton
              v-for="social in socialRedirects"
              :key="social.name"
              class="col-span-6 xl:col-span-3 px-1.5 hover:bg-neutral-25"
              @click="redirectTo(social.url)"
            >
              <div class="flex gap-1.5">
                <component :is="social.icon" />
                <BaseText
                  type="label"
                  size="sm"
                >
                  {{ social.name }}
                </BaseText>
              </div>
            </BaseButton>
          </div>
        </div>
        <!-- No paypal linked -->
        <div
          v-if="!userPaypal"
          class="flex justify-between my-7"
        >
          <div>
            <div class="w-full h-full flex flex-col gap-1">
              <BaseText
                class="text-neutral-600"
                type="label"
                size="sm"
              >
                Link your PayPal account to
                receive payouts.
              </BaseText>
              <BaseText
                class="text-neutral-500"
                type="body"
                size="sm"
              >
                You don't currently have a
                linked PayPal account.
              </BaseText>
            </div>
          </div>
          <div class="flex flex-col gap-3 w-full max-w-md">
            <div>
              <!--  -->
              <div class="flex flex-col gap-1 w-full">
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  Email
                </BaseText>

                <input
                  v-model="paypalEmail"
                  :class="{'invalid': submitAttempted && !isEmailValid}"
                  class="form-input min-w-0 w-full px-2 py-1.5"
                  placeholder="Enter PayPal Email"
                >

                <!-- invalid indicator -->
                <div
                  v-if="submitAttempted && !isEmailValid"
                  class="flex items-center gap-1"
                >
                  <AltAlertIcon class="text-secondary-red-100" />
                  <BaseText
                    type="body"
                    size="xs"
                    class="text-secondary-red-100"
                  >
                    Email is invalid
                  </BaseText>
                </div>
              </div>
              <!--  -->
            </div>
            <BaseButton
              primary
              :disabled="loadingPaypal"
              :loading="loadingPaypal"
              @click="updateUserPaypal(paypalEmail)"
              forcedTheme="blue"
            >
              Connect
              PayPal
            </BaseButton>
          </div>
        </div>
        <!-- Linked paypal information -->
        <div
          v-else
          class="my-7"
        >
          <div class="flex justify-between w-full bg-neutral-25 rounded-lg p-2.5">
            <div class="flex gap-2 items-center">
              <PayPalIcon class="mr-2" />
              <BaseText
                class="text-neutral-600"
                type="label"
                size="sm"
              >
                Connected PayPal account:
              </BaseText>
              <!-- TODO @SAm update state -->
              <BaseText
                class="text-neutral-600"
                size="sm"
              >
                {{ userPaypal }}
              </BaseText>
            </div>
            <BaseButton
              class="hover:bg-neutral-50"
              :loading="loadingPaypal"
              :disabled="loadingPaypal"
              @click="updateUserPaypal('')"
            >
              Remove
            </BaseButton>
          </div>
        </div>
        <!-- Affiliate statistics -->
        <div class="grid grid-cols-12 gap-3 items-center border-t border-b border-neutral-50 py-7">
          <div
            v-for="statType in Object.keys(affiliateStats)"
            :key="statType"
            class="col-span-6 xl:col-span-3 border border-neutral-50 rounded-xl p-4 flex flex-col gap-2 w-full"
            style="min-width: 200px; min-height: 96px"
          >
            <BaseText
              class="text-neutral-500"
              type="label"
              size="sm"
            >
              {{ affiliateStats[statType].name }}
            </BaseText>
            <BaseText
              v-if="!loadingStats && affiliateStats[statType].value"
              class="text-neutral-600 fade-in"
              type="display"
              size="2xs"
            >
              {{ affiliateStats[statType].value }}
            </BaseText>
            <BaseLoadingSpinnerCircle
              v-else
              class="text-icon-normal w-8 h-8"
            />
          </div>
        </div>
        <!-- Footer -->
        <div class="mt-5 flex items-start justify-between">
          <img
            class="h-5 w-auto"
            src="../../assets/images/rewardful-logo.svg"
          >
          <!-- <BaseButton
            outlined
            @click="redirectTo('https://foreplay.getrewardful.com/login')"
          >
            Affiliate Dashboard Login
          </BaseButton> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from '@/api/config/FirebaseConfig'
// import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'
import { mapGetters, mapMutations } from 'vuex'
// Icons
import LinkRoundedIcon from '../globals/Icons/LinkRoundedIcon.vue'
import FacebookPlatformIcon from '../globals/Icons/FilterIcons/Platforms/FacebookPlatformIcon.vue'
import LinkedinPlatformIcon from '../globals/Icons/FilterIcons/Platforms/LinkedInPlatformIcon.vue'
import InstagramPlatformIcon from '../globals/Icons/FilterIcons/Platforms/InstagramPlatformIcon.vue'
import XPlatformIcon from '../globals/Icons/FilterIcons/Platforms/XPlatformIcon.vue'
import PayPalIcon from '../globals/Icons/PayPalIcon.vue'
import AltAlertIcon from '../globals/Icons/AltAlertIcon.vue'

export default {
  name: 'AffiliateReferralSettings',
  components: {
    LinkRoundedIcon,
    FacebookPlatformIcon,
    LinkedinPlatformIcon,
    InstagramPlatformIcon,
    XPlatformIcon,
    PayPalIcon,
    AltAlertIcon
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    isEmailValid () {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(this.paypalEmail)
    }
  },
  props: {

  },
  data () {
    return {
      loadingPaypal: false,
      submitAttempted: false,

      // Requested on mount
      loadingStats: true,

      paypalEmail: '',
      userPaypal: '',

      referralLink: '',
      socialRedirects: [
        {
          name: 'Facebook',
          icon: 'FacebookPlatformIcon',
          url: 'https://www.facebook.com'
        },
        {
          name: 'Linkedin',
          icon: 'LinkedinPlatformIcon',
          url: 'https://www.linkedin.com'
        },
        {
          name: 'Instagram',
          icon: 'InstagramPlatformIcon',
          url: 'https://www.instagram.com'
        },
        {
          name: 'X/Twitter',
          icon: 'XPlatformIcon',
          url: 'https://www.x.com'
        }
      ],
      affiliateStats: {
        visitors: {
          name: 'Visitors',
          value: ''
        },
        leads: {
          name: 'Trial Signups',
          value: ''
        },
        conversions: {
          name: 'Converted Referrals',
          value: ''
        },
        earnings: {
          name: 'Total Earnings',
          value: ''
        }
      }

    }
  },
  async mounted () {
    this.userPaypal = this.getUser.paypal_email
    this.loadingStats = true
    const referralData = await ForeplayAPI.Campaigns.getUserReferral()
    this.referralLink = referralData.url

    // Format & assign statistics
    this.updateAffiliateStats(referralData)
    this.loadingStats = false
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    updateAffiliateStats (data) {
      const { leads, conversions, earnings, visitors } = data
      // Earnings are in cents, convert to dollars
      const formattedEarnings = earnings ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(parseInt(earnings) / 100) : '$0.00'

      this.affiliateStats.visitors.value = visitors || '0'
      this.affiliateStats.leads.value = leads || '0'
      this.affiliateStats.conversions.value = conversions || '0'
      this.affiliateStats.earnings.value = formattedEarnings
    },
    async updateUserPaypal (email) {
      this.submitAttempted = true
      if (!this.userPaypal && !this.isEmailValid) return

      this.loadingPaypal = true
      try {
        const res = await ForeplayAPI.Campaigns.setUserPaypal(email ?? null)
        this.userPaypal = res.paypal_email
        const isUpdate = !!email
        // Reset input
        if (!isUpdate) {
          this.paypalEmail = ''
        }
        this.SET_USER({
          ...this.getUser,
          paypal_email: this.paypalEmail
        })
        this.submitAttempted = false
        this.$showAlert({
          message: `Email successfully ${isUpdate ? 'updated' : 'removed'}`,
          type: 'success'
        })
      } catch (e) {
        this.$showAlert({
          message: 'Error updating paypal email',
          type: 'error'
        })
      }
      this.loadingPaypal = false
    },
    redirectTo (url) {
      window.open(url, '_blank')
    },
    handleSuccess (msg) {
      this.$showAlert({
        message: msg,
        type: 'success'
      })
    }

  }

}
</script>

<style scoped>
/* Forms */
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input.invalid {
  box-shadow: none;
  border-color: #FF002F;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
