<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7.91642 10.6253L9.16643 11.8753L12.0831 8.95866M7.35099 4.21704L5.63225 3.94439C5.08921 3.85825 4.59555 4.27248 4.58608 4.82223L4.55613 6.5622C4.55076 6.87423 4.38493 7.16145 4.11739 7.32212L2.62551 8.21804C2.15414 8.50111 2.04224 9.13575 2.38836 9.56297L3.48385 10.9151C3.6803 11.1576 3.73789 11.4842 3.63622 11.7793L3.06926 13.4245C2.89013 13.9444 3.21235 14.5025 3.7521 14.6073L5.46044 14.9389C5.76679 14.9984 6.02085 15.2116 6.13262 15.5029L6.75587 17.1277C6.95279 17.6411 7.55836 17.8615 8.03918 17.5948L9.56102 16.7508C9.83393 16.5994 10.1656 16.5994 10.4385 16.7508L11.9603 17.5948C12.4412 17.8615 13.0467 17.6411 13.2436 17.1277L13.8669 15.5029C13.9787 15.2116 14.2327 14.9984 14.5391 14.9389L16.2474 14.6073C16.7872 14.5025 17.1094 13.9444 16.9303 13.4245L16.3633 11.7793C16.2616 11.4842 16.3192 11.1576 16.5157 10.9151L17.6112 9.56297C17.9573 9.13575 17.8454 8.50111 17.374 8.21804L15.8821 7.32212C15.6146 7.16145 15.4488 6.87423 15.4434 6.5622L15.4134 4.82223C15.404 4.27248 14.9103 3.85825 14.3673 3.94439L12.6485 4.21704C12.3403 4.26593 12.0287 4.1525 11.824 3.91692L10.6826 2.60328C10.322 2.18823 9.67754 2.18823 9.31692 2.60328L8.17555 3.91692C7.97086 4.1525 7.65921 4.26593 7.35099 4.21704Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExpertSettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
