<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.5 10C3.5 13.728 6.52208 16.75 10.25 16.75C13.978 16.75 17 13.728 17 10C17 6.27208 13.978 3.25 10.25 3.25C6.52208 3.25 3.5 6.27208 3.5 10Z" 
    stroke="currentColor" stroke-width="1.5"/>
    <path d="M12.125 7.75L7.625 10L12.125 12.25" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'OpLessThanIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
