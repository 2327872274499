<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 3.4751V16.5251H5.2751C4.28099 16.5251 3.4751 15.7192 3.4751 14.7251V5.2751C3.4751 4.28099 4.28099 3.4751 5.2751 3.4751H14.7251C15.7192 3.4751 16.5251 4.28099 16.5251 5.2751V7.8251C16.5251 8.15647 16.2565 8.4251 15.9251 8.4251H13.2626H10.0001V10.8251C10.0001 11.1565 9.73147 11.4251 9.4001 11.4251H6.7376H3.4751" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.25 11.5V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M17.5 13.75H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CreateBoardItemIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>