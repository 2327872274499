<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.9999 15.1333H4.86657C4.05655 15.1333 3.3999 14.4767 3.3999 13.6666V5.59997C3.3999 4.78995 4.05655 4.1333 4.86657 4.1333H7.7483C8.23869 4.1333 8.69662 4.37838 8.96862 4.78641L9.78218 6.00675C9.91821 6.21076 10.1472 6.3333 10.3924 6.3333H15.1332C15.9433 6.3333 16.5999 6.98995 16.5999 7.79997V8.7333" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.25 11.5V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M17.5 13.75H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CreateFolderItemIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
