<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M12.25 8.5L10.75 10L12.25 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.25 6.25C3.25 5.42157 3.92157 4.75 4.75 4.75H15.25C16.0784 4.75 16.75 5.42157 16.75 6.25V13.75C16.75 14.5784 16.0784 15.25 15.25 15.25H4.75C3.92157 15.25 3.25 14.5784 3.25 13.75V6.25Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 4.75V10V15.25" stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'CollapseIcon',
  props: {
    width: { 
      type: Number, 
      default: 20 
    },
    height: { 
      type: Number, 
      default: 20 
    }
  }
}
</script>

<style scoped></style>
