import AxiosConfig from '../config/AxiosConfig'
import firebase from '../config/FirebaseConfig'

const migrateUserToTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  await AxiosConfig.post('/migrateUserToTeam', {
    teamId,
    uid: user.uid
  })
}

const migrateUserFromTeam = async (userId, teamId) => {
  await AxiosConfig.post('/migrateUserFromTeam', {
    teamId,
    uid: userId,
  })
}

const addUserToTeam = async (teamId) => {
  const user = firebase.auth().currentUser
  await AxiosConfig.post('/addUserToTeam', {
    uid: user.uid,
    teamId
  })
}

const adminRemoveTeamMember = async (memberId) => {
  await AxiosConfig.post('/adminRemoveTeamMember', {
    memberId
  })
}

const deleteTeam = async (teamId) => {
  const user = firebase.auth().currentUser
  
  await AxiosConfig.post('/deleteTeam', {
    uid: user.uid,
    teamId
  })
}

export default {
  migrateUserToTeam,
  migrateUserFromTeam,
  addUserToTeam,
  adminRemoveTeamMember,
  deleteTeam
}