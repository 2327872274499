<template>
  <!-- Loading Skeleton -->
  <div v-if="!team" class="skeleton w-full rounded-xl" style="height: 45px;" />
  <!-- Team Invite Row -->
  <div v-else class="w-full flex items-center gap-3 p-1.5 rounded-xl border border-border-normal fade-in">
    <!-- Team Avatar -->
    <img v-if="team.avatar" :src="team.avatar" alt="Team Avatar" class="w-8 h-8 rounded-md flex-shrink-0">
    <div v-else class="w-8 h-8 rounded-md flex-shrink-0 bg-neutral-900 flex items-center justify-center">
      <BaseText type="label" size="sm" class="text-white">
        {{ team.teamName?.charAt(0)?.toUpperCase() || 'T'}}
      </BaseText>
    </div>
    <!-- Team Name -->
    <BaseText type="label" size="sm" class="flex-grow text-text-muted">
      {{ team.teamName }}
    </BaseText>
    <div class="flex items-center gap-0.5 pr-1">
      <!-- Decline Button -->
      <button class="flex justify-center px-2.5 py-1.5 rounded-md transition-colors duration-100" style="width: 70px;"
      :class="loadingDeclineInvitation ? 'bg-neutral-25 cursor-default' : 'hover:bg-neutral-25'"
      @click="rejectInvitation">
        <BaseLoadingSpinnerCircle v-if="loadingDeclineInvitation" class="text-text-disabled" />
        <BaseText v-else type="label" size="sm" class="text-text-muted">
          Decline
        </BaseText>
      </button>
      <!-- Join Button -->
      <button class="flex justify-center px-2.5 py-1.5 rounded-md transition-colors duration-100" style="width: 48px;"
      :class="loadingJoinTeam ? 'bg-neutral-25 cursor-default' : 'hover:bg-neutral-25'"
      @click="acceptInvitation">
        <BaseLoadingSpinnerCircle v-if="loadingJoinTeam" class="text-text-disabled" />
        <BaseText v-else type="label" size="sm" class="text-text-muted">
          Join
        </BaseText>
      </button>
    </div>
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import CloudFunctionsAPI from '@/api'

export default {
  name: 'TeamSettingsInvitationRow',
  props: {
    invitation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      team: null,
      loadingJoinTeam: false,
      loadingDeclineInvitation: false
    }
  },
  async created () {
    this.team = await FirebaseAPI.Teams.getByID(this.invitation.teamId)
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserTeam','fetchUserInvitations']),
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapActions('SpyderModule', ['fetchSpyderSubscriptions']),
    ...mapActions('BriefsModule', ['fetchBriefs']),
    async acceptInvitation () {
      this.loadingJoinTeam = true
      try {
        await FirebaseAPI.Invitations.accept(this.invitation.id)
        await FirebaseAPI.Teams.join(this.invitation.teamId)

        // Update the team admin's stripe and migrate the user data into the team
        await CloudFunctionsAPI.Teams.addUserToTeam(this.invitation.teamId)
        await CloudFunctionsAPI.Teams.migrateUserToTeam(this.invitation.teamId)

        this.$showAlert({
          type: 'success',
          message: 'Team joined successfully.'
        })
        this.$emit('teamJoined', this.invitation.teamId)

      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error.message,
          type: 'error'
        })
      }
    },
    async rejectInvitation () {
      this.loadingDeclineInvitation = true
      try {
        await FirebaseAPI.Invitations.reject(this.invitation.id)
        await this.fetchUserInvitations()
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loadingDeclineInvitation = false
      }
    }
  }
}
</script>

<style scoped>
.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
