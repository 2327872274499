var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full p-5"},[_c('div',{staticClass:"flex items-center"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('div',{staticClass:"w-6 h-6 rounded-full flex items-center justify-center text-base font-medium font-inter leading-6 shrink-0",class:{
            'bg-white': true,
            'border': true,
            'border-text-muted': index === _vm.currentStep,
            'border-text-disabled': index !== _vm.currentStep,
            'text-text-muted': index === _vm.currentStep,
            'text-text-disabled': index !== _vm.currentStep,
          }},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"overflow-hidden"},[(index === _vm.currentStep)?_c('BaseText',{staticClass:"text-text-muted whitespace-nowrap transition-all duration-300",style:({
              maxWidth: index === _vm.currentStep ? '200px' : '0',
              opacity: index === _vm.currentStep ? 1 : 0,
              marginLeft: index === _vm.currentStep ? '0.5rem' : '0'
            }),attrs:{"type":"label","size":"sm"}},[_vm._v(" "+_vm._s(step)+" ")]):_vm._e()],1)]),(index < _vm.steps.length - 1)?_c('div',{staticClass:"h-px mx-4 flex-shrink border-border-normal-50",style:({
          background: 'var(--border-normal-50, #EAEFF3)',
          minWidth: '16px'
        })}):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }