import { render, staticRenderFns } from "./MonoTiktokIcon.vue?vue&type=template&id=22f58319&scoped=true"
import script from "./MonoTiktokIcon.vue?vue&type=script&lang=js"
export * from "./MonoTiktokIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f58319",
  null
  
)

export default component.exports