<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7524 14.7543H12.7533V11.6216C12.7533 10.8746 12.7381 9.91337 11.7115 9.91337C10.6692 9.91337 10.51 10.7262 10.51 11.5665V14.7543H8.51089V8.3125H10.4313V9.19053H10.4571C10.7255 8.68428 11.378 8.14994 12.3528 8.14994C14.3784 8.14994 14.753 9.4831 14.753 11.2184L14.7524 14.7543ZM6.25304 7.43106C5.60954 7.43106 5.0926 6.91019 5.0926 6.2695C5.0926 5.62938 5.6101 5.10906 6.25304 5.10906C6.89429 5.10906 7.41404 5.62938 7.41404 6.2695C7.41404 6.91019 6.89373 7.43106 6.25304 7.43106ZM7.25541 14.7543H5.25066V8.3125H7.25541V14.7543ZM15.7525 3.25H4.24716C3.69648 3.25 3.25098 3.68538 3.25098 4.22256V15.7775C3.25098 16.3152 3.69648 16.75 4.24716 16.75H15.7509C16.301 16.75 16.751 16.3152 16.751 15.7775V4.22256C16.751 3.68538 16.301 3.25 15.7509 3.25H15.7525Z"
      fill="currentColor"
    />

  </svg>
</template>
<script>
export default {
  name: 'MonoLinkedinIcon',
  props: {
    width: {
      type: Number,
      default: () => 20
    },
    height: {
      type: Number,
      default: () => 20
    }
  }
}
</script>
  <style scoped lang="css"></style>
