import AxiosConfig from './LensAxiosConfig'
import firebase from '../config/FirebaseConfig'

const create = async (reportPayload, useDefault) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = new URLSearchParams()
  if (useDefault) params.append('use_default', useDefault)

  const { data } = await AxiosConfig.post(
    `/reports?${params.toString()}`,
    reportPayload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const update = async (reportId, toUpdate) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.patch(
    `/reports/${reportId}`,
    toUpdate,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const remove = async (reportId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.delete(
     `/reports/${reportId}`,
     {
       headers: {
         Authorization: `Bearer ${token}`
       }
     }
  )

  return data
}

const getByID = async (reportId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/reports/${reportId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

async function getAll (query) {
  const { Aa: token } = firebase.auth().currentUser

  const params = new URLSearchParams()

  if (query.name) params.append('name', query.name)
  if (query.lens_id) params.append('lens_id', query.lens_id)
  if (query.page) params.append('page', query.page)
  if (query.limit) params.append('limit', query.limit)

  const { data } = await AxiosConfig.get(`/reports/?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}

// -------------- Shared Reports  -------------- \\
async function createShare (sharePayload) {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser
  const { data } = await AxiosConfig.post(
    '/share/reports',
    sharePayload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

async function updateShare (shareReportId, sharePayload) {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.patch(
    `/share/reports/${shareReportId}`,
    sharePayload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

async function getShareById (shareReportId) {
  // Pull the report jwt if exists
  const jwtToken = sessionStorage.getItem(`access_token_${shareReportId}`)

  const { data } = await AxiosConfig.get(
    `/share/reports/${shareReportId}`,
    {
      headers: {
        'x-access-token': jwtToken
      }
    }
  )

  return data
}

async function getAllShares (reportId) {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/reports/${reportId}/shares`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  console.log('ALL SHARES', data)
  return data
}

async function deleteShare (shareReportId) {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.delete(
      `/share/reports/${shareReportId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
  )

  return data
}

// ----- Start of public routes ------- \\
async function getPublicShareById (shareReportId) {
  const { data } = await AxiosConfig.get(
    `/share/reports/view/${shareReportId}`
  )

  return data
}

async function validateSharePassword (shareReportId, plainTextPassword) {
  const { data } = await AxiosConfig.post(
     `/share/reports/unlock/${shareReportId}`,
     { plain_text_password: plainTextPassword }
  )

  if (data.token) {
    sessionStorage.setItem(`access_token_${shareReportId}`, data.token)
  }
  return data
}
// ----- end of public routes ------- \\

export default {
  create,
  update,
  remove,
  getByID,
  getAll,
  // Shared reports
  createShare,
  updateShare,
  getAllShares,
  getShareById,
  deleteShare,
  getPublicShareById,
  validateSharePassword
}
