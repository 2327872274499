<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M13.5 11.5V4M13.5 11.5C14.7431 11.5 15.75 12.5069 15.75 13.75C15.75 14.9931 14.7431 16 13.5 16C12.2569 16 11.25 14.9931 11.25 13.75C11.25 12.5069 12.2569 11.5 13.5 11.5ZM6 16V10M6 10C4.75687 10 3.75 8.99313 3.75 7.75C3.75 6.50687 4.75687 5.5 6 5.5M6 10C7.24313 10 8.25 8.99313 8.25 7.75C8.25 6.50687 7.24313 5.5 6 5.5M6 5.5V4" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'SetupStatusIcon',
  props: {
    width: { 
      type: Number, 
      default: 20 
    },
    height: { 
      type: Number, 
      default: 20 
    }
  }
}
</script>

<style scoped></style>
