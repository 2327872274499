<template>
  <BaseModal @close="$emit('close')" cardWidth="520px" noPadding noToolbar noExit>
    <template #default>
      <!-- Modal Header -->
      <div class="relative w-full flex flex-col gap-1 p-5 border-b border-border-normal">
        <BaseText type="label" size="md" class="text-text-muted">
          Invite Member
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Invite members to collaborate and access shared features.
        </BaseText>
        <button class="absolute top-3 right-3 p-1" @click="$emit('close')">
          <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
        </button>
      </div>
      <!-- Modal Content -->
      <div class="w-full flex flex-col gap-1 p-5 border-b border-border-normal">
        <BaseText type="label" size="sm" class="text-text-muted">
          Email
        </BaseText>
        <input v-model="inviteEmail" class="form-input w-full px-2 py-1.5 rounded-md" placeholder="Enter email address"
        @keyup.enter="sendInvitation" />
      </div>
      <!-- Modal Actions Footer -->
      <div class="w-full flex justify-between p-5">
        <button class="px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
        @click="$emit('close')">
          <BaseText type="label" size="sm" class="text-text-muted">
            Cancel
          </BaseText>
        </button>
        <button class="submit-btn py-1.5 px-3 rounded-md" style="width: 60px;"
        :disabled="!isFormValid || loadingSubmit" :class="isFormValid ? 'enabled' : 'disabled cursor-default'"
        @click="sendInvitation">
          <BaseLoadingSpinner v-if="loadingSubmit" small />
          <BaseText v-else type="label" size="sm">
            Invite
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import FunctionsAPI from '@/api'
import validator from "email-validator";

import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'InviteTeamMemberModal',
  components: {
    TagRemoveIcon
  },
  data () {
    return {
      inviteEmail: '',
      loadingSubmit: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam']),
    isFormValid () {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.inviteEmail);
    }
  },
  methods: {
    async sendInvitation () {
      if (!this.isFormValid) return
      this.loadingSubmit = true
      const toEmail = this.inviteEmail

      try {
        if (!validator.validate(toEmail)) {
          throw new Error('Invalid email address')
        }

        
        // Check if the user is already a member of the team
        const user = await FirebaseAPI.Users.getByEmail(toEmail)
        if (user && this.getTeam.members.includes(user.id)) {
          throw new Error('User is already a member of the team')
        }
        // Check if this team has already sent an invitation to this user
        const invitations = await FirebaseAPI.Invitations.getAllCreatedByUser(this.getTeam.id)
        const outgoingInvitation = invitations.find(invitation => invitation.toEmail === toEmail)
        if (outgoingInvitation && outgoingInvitation.status === 'pending') {
          throw new Error('Invitation already sent to this user')
        }

        await FunctionsAPI.Invitations.createInvitation(toEmail, this.getTeam.id)
        this.$emit('invitationSent')
        this.$showAlert({
          type: 'success',
          message: "Invitation sent successfully",
        })
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: "Something went wrong sending your invitation.",
        })
      } finally {
        this.loadingSubmit = false
      }
    }
  }
}
</script>

<style scoped>
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input.invalid {
  box-shadow: none;
  border-color: #FF002F;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-color {
  syntax: '<color>';
  initial-value: #0063F4;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out, 
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out, 
    --submit-btn-opacity-2 100ms ease-in-out,
    --submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --submit-color: #F6F8FA;
}
</style>
