import { render, staticRenderFns } from "./BaseAdvertisementVideoPlayer.vue?vue&type=template&id=619548b3&scoped=true"
import script from "./BaseAdvertisementVideoPlayer.vue?vue&type=script&lang=js"
export * from "./BaseAdvertisementVideoPlayer.vue?vue&type=script&lang=js"
import style0 from "./BaseAdvertisementVideoPlayer.vue?vue&type=style&index=0&id=619548b3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619548b3",
  null
  
)

export default component.exports