<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M15.25 9.7993V14.5C15.25 15.3284 14.5784 16 13.75 16H6.25C5.42157 16 4.75 15.3284 4.75 14.5V9.7993M15.25 9.7993V9.625M15.25 9.7993C15.6984 9.53995 16 9.05522 16 8.5C16 7.67157 15.3284 7 14.5 7H5.5C4.67157 7 4 7.67157 4 8.5C4 9.05522 4.30165 9.53995 4.75 9.7993M15.25 9.7993C15.0293 9.92695 14.7732 10 14.5 10H5.5C5.22678 10 4.97063 9.92695 4.75 9.7993M4.75 9.7993V9.625M10 7V5.75M10 7H8.74997C7.36928 7 6.25 5.88071 6.25 4.5C6.25 3.80964 6.80964 3.25 7.5 3.25C8.8807 3.25 10 4.36929 10 5.75M10 7H11.25C12.6307 7 13.75 5.88071 13.75 4.5C13.75 3.80964 13.1903 3.25 12.5 3.25C11.1193 3.25 10 4.36929 10 5.75M10 7V16" 
    stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'PerksUserIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>