import AxiosConfig from './LensAxiosConfig'

const getLensMetrics = async (status = 1) => {
  const url = `/metrics?status=${status}`
  const { data } = await AxiosConfig.get(
    url
  )
  return data
}

export default {
  getLensMetrics
}
