<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.68595 3.53014C9.81202 3.2677 10.1881 3.2677 10.3142 3.53014L11.9639 6.9643C12.0147 7.06992 12.1157 7.14287 12.2325 7.15816L16.0305 7.65544C16.3207 7.69344 16.4369 8.04872 16.2246 8.24892L13.4463 10.8686C13.3609 10.9492 13.3222 11.0672 13.3437 11.1823L14.0411 14.9238C14.0945 15.2097 13.7902 15.4293 13.5329 15.2906L10.1661 13.4755C10.0625 13.4197 9.93762 13.4197 9.83408 13.4755L6.46724 15.2906C6.20995 15.4293 5.90569 15.2097 5.959 14.9238L6.65648 11.1823C6.67793 11.0672 6.63932 10.9492 6.55387 10.8686L3.77558 8.24892C3.56326 8.04872 3.67948 7.69344 3.96971 7.65544L7.7676 7.15816C7.8844 7.14287 7.98549 7.06992 8.03623 6.9643L9.68595 3.53014Z" 
    fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'UnstarItemIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>