<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M11.5008 10.9374C11.0304 10.8151 10.5288 10.75 10.0008 10.75C7.45747 10.75 5.52783 12.2607 4.7377 14.3972C4.4311 15.2261 5.13958 16 6.02347 16H9.25082M14.5008 12.25V14.5M14.5008 14.5V16.75M14.5008 14.5H12.2508M14.5008 14.5H16.7508M12.6258 5.875C12.6258 7.32475 11.4506 8.5 10.0008 8.5C8.55107 8.5 7.37584 7.32475 7.37584 5.875C7.37584 4.42525 8.55107 3.25 10.0008 3.25C11.4506 3.25 12.6258 4.42525 12.6258 5.875Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'AddUserIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
