import io from 'socket.io-client'
import statsSetup from './stats'
import { sendEvent } from './events'
import liveUpdateAdsSetup from './liveAppUpdates'
import authSocketSetup from './auth'

// const socket = io('http://localhost:5001')
const socket = io('https://notifications.foreplay.co')

statsSetup(socket)
liveUpdateAdsSetup(socket)

export const authSocket = () => authSocketSetup(socket)
export const sendEventSocket = (event, payload) => sendEvent(socket, event, payload)
