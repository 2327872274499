<template>
  <div class="w-full team-members-grid pl-3 pr-1.5 py-1.5 fade-in">
    <!-- User Avatar -->
    <img v-if="user?.avatar" :src="user.avatar" alt="User Avatar" class="w-5 h-5 rounded-full flex-shrink-0"
    referrerpolicy="no-referrer">
    <!-- Placeholder User Avatar -->
    <div v-else-if="user?.name || user?.first_name || user?.last_name" 
    class="w-5 h-5 rounded-full flex items-center justify-center bg-neutral-25">
      <BaseText type="label" size="xs" class="text-text-normal">
        {{ user.name?.charAt(0) || user.first_name?.charAt(0) || user.last_name?.charAt(0) || '' }}
      </BaseText>
    </div>
    <!-- Placeholder Non-user Avatar -->
    <DefaultAvatarIcon v-else class="w-5 h-5 flex-shrink-0" />
    <!-- User Name -->
    <div class="w-full flex items-center gap-2 min-w-0">
      <BaseText v-if="memberName" type="label" size="sm" class="shrink text-text-muted py-1.5 min-w-0 truncate">
        {{ memberName }}
      </BaseText>
      <!-- Pending invitation status label -->
      <div v-if="invitation" class="flex items-center gap-0.5 pl-0.5 py-0.5 pr-2 rounded-md bg-secondary-yellow-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1.5">
          <circle cx="3" cy="3" r="3" fill="#FFBE4C" />
        </svg>
        <BaseText type="label" size="xs" class="text-secondary-yellow-300">
          Pending
        </BaseText>
      </div>
    </div>
    <!-- User Email -->
    <BaseText type="body" size="sm" class="text-text-normal py-1.5 min-w-0 truncate">
      {{ user?.email || invitation.toEmail }}
    </BaseText>
    <!-- User Role / Remove Button -->
    <div class="w-full flex items-center justify-between">
      <BaseText type="body" size="sm" class="text-text-normal">
        {{ getTeam.created_by === user?.id ? 'Team Admin' : 'Team Member' }}
      </BaseText>
      <BaseLoadingSpinnerCircle v-if="loadingCancelInvitation" class="text-text-subdued mr-3" />
      <button v-else-if="getMemberAction" 
      class="px-2.5 py-1.5 rounded-md text-text-muted transition-colors duration-100 
      hover:bg-neutral-25 hover:text-text-loud" @click="handleMemberAction">
        <BaseText type="label" size="sm" class="capitalize">
          {{ getMemberActionText }}
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import firebase from '../../../api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'

import DefaultAvatarIcon from '../../globals/Icons/DefaultAvatarIcon.vue'

export default {
  name: 'TeamSettingsUserRow',
  components: {
    DefaultAvatarIcon
  },
  props: {
    user: {
      required: true
    },
    viewAsTeamAdmin: {
      type: Boolean,
      required: true
    },
    invitation: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loadingCancelInvitation: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getTeam']),
    getMemberAction () {
      if (this.invitation && (this.viewAsTeamAdmin || this.invitation.createdBy === firebase.auth().currentUser.uid))
        return 'cancel'
      else if (this.viewAsTeamAdmin && this.user && this.user.id !== this.getTeam.created_by)
        return 'remove'
      else if (!this.viewAsTeamAdmin && this.user && firebase.auth().currentUser.uid === this.user.id)
        return 'leave'
      return null
    },
    getMemberActionText () {
      if (this.getMemberAction === 'cancel')
        return 'Cancel Invite'
      else
        return this.getMemberAction
    },
    memberName () {
      if (!this.user?.name && !this.user?.first_name && !this.user?.last_name) return null
      let name = null
      if (this.user?.first_name) {
        name = this.user.first_name
        if (this.user?.last_name) {
          name += ` ${this.user.last_name}`
        }
      } else if (this.user?.name) {
        name = this.user.name
      }
      return name
    }
  },
  methods: {
    handleMemberAction () {
      if (this.getMemberAction === 'cancel')
        this.cancelInvitation()
      else if (this.getMemberAction === 'remove')
        this.$emit('removeMember')
      else if (this.getMemberAction === 'leave')
        this.$emit('leaveTeam')
    },
    async cancelInvitation () {
      this.loadingCancelInvitation = true
      try {
        await FirebaseAPI.Invitations.remove(this.invitation.id)
        this.$emit('invitationCancelled')
      } catch (error) {
        this.$showAlert({
          type: 'error',
          message: 'Failed to cancel invitation'
        })
      } finally {
        this.loadingCancelInvitation = false
      }
    }
  }
}
</script>

<style scoped>
.team-members-grid {
  display: grid;
  grid-template-columns: 20px 200px 240px 1fr;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
