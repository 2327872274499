<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7.74989 5.12489L9.46952 3.40522C9.76247 3.11232 10.2373 3.11232 10.5302 3.40522L12.2498 5.12489M5.12489 7.74989L3.40522 9.46952C3.11232 9.76247 3.11232 10.2373 3.40522 10.5302L5.12489 12.2498M14.8748 7.74989L16.5945 9.46952C16.8875 9.76247 16.8875 10.2373 16.5945 10.5302L14.8748 12.2498M12.2498 14.8748L10.5302 16.5945C10.2373 16.8875 9.76247 16.8875 9.46952 16.5945L7.74989 14.8748M9.99985 3.99989V9.99985M9.99985 9.99985V15.9998M9.99985 9.99985H3.99989M9.99985 9.99985H15.9998" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'MoveItemIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>