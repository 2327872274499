<template>
  <div class="w-full flex flex-col p-6">
    <!-- NO TEAM STATE -->
    <div v-if="!getTeam" class="w-full mt-7 mx-auto flex flex-col gap-5" style="max-width: 864px">
      <!-- Header -->
      <div class="flex flex-col gap-1">
        <BaseText type="label" size="md" class="text-text-muted">
          Team Settings
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Let's get started by creating a team.
        </BaseText>
      </div>
      <!-- Create Team Button -->
      <button class="create-invite-btn w-full p-6 flex flex-col gap-2 items-center"
      @click="showCreateTeamModal = true">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10 6.25V10M10 10V13.75M10 10H6.25M10 10H13.75" stroke="#5E6678" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        <BaseText type="label" size="sm" class="text-text-muted">
          Create a Team
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Start building together now.
        </BaseText>
      </button>
      <!-- Team Invitations -->
      <div v-if="getPendingInvitations.length" class="w-full flex flex-col gap-5">
        <!-- divider -->
        <div class="w-full h-px bg-border-normal" />
        <!-- Header -->
        <div class="flex flex-col gap-1">
          <BaseText type="label" size="md" class="text-text-muted">
            Invitations
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal">
            You have been invited to join the following teams.
          </BaseText>
        </div>
        <!-- Incoming Team Invitations List -->
        <div class="w-full flex flex-col gap-2">
          <TeamSettingsInvitationRow v-for="invitation in getPendingInvitations" :key="invitation.id"
            :invitation="invitation"
            @teamJoined="handleTeamJoined"
          />
        </div>
      </div>
    </div>
    <!-- EXISTING TEAM STATE -->
    <div v-else class="w-full mt-7 mx-auto flex flex-col" style="max-width: 864px">
      <!-- Header -->
      <div class="flex flex-col gap-1">
        <BaseText type="label" size="md" class="text-text-muted">
          Team Settings
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          {{ 
            teamHasMembers || getTeam?.outgoingInvitations
              ? "Manage users who have access to your team."
              : "Let's get started by adding members to your team."
          }}
        </BaseText>
      </div>
      <!-- Team Name & Member Count -->
      <div class="w-full flex items-center gap-3 mt-5">
        <img v-if="getTeam.avatar" :src="getTeam.avatar" alt="Team Avatar" class="w-8 h-8 rounded-md flex-shrink-0">
        <div v-else class="w-8 h-8 rounded-md flex-shrink-0 bg-neutral-900 flex items-center justify-center">
          <BaseText type="label" size="sm" class="text-white">
            {{ getTeam.teamName?.charAt(0)?.toUpperCase() || 'T'}}
          </BaseText>
        </div>
        <div class="flex items-center gap-2 py-1.5">
          <BaseText type="label" size="sm" class="text-text-muted">
            {{ getTeam.teamName || 'My Team' }}
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-muted">
            {{ getTeam.members.length }} Member{{ getTeam.members.length > 1 ? 's' : '' }}
          </BaseText>
        </div>
        <div class="relative ml-auto" @mouseenter="showCannotDeleteTeamTooltip = true" @mouseleave="showCannotDeleteTeamTooltip = false">
          <!-- Delete Team Button -->
          <button v-if="userIsTeamAdmin" class="ml-auto delete-team-btn px-2.5 py-1.5 rounded-md" 
          :class="{'cursor-default': !canDeleteTeam}" :disabled="!canDeleteTeam"
          @click="showDeleteTeamModal = true">
            <BaseText type="label" size="sm">
              Delete Team
            </BaseText>
          </button>
          <!-- Cannot delete team tooltip -->
          <transition>
            <div v-if="!canDeleteTeam && showCannotDeleteTeamTooltip" class="info-tooltip p-2 rounded-lg"
            @mouseenter="showCannotDeleteTeamTooltip = false">
              <BaseText type="body" size="xs" class="text-white text-center">
                Your team cannot be deleted while members are still assigned. Please remove all members to proceed with deletion.
              </BaseText>
            </div>
          </transition>
        </div>
      </div>
      <!-- Whitelabel Ad -->
      <div class="w-full flex items-center gap-2 p-3 mt-5 bg-neutral-25" style="border-radius: 10px">
        <WhiteLabelSettingsIcon class="text-icon-normal" />
        <BaseText type="label" size="sm" class="text-text-muted" style="width: 200px;">
          Whitelabel Share is here!
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Customize your share page now.
        </BaseText>
      </div>
      <!-- INVITE TEAM MEMBER BUTTON -->
      <button class="create-invite-btn w-full flex items-center gap-2 mt-5" :class="useSmallInviteButton ? 'p-3' : 'flex-col p-6'"
      @click="showInviteTeamMemberModal = true">
        <AddUserIcon class="text-icon-normal" />
        <BaseText type="label" size="sm" class="text-text-muted" :class="{'text-left': useSmallInviteButton}"
        :style="{width: useSmallInviteButton ? '200px' : 'auto'}">
          {{ useSmallInviteButton ? 'Invite new team member' : 'Invite your first team member' }}
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Additional team members cost $20/mo.
        </BaseText>
      </button>
      <!-- members loading skeletons -->
      <div v-if="loadingData" class="w-full flex flex-col gap-5 mt-4 px-2">
        <div v-for="n in (getTeam.members.length + getTeam.outgoingInvitations)" 
        :key="`skeleton-${n}`" class="skeleton w-full h-8 rounded-lg" />
      </div>
      <!-- TEAM MEMBERS GRID -->
      <div v-else class="w-full flex flex-col gap-2 mt-3">
        <!-- Members -->
        <TeamSettingsUserRow v-for="member in teamMembers" :key="member.id" 
          :user="member" 
          :viewAsTeamAdmin="userIsTeamAdmin"
          @removeMember="teamMemberToRemove = member"
          @leaveTeam="showLeaveTeamModal = true"
        />
        <!-- Pending Invitations -->
        <TeamSettingsUserRow v-for="invitation in outgoingInvitations" :key="invitation.id" 
          :user="invitation.user" 
          :viewAsTeamAdmin="userIsTeamAdmin"
          :invitation="invitation"
          @invitationCancelled="getOutgoingInvitations"
        />
      </div>
    </div>
    <!-- Migration Notification -->
    <div class="migration-notification flex items-center p-0.5 rounded-full"
    :class="showMigrationNotification ? 'migration-notif-enter-anim' : doAnimNotif ? 'migration-notif-leave-anim' : ''">
      <!-- Loading Spinner -->
      <div class="p-1.5 flex items-center justify-center">
        <BaseLoadingSpinnerCircle class="text-white" :duration="1.2"/>
      </div>
      <!-- Notification Text -->
      <BaseText type="body" size="sm" class="text-white">
        We're migrating user data. Unexpected behavior may occur.
      </BaseText>
      <!-- Dismiss Button -->
      <button class="mx-3 text-white hover:underline" @click="showMigrationNotification = false">
        <BaseText type="body" size="sm">
          Dismiss
        </BaseText>
      </button>
    </div>

    <!-- MODALS -->

    <CreateTeamModal v-if="showCreateTeamModal" @close="showCreateTeamModal = false" />
    <DeleteTeamModal v-if="showDeleteTeamModal" @close="showDeleteTeamModal = false" />
    <InviteTeamMemberModal 
      v-if="showInviteTeamMemberModal" 
      @close="showInviteTeamMemberModal = false"
      @invitationSent="getOutgoingInvitations"
    />
    <LeaveTeamModal 
      v-if="showLeaveTeamModal" 
      @close="showLeaveTeamModal = false"
      @leftTeam="handleTeamLeft"
    />
    <RemoveTeamMemberModal 
      v-if="teamMemberToRemove"
      :userToRemove="teamMemberToRemove"
      @close="teamMemberToRemove = null"
      @removedTeamMember="handleTeamMemberRemoved"
    />
  </div>
</template>

<script>
import firebase from '../../../api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import { mapGetters, mapMutations, mapActions } from 'vuex'

// COMPONENTS
import TeamSettingsUserRow from './TeamSettingsUserRow.vue'
import TeamSettingsInvitationRow from './TeamSettingsInvitationRow.vue'
import CreateTeamModal from './CreateTeamModal.vue'
import InviteTeamMemberModal from './InviteTeamMemberModal.vue'
import DeleteTeamModal from './DeleteTeamModal.vue'
import LeaveTeamModal from './LeaveTeamModal.vue'
import RemoveTeamMemberModal from './RemoveTeamMemberModal.vue'

// ICONS
import WhiteLabelSettingsIcon from '../../globals/Icons/SettingsIcons/WhitelabelSettingsIcon.vue'
import AddUserIcon from '../../globals/Icons/AddUserIcon.vue'
import DefaultAvatarIcon from '../../globals/Icons/DefaultAvatarIcon.vue'

export default {
  name: 'TeamSettings',
  components: {
    TeamSettingsUserRow,
    TeamSettingsInvitationRow,
    CreateTeamModal,
    InviteTeamMemberModal,
    DeleteTeamModal,
    LeaveTeamModal,
    RemoveTeamMemberModal,
    WhiteLabelSettingsIcon,
    AddUserIcon,
    DefaultAvatarIcon
  },
  data () {
    return {
      teamMembers: [],
      outgoingInvitations: [],
      teamMemberToRemove: null,

      // UI State
      loadingData: true,
      showMigrationNotification: false,
      doAnimNotif: false,
      showCreateTeamModal: false,
      showInviteTeamMemberModal: false,
      showDeleteTeamModal: false,
      showCannotDeleteTeamTooltip: false,
      showLeaveTeamModal: false,
    }
  },
  async mounted () {
    if (this.getTeam) {
      await this.getTeamMembers()
      await this.getOutgoingInvitations()
    }
    this.loadingData = false
  },
  watch: {
    'getTeam.members': {
      async handler () {
        await this.getTeamMembers()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser','getTeam', 'getPendingInvitations']),
    teamHasMembers () {
      // return true
      return this.getTeam?.members?.length > 1
    },
    userIsTeamAdmin () {
      return this.getTeam?.created_by === firebase.auth().currentUser.uid
    },
    canDeleteTeam () {
      return this.userIsTeamAdmin && !this.teamHasMembers
    },
    useSmallInviteButton () {
      return this.teamHasMembers || this.getTeam?.outgoingInvitations
    }
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER_TEAM']),
    ...mapActions('AuthModule', ['fetchUserTeam','fetchUserInvitations']),
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapActions('SpyderModule', ['fetchSpyderSubscriptions']),
    ...mapActions('BriefsModule', ['fetchBriefs']),
    async getTeamMembers () {
      let members = await FirebaseAPI.Teams.getTeamMembers()
      // Sort so the admin is first
      this.teamMembers = members?.sort((a, b) => a.id === this.getTeam.created_by ? -1 : 1) || []
    },
    async getOutgoingInvitations () {
      if (!this.getTeam) return
      const invitations = await FirebaseAPI.Invitations.getAllCreatedByUser(this.getTeam.id) || []

      // Fetch the user data for each invitation
      this.outgoingInvitations = await Promise.all(invitations.map(async invitation => {
        const user = await FirebaseAPI.Users.getByEmail(invitation.toEmail)
        return {
          ...invitation,
          user: user ?? null
        }
      }))
      
      this.SET_USER_TEAM({...this.getTeam, outgoingInvitations: this.outgoingInvitations.length})
    },
    handleTeamJoined (teamId) {
      this.handleMigrationNotification('team-migration-jobs', teamId)
      this.refetchUserData()
    },
    async handleTeamLeft (teamId) {
      await this.fetchUserTeam()
      await this.handleMigrationNotification('team-removal-jobs', teamId)
      this.refetchUserData(false)
    },
    handleTeamMemberRemoved (userId) {
      this.handleMigrationNotification('team-removal-jobs', this.getTeam.id, userId)
      this.refetchUserData()
    },
    async refetchUserData (refetchTeam = true) {
      if (refetchTeam) {
        await this.fetchUserTeam()
        await this.getTeamMembers()
      }
      this.fetchBoards()
      this.fetchFolders()
      this.fetchSpyderSubscriptions()
      this.fetchBriefs()
      this.fetchUserInvitations()
    },
    async handleMigrationNotification (collection, teamId, userId = null) {
      await new Promise(resolve => setTimeout(resolve, 4000))
      this.showMigrationNotification = true
      this.doAnimNotif = true

      // We will poll the migration job and close the notification when it's done (if the user hasn't closed it yet)
      const uid = userId ?? firebase.auth().currentUser.uid
      let snapshot = await FirebaseAPI.Teams.getMigrationJobSnapshot(collection, uid, teamId)
      while (this.showMigrationNotification && !snapshot.empty && !snapshot?.docs[0]?.data()?.retriesMaxed) {
        await new Promise(resolve => setTimeout(resolve, 1500))
        snapshot = await FirebaseAPI.Teams.getMigrationJobSnapshot(collection, uid, teamId)
      }

      if (this.showMigrationNotification) {
        this.showMigrationNotification = false
      }
    }
  }
}
</script>

<style scoped>
.create-invite-btn {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.create-invite-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.14), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.delete-team-btn {
  box-shadow: none;
  color: #303546; /* text-muted */
  background-color: transparent;
  transition: box-shadow 150ms ease-in-out, color 150ms ease-in-out, background-color 150ms ease-in-out;
}
.delete-team-btn:enabled:hover {
  box-shadow: 0px 0px 0px 1px #F4003A;
  color: #DF1C41; /* secondary-red-100 */
}
.delete-team-btn:disabled {
  background-color: #F6F8FA; /* neutral-25 */
  color: #A4ACB9; /* text-disabled */
}

.team-members-grid {
  display: grid;
  grid-template-columns: 20px 200px 240px 1fr;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
}

.info-tooltip {
  position: absolute;
  width: 264px;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(6, 7, 16, 0.92);
  backdrop-filter: blur(40px);
}

.migration-notification {
  position: fixed;
  bottom: 24px;
  left: calc(50% + 140px);
  transform: translate(-50%, 0%);
  background-color: rgba(6, 7, 16, 0.88);
  backdrop-filter: blur(40px);
  opacity: 0;
}
.migration-notif-enter-anim {
  animation: notificationEnter 150ms ease-in-out forwards;
}
.migration-notif-leave-anim {
  animation: notificationLeave 150ms ease-in forwards;
}
@keyframes notificationEnter {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes notificationLeave {
  from { transform: translate(-50%, 0); opacity: 1; }
  to { transform: translate(-50%, 200%); opacity: 1; }
}

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
