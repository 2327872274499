<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none"
  class="search-icon" :class="{'active': isActive, 'hovered': isHovered && !isActive, 'sidebar': sidebar}">
    <path d="M16.25 16.25L13.0659 13.0659M14.75 9C14.75 12.1756 12.1756 14.75 9 14.75C5.82436 14.75 3.25 12.1756 3.25 9C3.25 5.82436 5.82436 3.25 9 3.25C12.1756 3.25 14.75 5.82436 14.75 9Z" 
    stroke="#303546" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="9" cy="9" r="3" fill="#DFE1E7"/>
  </svg>
</template>

<script>
export default {
  name: 'DynamicSearchIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    isHovered: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    sidebar: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.search-icon path {
  stroke: #808899; /* icon-subdued */
  transition: stroke 100ms ease-in-out;
}
.search-icon.hovered path {
  stroke: #5E6678; /* neutral-500 */
}
.search-icon.active path {
  stroke: #FFFFFF;
}
.search-icon circle {
  fill: #DFE1E7;
  transform: scale(0);
  transform-origin: center;
  transition: fill 100ms ease-in-out, transform 100ms ease-in-out;
}
.search-icon.hovered circle {
  transform: scale(1);
}
.search-icon.active circle {
  fill: #FFFFFF;
  transform: scale(1);
}

/* Sidebar specific coloring */
.search-icon.sidebar path {
  stroke: #AFAFB2;
}
.search-icon.sidebar.hovered path {
  stroke: #FFFFFF;
}
.search-icon.sidebar.active path {
  stroke: #FFFFFF;
}
.search-icon.sidebar circle {
  fill: #5A5A60;
}
.search-icon.sidebar.active circle {
  fill: #FFFFFF;
}
</style>