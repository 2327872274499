<template>
  <div class="sidebar-item-dropdown flex flex-col p-1 w-full">
    <div v-for="(item, index) in items" :key="`dropdown-item-${item.name}-${index}`">
      <div v-if="item.name === 'divider'" class="divider my-1" />
      <button v-else class="w-full flex items-center gap-2 px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-50"
      @click="() => { item.action(); $emit('close') }">
        <component :is="item.icon" class="text-neutral-alpha-650" />
        <BaseText type="body" size="sm" class="text-neutral-alpha-800">
          {{ item.name }}
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarItemDropdown',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.sidebar-item-dropdown {
  background-color: #23242c;
  border-radius: 10px;
}
.divider {
  position: relative;
  width: calc(100% + 8px);
  height: 0px;
  left: -4px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}
</style>