// ==== Date Range Selector Dropdown Funcs ====

const getPresetDateRanges = () => {
  return {
    // today: {
    //   name: 'Today',
    //   start: floorDate(new Date(new Date().setDate(new Date().getDate() - 1))), 
    //   end: new Date()
    // },
    last7Days: {
      name: 'Last 7 days',
      start: floorDate(new Date(new Date().setDate(new Date().getDate() - 7))), 
      end: getEndOfYesterday()
    },
    last14Days: {
      name: 'Last 14 days',
      start: floorDate(new Date(new Date().setDate(new Date().getDate() - 14))), 
      end: getEndOfYesterday()
    },
    last30Days: {
      name: 'Last 30 days',
      start: floorDate(new Date(new Date().setDate(new Date().getDate() - 30))), 
      end: getEndOfYesterday()
    },
    last90Days: {
      name: 'Last 90 days',
      start: floorDate(new Date(new Date().setDate(new Date().getDate() - 90))), 
      end: getEndOfYesterday()
    },
    last365Days: {
      name: 'Last 365 days',
      start: floorDate(new Date(new Date().setDate(new Date().getDate() - 365))), 
      end: getEndOfYesterday()
    },
    allTime: {
      name: 'All time',
      start: null,
      end: null
    }
  }
}

const getMatchingPresetName = (dateRange) => {
  const presetDateRanges = getPresetDateRanges()
  for (const [_, range] of Object.entries(presetDateRanges)) {
    if (areDateRangesEqual(range, dateRange)) {
      console.log('Found matching preset', range.name)
      return range.name
    }
  }
  return null
}

const areDateRangesEqual = (range1, range2) => {
  if (!range1 || !range2 || !range1.start || !range2.start || !range1.end || !range2.end) return false
  return (
    range1.start.getFullYear() === range2.start.getFullYear() &&
    range1.start.getMonth() === range2.start.getMonth() &&
    range1.start.getDate() === range2.start.getDate() &&
    range1.end.getFullYear() === range2.end.getFullYear() &&
    range1.end.getMonth() === range2.end.getMonth() &&
    range1.end.getDate() === range2.end.getDate()
  )
}

const floorDate = (date) => {
  // Decrease the date to the first possible second of the day
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

const getEndOfYesterday = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1) // Move to yesterday
  yesterday.setHours(23, 59, 59, 999) // Set to last millisecond of the day
  return yesterday
}

const formatCustomDateRangeLabel = (startDate, endDate) => {
  // Check if the start and end dates are in the same year.
  if (startDate.getFullYear() === endDate.getFullYear()) {
    // Format: "Jan 27 - Feb 9, 2025"
    const startStr = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const endStr = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    return `${startStr} - ${endStr}`;
  } else {
    // Format: "Jan 27, 2024 - Feb 9, 2025"
    const startStr = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const endStr = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    return `${startStr} - ${endStr}`;
  }
}

export { getPresetDateRanges, areDateRangesEqual, getMatchingPresetName, formatCustomDateRangeLabel, floorDate, getEndOfYesterday }