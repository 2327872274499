<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.875 16.75C6.14708 16.75 3.125 13.728 3.125 10C3.125 6.27208 6.14708 3.25 9.875 3.25M13.25 4.15303C13.8715 4.51256 14.4304 4.96835 14.9062 5.5M13.25 15.847C13.8715 15.4874 14.4304 15.0317 14.9062 14.5M16.4973 8.68682C16.5811 9.11162 16.625 9.55068 16.625 10C16.625 10.4493 16.5811 10.8884 16.4973 11.3131" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.125 7.75L7.625 12.25M12.125 12.25L7.625 7.75" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'OpIsNotIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>