<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M11.5 16.7502H16.75M16.4394 4.8146L15.1856 3.56092C14.5999 2.97514 13.6501 2.97514 13.0644 3.56092L3.68934 12.936C3.40803 13.2172 3.25 13.5987 3.25 13.9966V16.7502H6.00368C6.40151 16.7502 6.78303 16.5922 7.06434 16.311L16.4394 6.93592C17.0251 6.35013 17.0251 5.40039 16.4394 4.8146Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'EditItemIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>