<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M12.625 10L6.625 10.0002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.625 16.75C5.89708 16.75 2.875 13.728 2.875 10C2.875 6.27208 5.89708 3.25 9.625 3.25M13 4.15303C13.6215 4.51256 14.1804 4.96835 14.6562 5.5M13 15.847C13.6215 15.4874 14.1804 15.0317 14.6562 14.5M16.2473 8.68682C16.3311 9.11162 16.375 9.55068 16.375 10C16.375 10.4493 16.3311 10.8884 16.2473 11.3131" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'OpDoesNotContainIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>