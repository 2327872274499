<template>
  <button
    type="button"
    class="relative inline-flex flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none"
    :class="[value ? `bg-${color}` : 'bg-neutral-50', small ? 'w-7 h-4' : 'w-11 h-6']"
    style="padding-top: 1.5px; padding-left: 2px;"
    role="switch"
    aria-checked="false"
    @click="updateValue"
  >
    <span
      :class="{
        'translate-x-5': value,
        'translate-x-0': !value
      }"
      :style="{
        width: small ? '13px' : '20px', 
        height: small ? '13px' : '20px',
        transform: value ? small ? 'translateX(11px)' : 'translateX(20px)' : 'translateX(0px)'
      }"
      class="pointer-events-none relative inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
    />
  </button>
</template>

<script>
export default {
  name: 'BaseToggle',
  props: {
    // Model
    value: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'green-500'
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    // Update Model Value
    updateValue () {
      this.$emit('toggle')
    }
  }
}
</script>

<style scoped lang="css">
</style>
