<template>
  <div
    v-on-clickaway="closeDropdown"
    class="relative inline-block"
  >
    <!-- Optional back button -->
    <router-link
      v-if="backRoute"
      :to="backRoute"
      class="px-1.5 inline-flex items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M11.3217 13.3333L8.57757 10.5893C8.25214 10.2638 8.25214 9.73618 8.57757 9.41074L11.3217 6.66666"
          stroke="#5E6678"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </router-link>

    <!-- Current route display (collapsed state) -->
    <button
      v-if="!dropdownExpanded"
      class="route-switcher rounded-md flex items-center pl-2 pr-1 py-1.5"
      :class="{'enabled': routes.length > 0}"
      @click="toggleDropdown"
    >
      <!-- Icon/Avatar -->
      <slot name="current-icon">
        <div
          v-if="currentRoute.icon"
          class="mr-2 w-5 h-5 flex-shrink-0"
        >
          <component
            :is="currentRoute.icon"
            v-if="typeof currentRoute.icon === 'object'"
            class="text-icon-muted"
          />
          <img
            v-else-if="currentRoute.avatar"
            :src="currentRoute.avatar"
            class="w-5 h-5"
            style="border-radius: 4.5px"
          >
        </div>
      </slot>

      <!-- Label -->
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted mr-1 truncate"
        style="max-width: 200px"
      >
        {{ currentRoute[labelKey] || '' }}
      </BaseText>

      <!-- Dropdown indicator -->
      <slot name="dropdown-indicator">
        <ChevronGrabberIcon
          v-if="routes.length > 0"
          class="ml-auto text-icon-normal"
        />
      </slot>
    </button>

    <!-- Expanded dropdown -->
    <div
      v-else
      ref="dropdown-spacer"
      class="relative h-8"
    >
      <div
        ref="dropdown"
        v-on-clickaway="closeDropdown"
        class="dropdown-container absolute w-max -top-1 -left-1 flex flex-col gap-1 px-1 pt-1 rounded-lg bg-neutral-25 z-50"
      >
        <!-- Current route (expanded state) -->
        <button
          class="flex items-center rounded-md pl-2 pr-1 py-1.5 box-shadow bg-white"
          @click="closeDropdown"
        >
          <!-- Icon/Avatar -->
          <slot name="current-icon-expanded">
            <div
              v-if="currentRoute.icon"
              class="mr-2 w-5 h-5 flex-shrink-0"
            >
              <component
                :is="currentRoute.icon"
                v-if="typeof currentRoute.icon === 'object'"
              />
              <img
                v-else-if="currentRoute.avatar"
                :src="currentRoute.avatar"
                class="w-5 h-5"
                style="border-radius: 4.5px"
              >
            </div>
          </slot>

          <!-- Label -->
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted mr-1 truncate"
            style="max-width: 200px"
          >
            {{ currentRoute[labelKey] || '' }}
          </BaseText>

          <!-- Dropdown indicator -->
          <slot name="dropdown-indicator-expanded">
            <ChevronGrabberIcon
              class="ml-auto text-icon-normal"
            />
          </slot>
        </button>

        <!-- Route options -->
        <div
          class="flex flex-col gap-1 overflow-y-scroll scrollbar-hide"
          style="max-height: 236px"
          :class="routes.length > 6 ? 'pb-2' : 'pb-1'"
        >
          <component
            :is="useRouterLink ? 'router-link' : 'button'"
            v-for="(route, index) in availableRoutes"
            :key="`route-${index}`"
            :to="useRouterLink ? getRouteDestination(route) : undefined"
            class="flex items-center rounded-md pl-2 pr-1 py-1.5 transition-colors hover:bg-neutral-50"
            @click="useRouterLink ? routeSelected(route) : navigateToRoute(route)"
          >
            <!-- Icon/Avatar -->
            <slot
              name="route-icon"
              :route="route"
            >
              <div
                v-if="route.icon"
                class="mr-2 w-5 h-5 flex-shrink-0"
              >
                <component
                  :is="route.icon"
                  v-if="typeof route.icon === 'object'"
                />
                <img
                  v-else-if="route.avatar"
                  :src="route.avatar"
                  class="w-5 h-5"
                  style="border-radius: 4.5px"
                >
              </div>
            </slot>

            <!-- Label -->
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted mr-1 whitespace-nowrap truncate"
              style="max-width: 200px"
            >
              {{ route[labelKey] || '' }}
            </BaseText>
          </component>
        </div>

        <!-- Fade overlay for long lists -->
        <!-- <div
          v-if="routes.length > 6"
          class="absolute bottom-0 left-0 right-0 h-7 bg-gradient-to-t from-neutral-25 to-transparent rounded-b-lg opacity-90 pointer-events-none"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import ChevronGrabberIcon from '@/components/globals/Icons/ChevronGrabberIcon.vue'

export default {
  name: 'BaseDropdownRouteSwitcher',
  components: {
    ChevronGrabberIcon
  },
  mixins: [clickaway],
  props: {
    /**
     * Array of route objects to display in the dropdown
     * Each route should have at minimum:
     * - A label property (configurable via labelKey)
     * - Either a 'to' object or 'name' string for Vue Router
     * - Optional icon/avatar
     */
    routes: {
      type: Array,
      default: () => []
    },
    /**
     * The currently selected route object
     */
    currentRoute: {
      type: Object,
      default: () => ({})
    },
    /**
     * The key to use for displaying the route label
     */
    labelKey: {
      type: String,
      default: 'name'
    },
    /**
     * Optional back route (for the back arrow)
     * Can be a string route name or a router location object
     */
    backRoute: {
      type: [String, Object],
      default: null
    },
    /**
     * Whether to use router-link (true) or programmatic navigation (false)
     */
    useRouterLink: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dropdownExpanded: false
    }
  },
  computed: {
    availableRoutes () {
      // Filter out the current route from the available routes
      return this.routes.filter(route => 
        route.name && 
        (!this.currentRoute || route[this.labelKey] !== this.currentRoute[this.labelKey])
      )
    }
  },
  methods: {
    toggleDropdown () {
      if (this.routes.length > 0) {
        this.dropdownExpanded = !this.dropdownExpanded
        if (this.dropdownExpanded) {
          this.$nextTick(() => {
            this.adjustDropdownWidth()
          })
        }
      }
    },
    closeDropdown () {
      this.dropdownExpanded = false
    },
    adjustDropdownWidth () {
      const spacer = this.$refs['dropdown-spacer']
      const dropdown = this.$refs.dropdown
      if (spacer && dropdown) {
        spacer.style.width = `${dropdown.offsetWidth - 8}px`
      }
    },
    getRouteDestination (route) {
      // Handle different route formats
      if (route.to) {
        return route.to
      } else if (route.routeName) {
        // Use routeName for navigation, but display name for UI
        return {
          name: route.routeName,
          params: route.params || {},
          query: route.query || {}
        }
      } else if (route.name && typeof route.name === 'string' && !route[this.labelKey]) {
        // Backward compatibility - if name exists but isn't being used as display text
        return {
          name: route.name,
          params: route.params || {},
          query: route.query || {}
        }
      } else {
        return { path: route.path || '/' }
      }
    },
    navigateToRoute (route) {
      // For programmatic navigation
      const destination = this.getRouteDestination(route)
      this.$router.push(destination)
      this.closeDropdown()

      // Emit the selected route
      this.$emit('route-selected', route)
    },
    routeSelected (route) {
      // Emit the selected route even when using router-link
      this.$emit('route-selected', route)
      this.closeDropdown()
    }
  }
}
</script>

<style scoped>
.route-switcher {
  transition: box-shadow 150ms ease-in-out;
  box-shadow: none;
  cursor: default;
}
.route-switcher.enabled {
  cursor: pointer;
}
.route-switcher.enabled:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.dropdown-container {
  z-index: 40002;
}
.box-shadow {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
</style>
