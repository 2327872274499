<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path opacity="0.5" d="M16 10C16 10.7879 15.8448 11.5681 15.5433 12.2961C15.2417 13.0241 14.7998 13.6855 14.2426 14.2426C13.6855 14.7998 13.0241 15.2417 12.2961 15.5433C11.5681 15.8448 10.7879 16 10 16" 
    stroke="currentColor" stroke-width="1.5"/>
    <path opacity="0.5" d="M4 10C4 9.21207 4.15519 8.43185 4.45672 7.7039C4.75825 6.97595 5.20021 6.31451 5.75736 5.75736C6.31451 5.20021 6.97595 4.75825 7.7039 4.45672C8.43185 4.15519 9.21207 4 10 4" 
    stroke="currentColor" stroke-width="1.5"/>
    <path d="M12.5831 3.76381C11.9724 3.51086 11.3291 3.34945 10.6738 3.28371C10.292 3.24541 9.9694 3.53961 9.92909 3.9212L9.41874 8.75169C9.38157 9.10355 9.10355 9.38157 8.75169 9.41874L3.9212 9.92909C3.53962 9.9694 3.24541 10.292 3.28372 10.6738C3.34946 11.3291 3.51087 11.9724 3.76381 12.5831C4.10303 13.4021 4.60023 14.1462 5.22703 14.773C5.85382 15.3998 6.59794 15.897 7.41689 16.2362C8.02755 16.4891 8.67091 16.6505 9.32618 16.7163C9.70797 16.7546 10.0306 16.4604 10.0709 16.0788L10.5813 11.2483C10.6184 10.8965 10.8965 10.6184 11.2483 10.5813L16.0788 10.0709C16.4604 10.0306 16.7546 9.70797 16.7163 9.32618C16.6505 8.67091 16.4891 8.02755 16.2362 7.41688C15.897 6.59794 15.3998 5.85382 14.773 5.22703C14.1462 4.60023 13.4021 4.10303 12.5831 3.76381Z" 
    stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'SpyderIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>