<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1829 4C10.5835 4.00221 11.5857 4.0119 12.6506 4.05455L13.0281 4.07101C14.1003 4.12177 15.1715 4.20849 15.7028 4.35663C16.4115 4.55571 16.9685 5.13663 17.1567 5.87299C17.4565 7.04231 17.494 9.32455 17.4986 9.87685L17.4993 9.9913V9.99932C17.4993 9.99932 17.4993 10.0021 17.4993 10.0074L17.4986 10.1219C17.494 10.6742 17.4565 12.9564 17.1567 14.1257C16.9658 14.8648 16.4088 15.4458 15.7028 15.6421C15.1715 15.7902 14.1003 15.8769 13.0281 15.9277L12.6506 15.9441C11.5857 15.9868 10.5835 15.9965 10.1829 15.9987L10.0071 15.9993H9.99932C9.99932 15.9993 9.9967 15.9993 9.99152 15.9993L9.81588 15.9987C8.96808 15.9941 5.42329 15.9558 4.29588 15.6421C3.5872 15.443 3.03019 14.8621 2.8419 14.1257C2.54215 12.9564 2.50468 10.6742 2.5 10.1219V9.87685C2.50468 9.32455 2.54215 7.04231 2.8419 5.87299C3.0328 5.1339 3.58981 4.55299 4.29588 4.35663C5.42329 4.04292 8.96808 4.00466 9.81588 4H10.1829ZM8.49933 7.37435V12.6243L12.9993 9.99932L8.49933 7.37435Z"
      fill="currentColor"
    />

  </svg>
</template>
<script>
export default {
  name: 'MonoYoutubeIcon',
  props: {
    width: {
      type: Number,
      default: () => 20
    },
    height: {
      type: Number,
      default: () => 20
    }
  }
}
</script>
  <style scoped lang="css"></style>
