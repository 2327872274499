<template>
  <div class="w-full flex flex-col p-6">
    <!-- Avatar Upload Section -->
    <div class="section-grid mx-auto mt-6">
      <div class="flex flex-col gap-1 mb-6 xl:mb-0" style="padding-right: 104px">
        <BaseText type="label" size="md" class="text-text-muted">
          Profile Photo
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Manage your personal details like name, contact info, and preferences.
        </BaseText>
      </div>
      <div class="flex gap-5">
        <img v-if="avatarPreview" :src="avatarPreview" alt="Preview" class="w-11 h-11 rounded-full">
        <DefaultAvatarIcon v-else-if="!updatedUser.avatar" :width="44" :height="44" />
        <img v-else :src="updatedUser.avatar" alt="Avatar" class="w-11 h-11 rounded-full">
        <div class="flex flex-col gap-2">
          <div class="flex items-center gap-2 py-1.5">
            <button class="standard-btn flex items-center gap-1.5 rounded-md pl-1.5 py-1.5 pr-2.5"
            @click="handleFileInput">
              <input ref="fileInput" class="hidden" type="file" @change="handleFileUpload">
              <UploadIcon class="text-icon-normal" />
              <BaseText type="label" size="sm" class="text-text-muted">
                Upload
              </BaseText>
            </button>
            <button class="px-2.5 py-1.5 rounded-md transition-colors"
            :class="hasAvatarToRemove ? 'hover:bg-neutral-25 text-text-muted' : 'cursor-default bg-neutral-10 text-text-disabled'"
            @click="removeAvatar">
              <BaseText type="label" size="sm">
                Remove
              </BaseText>
            </button>
          </div>
          <BaseText type="body" size="sm" class="text-text-normal">
            We support PNGs and JPEGs.
          </BaseText>
        </div>
      </div>
    </div>
    <!-- Divider -->
    <div class="section-grid mx-auto py-7">
      <div class="w-full col-span-2 h-px bg-neutral-50" />
    </div>
    <!-- Personal Info Section -->
    <div class="section-grid mx-auto">
      <div class="flex flex-col gap-1 mb-6 xl:mb-0" style="padding-right: 104px">
        <BaseText type="label" size="md" class="text-text-muted">
          Personal Information
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Manage your personal details like name, contact info, and preferences.
        </BaseText>
      </div>
      <div class="flex flex-col gap-4 w-full">
        <!-- Name Inputs -->
        <div class="grid grid-cols-2 gap-x-3 w-full">
          <!-- First Name -->
          <div class="flex flex-col gap-1 w-full">
            <BaseText type="label" size="sm" class="text-text-muted">
              First Name
            </BaseText>
            <input v-model="updatedUser.first_name" :class="{'invalid': submitAttempted && !isFirstNameValid}"
            class="form-input min-w-0 w-full px-2 py-1.5" placeholder="John" />
            <!-- invalid indicator -->
            <div v-if="submitAttempted && !isFirstNameValid" class="flex items-center gap-1">
              <AltAlertIcon class="text-secondary-red-100" />
              <BaseText type="body" size="xs" class="text-secondary-red-100">
                First name is required
              </BaseText>
            </div>
          </div>
          <!-- Last Name -->
          <div class="flex flex-col gap-1 w-full">
            <BaseText type="label" size="sm" class="text-text-muted">
              Last Name
            </BaseText>
            <input v-model="updatedUser.last_name" :class="{'invalid': submitAttempted && !isLastNameValid}"
            class="form-input min-w-0 w-full px-2 py-1.5" placeholder="Smith" />
            <!-- invalid indicator -->
            <div v-if="submitAttempted && !isLastNameValid" class="flex items-center gap-1">
              <AltAlertIcon class="text-secondary-red-100" />
              <BaseText type="body" size="xs" class="text-secondary-red-100">
                Last name is required
              </BaseText>
            </div>
          </div>
        </div>
        <!-- Email Input -->
        <div class="flex flex-col gap-1 w-full">
          <BaseText type="label" size="sm" class="text-text-muted">
            Email
          </BaseText>
          <input v-model="updatedUser.email" :class="{'invalid': submitAttempted && !isEmailValid}"
          class="form-input min-w-0 w-full px-2 py-1.5" placeholder="example@gmail.com" />
          <!-- invalid indicator -->
          <div v-if="submitAttempted && !isEmailValid" class="flex items-center gap-1">
            <AltAlertIcon class="text-secondary-red-100" />
            <BaseText type="body" size="xs" class="text-secondary-red-100">
              Enter a valid email address
            </BaseText>
          </div>
        </div>
        <!-- Phone Number Input -->
        <div class="flex flex-col gap-1 w-full">
          <BaseText type="label" size="sm" class="text-text-muted">
            Phone Number
          </BaseText>
          <input v-model="updatedUser.phone_number" :class="{'invalid': submitAttempted && !isPhoneNumberValid}" 
          class="form-input w-full min-w-0 px-2 py-1.5" placeholder="+1 (000) 000-0000" />
          <!-- invalid indicator -->
          <div v-if="submitAttempted && !isPhoneNumberValid" class="flex items-center gap-1">
            <AltAlertIcon class="text-secondary-red-100" />
            <BaseText type="body" size="xs" class="text-secondary-red-100">
              Enter a valid phone number
            </BaseText>
          </div>
        </div>
        <!-- Divider -->
        <div class="w-full h-px bg-neutral-50" />
        <div class="grid grid-cols-2 gap-x-3 w-full">
          <!-- Reset Password Button -->
          <button class="reset-password-btn px-2 py-1.5 rounded-md w-full" :disabled="passwordResetDisabled"
          :class="passwordResetDisabled ? 'disabled' : 'enabled'" 
          @click="resetPassword">
            <!-- default state -->
            <div v-if="!passwordResetEmailSent" class="w-full flex items-center justify-center gap-1.5">
              <BaseLoadingSpinnerCircle v-if="loadingPasswordReset" :duration="0.8" class="text-text-subdued" />
              <ResetPasswordIcon v-else class="text-icon-normal" />
              <BaseText type="label" size="sm" class="text-text-muted">
                Reset Password
              </BaseText>
            </div>
            <!-- reset email sent indicator state -->
            <div v-else class="w-full flex items-center justify-center gap-1.5 fade-in">
              <EmailSentIcon class="text-icon-normal" />
              <BaseText type="body" size="sm" class="text-text-muted">
                Reset Email Sent
              </BaseText>
            </div>
          </button>
          <!-- Save Changes Button -->
          <button class="submit-btn px-2.5 py-1.5 rounded-md" :disabled="!changesMade || loadingSubmit"
          :class="{'enabled': changesMade && !loadingSubmit, 'disabled cursor-default': !changesMade}"
          @click="handleFormSubmit">
            <BaseLoadingSpinner v-if="loadingSubmit" small />
            <BaseText v-else type="label" size="sm">
              Save Changes
            </BaseText>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import { nanoid } from 'nanoid'
import { mapGetters, mapMutations } from 'vuex'
import _ from "lodash";
// Icons
import DefaultAvatarIcon from '../globals/Icons/DefaultAvatarIcon.vue'
import UploadIcon from '../globals/Icons/UploadIcon.vue'
import ResetPasswordIcon from '../globals/Icons/SettingsIcons/ResetPasswordIcon.vue'
import EmailSentIcon from '../globals/Icons/EmailSentIcon.vue'
import AltAlertIcon from '../globals/Icons/AltAlertIcon.vue'

export default {
  name: 'MyAccountSettings',
  components: {
    DefaultAvatarIcon,
    UploadIcon,
    ResetPasswordIcon,
    EmailSentIcon,
    AltAlertIcon
  },
  data () {
    return {
      currentUser: {},
      updatedUser: {},
      avatarPreview: '',
      avatarUpload: null,
      submitAttempted: false,
      loadingSubmit: false,
      loadingPasswordReset: false,
      passwordResetEmailSent: false,
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    hasAvatarToRemove () {
      return this.updatedUser.avatar || this.avatarPreview
    },
    changesMade () {
      return this.avatarPreview || !_.isEqual(this.currentUser, this.updatedUser)
    },
    isFirstNameValid () {
      return this.updatedUser?.first_name?.length
    },
    isLastNameValid () {
      return this.updatedUser?.last_name?.length
    },
    isEmailValid () {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(this.updatedUser.email)
    },
    isPhoneNumberValid () {
      const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
      return phoneNumberRegex.test(this.updatedUser.phone_number) || !this.updatedUser.phone_number // Phone number is optional
    },
    isFormValid () {
      return this.isFirstNameValid && this.isLastNameValid && this.isEmailValid && this.isPhoneNumberValid
    },
    passwordResetDisabled () {
      return this.loadingPasswordReset || this.passwordResetEmailSent
    }
  },
  mounted () {
    let initFirstName = this.getUser?.first_name ?? ''
    let initLastName = this.getUser?.last_name ?? ''
    // If the user only has a 'name' field (e.g. google auth users), we need to split it into first and last names
    if (this.getUser.name) {
      const nameParts = this.getUser.name.split(' ')
      if (!initFirstName.length && nameParts?.length) {
        initFirstName = nameParts[0]
      }
      if (!initLastName.length && nameParts?.length > 1) {
        initLastName = nameParts.slice(1).join(' ')
      }
    }

    // Assign init and diff state values
    this.currentUser = {
      ...this.getUser,
      first_name: initFirstName,
      last_name: initLastName
    }
    this.updatedUser = { ...this.currentUser }
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    handleFileInput () {
      this.$refs.fileInput.click()
    },
    handleFileUpload (event) {
      const mediaFile = event.target.files[0]
      if (!mediaFile) return
      this.avatarUpload = mediaFile
      const fileType = mediaFile.type
      if (!(fileType === 'image/png' || fileType === 'image/jpeg')) {
        this.$showAlert({
          message: 'Only JPEG and PNG accepted',
          type: 'error'
        })
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.avatarPreview = e.target.result
      }
      reader.readAsDataURL(mediaFile)
    },
    removeAvatar () {
      if (!this.hasAvatarToRemove) return
      this.avatarPreview = ''
      this.updatedUser.avatar = ''
    },
    async handleFormSubmit () {
      this.submitAttempted = true
      if (!this.isFormValid) return

      this.loadingSubmit = true
      try {
        // Update the user's auth if email has changed
        if (this.currentUser.email !== this.updatedUser.email) {
          const user = firebase.auth().currentUser
          await user.updateEmail(this.updatedUser.email)
        }
        // Remove the current avatar if necessary
        if (this.currentUser.avatar && (!this.updatedUser.avatar || this.avatarPreview)) {
          await this.deleteOldAvatar(this.currentUser.avatar)
        }
        // Upload the new avatar if provided
        if (this.avatarPreview) {
          const folderName = nanoid()
          const fileName = nanoid()
          const filePath = `${folderName}/${fileName}`
          const storageRef = firebase.app().storage('gs://foreplay-user-avatars').ref(filePath)
          
          await storageRef.put(this.avatarUpload)
          const fileLocation = `https://storage.googleapis.com/foreplay-user-avatars/${filePath}`
          this.updatedUser.avatar = fileLocation

          this.$showAlert({
            message: 'Profile Photo Updated',
            type: 'success'
          })
        }

        // Update the user object
        const toUpdate = { 
          ...this.getUser, 
          ...this.updatedUser, 
          name: `${this.updatedUser?.first_name} ${this.updatedUser?.last_name}` 
        }
        const userId = firebase.auth().currentUser.uid
        await FirebaseAPI.Users.update(userId, toUpdate)
        this.SET_USER(toUpdate)
        this.currentUser = toUpdate
        this.updatedUser = toUpdate
        this.avatarPreview = ''
        this.avatarUpload = null

        this.$showAlert({
          message: 'Account details updated',
          type: 'success'
        })
      } catch (error) {
        console.error(error)
        this.$showAlert({
          message: error,
          type: 'Failed to update account information. See console for details.'
        })
      } finally {
        this.loadingSubmit = false
      }
    },
    async resetPassword () {
      this.loadingPasswordReset = true
      const user = firebase.auth().currentUser
      try {
        await firebase.auth().sendPasswordResetEmail(user.email)
        this.passwordResetEmailSent = true
      } catch (error) {
        this.$showAlert({
          message: error,
          type: 'error'
        })
      } finally {
        this.loadingPasswordReset = false
      }
    },
    async deleteOldAvatar (filePath) {
      if (!filePath) return
      const path = filePath?.split('https://storage.googleapis.com/foreplay-user-avatars/')
      if (path.length <= 1) return

      const storage = firebase
        .app()
        .storage('gs://foreplay-user-avatars')

      const oldAvatarRef = storage.ref().child(
        path[1]
      )
      try {
        await oldAvatarRef.delete()
      } catch (error) {
        /**
         * If an error occurs in deleting the old avatar, we should still allow the user to upload. We can run a cleanup job later to remove any orphaned files.
         */
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
.section-grid {
  display: grid;
  grid-template-columns: 440px 440px;
}
@media (max-width: 1280px) {
  .section-grid {
    display: block;
    width: 100%;
    max-width: 640px;
  }
}

.standard-btn {
  background: linear-gradient(180deg, rgba(223, 225, 231, 0.08) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out;
}
.standard-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input.invalid {
  box-shadow: none;
  border-color: #FF002F;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}
.reset-password-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}
.reset-password-btn.enabled:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.reset-password-btn.disabled {
  cursor: default;
  background-color: #F8FAFB;
  box-shadow: none;
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0.25 }
  to { opacity: 1 }
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-color {
  syntax: '<color>';
  initial-value: #0063F4;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out, 
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out, 
    --submit-btn-opacity-2 100ms ease-in-out,
    --submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --submit-color: #F6F8FA;
}
</style>