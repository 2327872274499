<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M14.875 5.125L12.1494 7.85058M7.84667 12.1533L5.125 14.875M5.125 5.125L7.84667 7.84667M12.1494 12.1494L14.875 14.875M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10ZM13 10C13 11.6568 11.6568 13 10 13C8.34314 13 7 11.6568 7 10C7 8.34314 8.34314 7 10 7C11.6568 7 13 8.34314 13 10Z" 
    stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'CustomerSupportUserIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>