import CampaignStructureIcon from '../../../components/globals/Icons/LensIcons/filters/CampaignStructureIcon.vue'
import SetupStatusIcon from '../../../components/globals/Icons/LensIcons/filters/SetupStatusIcon.vue'
import AdContentsIcon from '../../../components/globals/Icons/LensIcons/filters/AdContentsIcon.vue'

import getFilterTypeOperations from './filterTypeOperations'

export default [
  {
    categoryName: 'Campaign Structure',
    icon: CampaignStructureIcon,
    options: [
      {
        optionType: 'metadata',
        name: 'Campaign Name',
        key: 'campaign_name',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Name' }
      },
      {
        optionType: 'metadata',
        name: 'Ad Set Name',
        key: 'adset_name',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Name' }
      },
      {
        optionType: 'metadata',
        name: 'Ad Name',
        key: 'ad_name',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Name' }
      }
    ]
  },
  {
    categoryName: 'Setup & Status',
    icon: SetupStatusIcon,
    options: [
      // TODO: UPDATE WHEN BACKEND SUPPORTS THE BELOW OPTIONS
      {
        optionType: 'metadata',
        name: 'Ad Status',
        key: 'ad_status',
        validOperations: getFilterTypeOperations('dropdown'),
        input: {
          type: 'dropdown',
          options: [
            { value: 'ACTIVE', name: 'Active' },
            { value: 'PAUSED', name: 'Paused' }
          ]
        }
      },
      { // Dynamic or Page Post - Check with Anas
        optionType: 'metadata',
        name: 'Distribution Format',
        key: 'distribution_format',
        validOperations: getFilterTypeOperations('dropdown'),
        input: {
          type: 'dropdown',
          options: [
            { value: 'todo', name: 'TODO' }
          ]
        }
      }
    ]
  },
  {
    categoryName: 'Ad Contents',
    icon: AdContentsIcon,
    options: [
      {
        optionType: 'metadata',
        name: 'Ad Type',
        key: 'ad_type',
        validOperations: getFilterTypeOperations('dropdown'),
        input: {
          type: 'dropdown',
          // TODO: This might need to be updated
          options: [
            { value: 'image', name: 'Image' }, // Find out what this is called in the backend
            { value: 'video', name: 'Video' },
            { value: 'carousel', name: 'Carousel' },
            { value: 'dynamic', name: 'Dynamic' }
          ]
        }
      },
      // TODO: UPDATE WHEN BACKEND SUPPORTS THE BELOW OPTIONS
      {
        optionType: 'metadata',
        name: 'Landing Page',
        key: 'destination_url',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'URL' }
      },
      {
        optionType: 'metadata',
        name: 'Copy',
        key: 'ad_copy',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Copy' }
      },
      // {
      //   optionType: 'metadata',
      //   name: 'CTA Type',
      //   key: 'cta_type',
      //   validOperations: getFilterTypeOperations('dropdown'),
      //   input: {
      //     type: 'dropdown',
      //     options: [
      //       // Probably not correct:
      //       { value: 'learn_more', name: 'Learn More' },
      //       { value: 'shop_now', name: 'Shop Now' },
      //       { value: 'book_now', name: 'Book Now' },
      //       { value: 'call_now', name: 'Call Now' },
      //       { value: 'sign_up', name: 'Sign Up' }
      //     ]
      //   }
      // },
      {
        optionType: 'metadata',
        name: 'Headline',
        key: 'ad_title',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Headline' }
      },
      // {
      //   optionType: 'metadata',
      //   name: 'Hook',
      //   key: 'hook',
      //   validOperations: getFilterTypeOperations('text'),
      //   input: { type: 'text', placeholder: 'Hook' }
      // }
    ]
  }
]
