<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none">
    <path d="M6.25 7.5C6.94036 7.5 7.5 6.94036 7.5 6.25C7.5 5.55964 6.94036 5 6.25 5C5.55964 5 5 5.55964 5 6.25C5 6.94036 5.55964 7.5 6.25 7.5Z" 
    fill="currentColor" />
    <path d="M13 12.9408V17.0598C13 17.6665 13.683 18.0222 14.1801 17.6742L17.1223 15.6147C17.5488 15.3161 17.5488 14.6844 17.1223 14.3858L14.1801 12.3263C13.683 11.9784 13 12.334 13 12.9408Z" 
    fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.75C2 3.23122 3.23122 2 4.75 2H13.25C14.7688 2 16 3.23122 16 4.75V8H19.25C20.7688 8 22 9.23122 22 10.75V19.25C22 20.7688 20.7688 22 19.25 22H10.75C9.23122 22 8 20.7688 8 19.25V16H4.75C3.23122 16 2 14.7688 2 13.25V4.75ZM14.5 4.75V8H10.75C9.33462 8 8.16899 9.06927 8.01682 10.4441L7.52348 10.1152C6.59976 9.49937 5.39635 9.49937 4.47263 10.1152L3.5 10.7636V4.75C3.5 4.05964 4.05964 3.5 4.75 3.5H13.25C13.9404 3.5 14.5 4.05964 14.5 4.75ZM6.69143 11.3633L8 12.2356V14.5H4.75C4.05964 14.5 3.5 13.9404 3.5 13.25V12.5664L5.30468 11.3633C5.72456 11.0833 6.27156 11.0833 6.69143 11.3633ZM9.5 19.25V10.75C9.5 10.0596 10.0596 9.5 10.75 9.5H19.25C19.9404 9.5 20.5 10.0596 20.5 10.75V19.25C20.5 19.9404 19.9404 20.5 19.25 20.5H10.75C10.0596 20.5 9.5 19.9404 9.5 19.25Z" 
    fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'MediaMetricsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>