<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2614 9.09856L13.07 10.7537C13.0622 10.8292 13.0268 10.8992 12.9707 10.9504C12.9146 11.0015 12.8417 11.0303 12.7658 11.0311H11.0386L11.0297 15.9369C11.0302 15.995 11.0079 16.0509 10.9676 16.0927C10.9272 16.1345 10.8721 16.1587 10.814 16.1602H9.06385C9.00681 16.1602 8.9521 16.1376 8.91168 16.0973C8.87127 16.0571 8.84845 16.0024 8.84823 15.9454C8.84801 15.9424 8.84801 15.9394 8.84823 15.9364V11.0311H7.55275C7.51203 11.0307 7.47179 11.0223 7.43432 11.0064C7.39685 10.9904 7.3629 10.9672 7.3344 10.9381C7.30591 10.909 7.28343 10.8746 7.26824 10.8368C7.25306 10.799 7.24548 10.7586 7.24593 10.7179L7.23954 9.06319C7.23892 9.02236 7.24635 8.98181 7.26142 8.94385C7.27649 8.90589 7.2989 8.87128 7.32735 8.84199C7.35581 8.8127 7.38976 8.78931 7.42727 8.77316C7.46478 8.757 7.5051 8.7484 7.54593 8.74785H8.84823V7.15024C8.84823 5.29524 9.94938 4.28613 11.564 4.28613H12.8876C12.9285 4.28658 12.969 4.2951 13.0066 4.3112C13.0442 4.32731 13.0782 4.35068 13.1068 4.37998C13.1353 4.40928 13.1578 4.44393 13.1729 4.48194C13.188 4.51995 13.1955 4.56057 13.1949 4.60148V5.99497C13.1955 6.0358 13.1881 6.07635 13.173 6.11431C13.1579 6.15226 13.1355 6.18688 13.1071 6.21617C13.0786 6.24545 13.0447 6.26885 13.0072 6.285C12.9696 6.30115 12.9293 6.30976 12.8885 6.31031H12.0758C11.1984 6.31031 11.0275 6.74242 11.0275 7.36629V8.74785H12.9567C12.9975 8.74835 13.0379 8.75689 13.0754 8.77299C13.113 8.78909 13.147 8.81242 13.1755 8.84167C13.2041 8.87091 13.2265 8.90549 13.2417 8.94342C13.2569 8.98136 13.2644 9.02191 13.2639 9.06277C13.2639 9.0747 13.2631 9.08663 13.2614 9.09856Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'MonoFacebookIcon',
  props: {
    width: {
      type: Number,
      default: () => 20
    },
    height: {
      type: Number,
      default: () => 20
    }
  }
}
</script>
  <style scoped lang="css"></style>
