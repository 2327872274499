import AxiosConfig from './LensAxiosConfig'
import firebase from '../config/FirebaseConfig'

const create = async (presetPayload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/presets',
    presetPayload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const remove = async (presetId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.delete(
      `/presets/${presetId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
  )

  return data
}

const getByID = async (presetId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/presets/${presetId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getAll = async (query) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser
  const params = new URLSearchParams()

  if (query?.page) params.append('page', query.page)
  if (query?.limit) params.append('limit', query.limit)

  const { data } = await AxiosConfig.get(
    `/presets?${params.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const update = async (presetId, payload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.patch(
    `/presets/${presetId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}
export default {
  create,
  update,
  remove,
  getByID,
  getAll
}
