<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10.375 16.75C6.64708 16.75 3.625 13.728 3.625 10C3.625 6.27208 6.64708 3.25 10.375 3.25M13.75 4.15303C14.3715 4.51256 14.9304 4.96835 15.4062 5.5M13.75 15.847C14.3715 15.4874 14.9304 15.0317 15.4062 14.5M16.9973 8.68682C17.0811 9.11162 17.125 9.55068 17.125 10C17.125 10.4493 17.0811 10.8884 16.9973 11.3131" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.625 8.125L9.25 12.25L7.75 10.75" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'OpIsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>