<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.6466 2.72143C9.78843 2.42619 10.2116 2.42619 10.3534 2.72143L12.2094 6.58486C12.2664 6.70368 12.3801 6.78575 12.5115 6.80296L16.7842 7.3624C17.1107 7.40515 17.2414 7.80484 17.0026 8.03006L13.877 10.9772C13.7809 11.0678 13.7374 11.2007 13.7616 11.3301L14.5462 15.5393C14.6062 15.8609 14.2639 16.108 13.9745 15.9519L10.1868 13.91C10.0703 13.8472 9.92973 13.8472 9.81325 13.91L6.02555 15.9519C5.7361 16.108 5.39381 15.8609 5.45378 15.5393L6.23844 11.3301C6.26258 11.2007 6.21914 11.0678 6.12301 10.9772L2.99743 8.03006C2.75857 7.80484 2.88932 7.40515 3.21583 7.3624L7.48845 6.80296C7.61986 6.78575 7.73358 6.70368 7.79067 6.58486L9.6466 2.72143Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'StarItemIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>