<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M11.9919 7.54781L8.91349 8.38737C8.65729 8.45724 8.45712 8.65741 8.38725 8.91361L7.54769 11.992C7.47134 12.272 7.7282 12.5288 8.00814 12.4525L11.0865 11.6129C11.3427 11.543 11.5429 11.3429 11.6128 11.0867L12.4524 8.00826C12.5287 7.72833 12.2718 7.47146 11.9919 7.54781Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'FindClientsUserIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>