<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none">
    <path d="M3.45 8.17505C2.85 9.72707 2.85 11.123 3.45 12.675M15.5953 6.63659L11.6783 8.97382L9.86905 5.73526C9.36945 4.84095 8.26294 4.53454 7.39761 5.05086C6.53227 5.56719 6.23579 6.71073 6.73539 7.60504L9.44921 12.4629L8.37466 12.2937C7.38685 12.1383 6.46411 12.8398 6.31366 13.8607L6.15 14.9712L11.0516 18.1663C13.0886 19.4943 15.6698 19.5464 17.7555 18.3019C20.8581 16.4506 21.9212 12.3506 20.1299 9.14419L19.3025 7.66318C18.5531 6.32172 16.8933 5.86209 15.5953 6.63659Z" 
    stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'EngagementMetricsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>