// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get All By User
const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('briefs').where('createdBy', '==', user.uid).orderBy('createdAt', 'desc').limit(100).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By Team
const getAllByTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('briefs').where('teamId', '==', teamId).orderBy('createdAt', 'desc').limit(100).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All Archived
const getAllArchived = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const createdByCondition = teamId ? ['teamId', '==', teamId] : ['createdBy', '==', user.uid]

  const snapshot = await db.collection('briefs').where(...createdByCondition).where('archived', '==', true).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get By ID
const getByID = async (briefId) => {
  const brief = await db.collection('briefs').doc(briefId).get()

  return {
    id: brief.id,
    ...brief.data()
  }
}

// Create a Brief
const create = (payload) => {
  const user = firebase.auth().currentUser
  // Track Brief Creation
  window.analytics.track('Brief Created', {
    briefName: payload.name,
    briefId: payload.id
  })
  return db
    .collection('briefs')
    .add({ ...payload, createdBy: user.uid, createdAt: +new Date() })
}

// Update Brief
const update = async (briefId, payload) => {
  return await db
    .collection('briefs')
    .doc(briefId)
    .update(payload)
}

// Remove/Delete Brief
const remove = async (briefId) => {
  return db.collection('briefs').doc(briefId).delete()
}

export default {
  create,
  getAllArchived,
  getAllByTeam,
  getAllByUser,
  getByID,
  remove,
  update
}
