import Vue from 'vue'
import Vuex from 'vuex'
import Modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: Modules
})

// Set up a watcher that updates the settings query param when the view mode changes
store.watch(
  (state, getters) => getters['MiscModule/getSettingsDrawerViewMode'],
  (newValue, oldValue) => {
    if (newValue) {
      const url = new URL(window.location.href)
      url.searchParams.set('settings', newValue)

      window.history.replaceState({}, '', url)
    } else {
      const url = new URL(window.location.href)
      url.searchParams.delete('settings')
      window.history.replaceState({}, '', url)
    }
  }
)

// Set up a watcher that updates the lens id query param when the view mode changes
store.watch(
  (state, getters) => getters['RouterModule/getCurrentRouteId'],
  (newValue, oldValue) => {
    if (newValue) {
      const url = new URL(window.location.href)
      url.searchParams.set('lensId', newValue)
      window.history.replaceState({}, '', url)
    } else {
      const url = new URL(window.location.href)
      url.searchParams.delete('lensId')
      window.history.replaceState({}, '', url)
    }
  }
)
export default store