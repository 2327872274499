import OpContainsIcon from '../../../components/globals/Icons/LensIcons/filters/operations/OpContainsIcon.vue'
import OpDoesNotContainIcon from '../../../components/globals/Icons/LensIcons/filters/operations/OpDoesNotContainIcon.vue'
import OpIsIcon from '../../../components/globals/Icons/LensIcons/filters/operations/OpIsIcon.vue'
import OpIsNotIcon from '../../../components/globals/Icons/LensIcons/filters/operations/OpIsNotIcon.vue'
import OpEqualToIcon from '../../../components/globals/Icons/LensIcons/filters/operations/OpEqualToIcon.vue'
import OpGreaterThanIcon from '../../../components/globals/Icons/LensIcons/filters/operations/OpGreaterThanIcon.vue'
import OpLessThanIcon from '../../../components/globals/Icons/LensIcons/filters/operations/OpLessThanIcon.vue'

export default function getFilterTypeOperations (type) {
  // Returns the valid comparisons/operation information for a given filter type
  switch (type) {
    case 'text':
      return [
        {
          key: 'contains',
          name: 'contains',
          icon: OpContainsIcon
        },
        {
          key: 'doesnt_contain',
          name: 'does not contain',
          icon: OpDoesNotContainIcon
        }
      ]
    case 'dropdown':
      return [
        {
          key: 'is',
          name: 'is',
          icon: OpIsIcon
        },
        {
          key: 'is_not',
          name: 'is not',
          icon: OpIsNotIcon
        }
      ]
    case 'number':
    case 'currency':
    case 'percent':
      return [
        {
          key: 'greater_than',
          name: 'is more than',
          icon: OpGreaterThanIcon
        },
        {
          key: 'less_than',
          name: 'is less than',
          icon: OpLessThanIcon
        },
        {
          key: 'equals',
          name: 'is equal to',
          icon: OpEqualToIcon
        }
      ]
    default:
      return []
  }
}