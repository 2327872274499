<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7.17526 10.0002L3.94462 11.5761C3.38304 11.85 3.38304 12.6503 3.94462 12.9242L9.92142 15.8397C10.1289 15.941 10.3716 15.941 10.5791 15.8397L16.5559 12.9242C17.1174 12.6503 17.1174 11.85 16.5559 11.5761L13.3253 10.0002M7.17526 10.0002L3.94462 8.4242C3.38304 8.15025 3.38304 7.34998 3.94462 7.07604L9.92142 4.16052C10.1289 4.05929 10.3716 4.05929 10.5791 4.16052L16.5559 7.07604C17.1174 7.34998 17.1174 8.15025 16.5559 8.4242L13.3253 10.0002M7.17526 10.0002L9.92142 11.3397C10.1289 11.441 10.3716 11.441 10.5791 11.3397L13.3253 10.0002" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CampaignStructureIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>