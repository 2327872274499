<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4.00002 10.75L4 14.5C4 15.3284 4.67157 16 5.5 16H14.5C15.3284 16 16 15.3284 16 14.5V10.75M4.00002 10.75L4.00003 5.5C4.00003 4.67157 4.6716 4.00001 5.50003 4.00001L14.5 4C15.3284 4 16 4.67157 16 5.5V10.75M4.00002 10.75H7.09451C7.42754 12.0439 8.60215 13 10 13C11.3979 13 12.5724 12.0439 12.9055 10.75H16" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ArchiveItemIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
